import {
  Component,
  Input,
  OnChanges,
  OnInit,
  OnDestroy,
  SimpleChanges,
  ChangeDetectorRef,
  EventEmitter,
  Output,
} from '@angular/core';
import { environment } from 'src/environments/environment';
import { MyCoverageService } from 'src/app/services/coverages/mycoverage.service';
import { AuthorizenetI } from '../authorizenet/authrizenet-payment/authorizenet.config';
import { AuthorizenetService } from './authorizenet-service/authorizenet.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { Router } from '@angular/router';
import { PaymentService } from 'src/app/services/payment.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { switchMap } from 'rxjs';
import { PostPayment } from 'src/app/models/payment/post-payment.model';
import { FiscalYearService } from 'src/app/services/fiscal-year.service';

@Component({
  selector: 'app-instant-payment',
  templateUrl: './instant-payment.component.html',
  styleUrls: ['./instant-payment.component.scss'],
})
export class InstantPaymentComponent implements OnInit, OnChanges {
  @Input() subTotal: number = 0;
  @Input() cardHolderName: string = '';
  @Input() enrollData!: any;
  @Output() showError: EventEmitter<string> = new EventEmitter<string>();

  IDCardYear: number = 0;
  ErrorMessage: string = '';
  showPaymentError: boolean = false;
  public authorizenetConfig!: AuthorizenetI;
  count: number = 0;
  constructor(
    private authService: AuthenticationService,
    private coverageService: MyCoverageService,
    private paymentService: PaymentService,
    private route: Router,
    private authrorizenetserviceInstance: AuthorizenetService,
    private notifyService: NotificationService,
    private changeDetector: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private fiscalYearService:FiscalYearService
  ) {
    this.IDCardYear = this.fiscalYearService.getFiscalYearStart();
   }

  ngOnInit(): void {
    this.initConfig();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initConfig();
    this.count = 0;
  }

  private initConfig(): void {
    let configObj = {
      billingAddressOptions:
        environment.authorizeNetConfig.billingAddressOptions,
      apiLoginID: environment.authorizeNetConfig.authorizeNetAPILoginID,
      clientKey: environment.authorizeNetConfig.authorizeNetAPIClientKey,
      acceptUIFormBtnTxt: environment.authorizeNetConfig.acceptUIFormBtnTxt,
      acceptUIFormHeaderTxt:
        environment.authorizeNetConfig.acceptUIFormHeaderTxt,
      acceptUIurl: environment.authorizeNetConfig.authorizeNetAcceptUIurl,
      amount: this.subTotal.toFixed(2).toString(),
    };
    this.authorizenetConfig = configObj;
  }

  public responseHandler(response: any): void {
    if (this.count == 0) {
      this.count++;
      this.spinner.show('payment');
      if (response.messages.resultCode === 'Ok') {
        this.createAcceptPaymentTransaction(response);
        return;
      }
      this.showPaymentError = true;
      this.count = 0;
      this.ErrorMessage = '';
      let i = 0;
      while (i < response.messages.message.length) {
        this.ErrorMessage += response.messages.message[i].text + ' ';
        i = i + 1;
      }
      this.showError.emit(this.ErrorMessage);
    }
  }

  private createAcceptPaymentTransaction(response: any): void {

    let payment = new PostPayment("", 0, 0, [], "");
    let createTransactionRequest = {
      transactionRequest: {
        amount: Number(this.subTotal.toFixed(2)),
        payment: {
          opaqueData: {
            dataDescriptor: response.opaqueData.dataDescriptor,
            dataValue: response.opaqueData.dataValue,
          },
        },
        customer: {
          email: this.enrollData.email,
        },
      },
    };
    payment = this.authService.postPayDetails.getValue();
          let emptyFlag = payment.shoppingCarts?.length == 0 || payment.shoppingCarts.some(x=> x.rowID == null || x.rowID == undefined || x.rowID == 0
           ||( (x.studentID == null  || x.studentID == "" || x.studentID == undefined)  &&  (x.ssn == undefined || x.ssn == "" || x.ssn == null)));
    if(!emptyFlag){
    this.authrorizenetserviceInstance
      .CreateAcceptPaymentTransaction(createTransactionRequest)
      .pipe(
        switchMap((response: any) => {
          
          if (response?.status && response?.body?.transactionResponse?.responseCode === "1" ) {
            
            payment.transactionId = response.body.transactionResponse.transId;
            return this.paymentService.PostPaymentProcess(payment);
          }

          throw response?.body?.transactionResponse?.errors.length > 0? 
                    response?.body?.transactionResponse?.errors[0].errorText: response?.body?.transactionResponse?.messages[0].description;
        })
      )
      .subscribe({
        next: (result: any) => {
          if (result.body && result.body != null) {
            this.paymentService.setPaymentDetails(result.body);
            this.spinner.hide('payment');
            this.route.navigate(['/paymentsuccess']);
          }
        },
        error: (error: any) => {
          this.showPaymentError = true;
          this.ErrorMessage = "There was an error processing your payment. Please review your payment details and try again.";
          this.count = 0;
          this.showError.emit(this.ErrorMessage);
          console.log(error);
        },
      });
    }
    else{
          this.showPaymentError = true;
          this.ErrorMessage = "There is system issue.Please contact customer service.";
          this.count = 0;
          this.showError.emit(this.ErrorMessage);
    }
  }
}
