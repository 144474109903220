import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UserAddress } from 'src/app/models/Profile.model';
import { AccountUpdate } from 'src/app/models/accountUpdate.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserdetailsService {

  private fullNameSubject = new Subject<string>();
  fullName = this.fullNameSubject.asObservable();

  
  setFullName(newFullName: string): void {
    this.fullNameSubject.next(newFullName);

  }

  constructor(private http: HttpClient) { }
  getUserAddress(email:string):Observable<UserAddress>{
    const headers = { 'content-type': 'application/json'}  
    const body= JSON.stringify(email);
    return this.http.post<any>(environment.baseUrl+'User/AccountAddress',body,{headers:headers});
  }
  updateUser(model: AccountUpdate):Observable<any>{
    return this.http.post<any>(environment.baseUrl+'User/UpdateAccountDetails',model);
  }
}
