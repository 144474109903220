<div class="delete-student-modal" id="deletePopup">
    <div>
        <button class="closeHeader" id="closeBtn" (click)="closeDialog()">
            <img src="./assets/images/close cross.svg">
        </button>
    </div>
    <div class="row">
        <img class="infoImg" src="assets\images\information.jpg" alt="">
    </div>
    <div class="row deleteRow">
        <div class="row">
            <div class="dltText">Delete Student</div>
        </div>
        <div class="row">
            <div class="cnfrmMsg">Are you sure you want to
                delete the student?</div>
        </div>
    </div>
    <div class="row btnRow">
        <div class="col-lg-6"><button type="button" id="cancel" (click)="closeDialog()">Cancel</button></div>
        <div class="col-lg-6"><button type="button" id="confirm" (click)="deleteStudent()">Confirm</button></div>
    </div>
</div>

