<div class="container">
  <div class="row ">
    <div class="accordion ms-auto " id="accordionExample">
      <div class="accordion-item">
        <h2 class="accordion-header" [id]="'heading'">
          <div class="d-flex justify-content-between px-2 py-3">

            <div class="d-flex flex-wrap align-items-center ms-2">
              <span class="fw-bold me-3" style="font-size: 14px;">{{studentInfo.firstName}} {{studentInfo.lastName}}</span>
              <span class="policynotext" style="font-size: 13px;" *ngIf="studentInfo.coverages.length > 0">Policy No - {{
                studentInfo.coverages[0].voluntaryPolicyNo}}</span>
            </div>

            <!-- <div class="d-inline-flex">
              <button
                class="downloadbtn me-1"
                data-toggle="tooltip"
                data-placement="left"
                id="downloadID"
                title="Click here to download"
                (click)="downloadIdCard(studentInfo,underwriters.insCo)">
                  <img src="assets/images/download.svg" alt="Unable to load image">
                </button>

                <button
                  class="accordion-button btnCoverage px-1"
                  type="button"
                  data-bs-toggle="collapse"
                  id="collapseBtn"
                  [attr.data-bs-target]="'#collapse'"
                  [attr.aria-controls]="'collapse'"
                  [attr.aria-expanded]="true"
                  (click)="collapse($event)"
                  >
              </button>
            </div> -->
          </div>

        </h2>
        <div [id]="'collapse'" class="accordion" data-bs-parent="#accordionExample"
          [attr.aria-labelledby]="'heading'">
          <div class="accordion-body" id="accBody">
            <div class="row">
              <div class="d-flex flex-wrap mb-4">
                <div style="margin-right: 5rem;">
                  <p class="fw-semibold mb-1 text-nowrap" style="font-size: .95rem;">School District</p>
                  <p class="text-dark" style="font-size: .9rem">{{studentInfo.district}}</p>
                </div>
                <div style="margin-right: 5rem;">
                  <p class="fw-semibold mb-1 text-nowrap" style="font-size: .95rem;">State</p>
                  <p class="text-dark" style="font-size: .9rem">{{studentInfo.state}}</p>
                </div>
                <div style="margin-right: 5rem;">
                  <p class="fw-semibold mb-1 text-nowrap" style="font-size: .95rem;">School Year</p>
                  <p class="text-dark" style="font-size: .9rem">{{ currentYear}} - {{currentYear + 1}}</p>
                </div>
              </div>
            </div>

            <div class="row mb-5" *ngIf="underwriters.insCo!=''">
              <div class="col-lg-6" id="logoColumn" *ngIf="underwriters.insCo!=''">
                <img *ngIf="underwriters.logoLoc" class="logoImage"
                  src='{{ "./assets/images/"+underwriters.logoLoc+""}}' alt={{underwriters.logoLoc}}>
                <div style="display: block;">
                  <p class="gnote">Coverage by</p>
                  <div class="note">
                    {{underwriters.insCo}}
                  </div>
                </div>

              </div>
              <div class="col-lg-6">
                <div class="note">
                  {{underwriters.address1}}, {{underwriters.address2}} <br>
                  {{underwriters.state}}, {{underwriters.city}}, {{underwriters.zip}}
                </div>
              </div>
            </div>
            <!-- <br> -->


            <div *ngIf="studentInfo.coverages.length > 0">
              <span class="headerLabel">Active Coverages</span>
              <table mat-table #table [dataSource]="studentInfo.coverages" matSort>
                <ng-container matColumnDef="Activity">
                  <th style="width: 35%;" mat-header-cell mat-sort-header *matHeaderCellDef>Activity</th>
                  <td style="width: 35%;" mat-cell *matCellDef="let element">{{element.activity}}
                    <div *ngIf="element.subCatNameString && element.subCatNameString !=''"> [{{element.subCatNameString}}]</div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="Demographics">
                  <th style="width: 35%;" mat-header-cell mat-sort-header *matHeaderCellDef>Demographics</th>
                  <td style="width: 35%;" mat-cell *matCellDef="let element">{{element.demographics}}</td>
                </ng-container>

                <ng-container matColumnDef="MarketingPlan">
                  <th style="width: 35%;" mat-header-cell mat-sort-header *matHeaderCellDef>Marketing Plan</th>
                  <td style="width: 35%;" mat-cell *matCellDef="let element">{{element.marketingPlan}}</td>
                </ng-container>

                <ng-container matColumnDef="Rate">
                  <th style="width: 35%;" mat-header-cell mat-sort-header *matHeaderCellDef>Coverage Rate</th>
                  <td style="width: 35%;" mat-cell *matCellDef="let element">{{element.paid |
                    currency:'USD':'symbol'}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="EffectiveDate">
                  <th style="width: 35%;" mat-header-cell mat-sort-header *matHeaderCellDef>Coverage<br>Effective Date
                  </th>
                  <td style="width: 35%;" mat-cell *matCellDef="let element">{{element.effectiveDate | date:
                    'M/d/yyyy'}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="TerminationDate">
                  <th style="width: 35%;" mat-header-cell mat-sort-header *matHeaderCellDef>Coverage<br>Expiration Date
                  </th>
                  <td style="width:35%;" mat-cell *matCellDef="let element">{{element.terminationDate | date:
                    'M/d/yyyy'}}
                  </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
            <br />
            <!--Available Coverages-->
            <div class="row">
              <div class="col-md-12">
                <span class="headerLabel">Available Coverages</span>
                <table mat-table #table [dataSource]="availableCoverages" matSort>
                  <ng-container matColumnDef="RowID">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
                    <td class="text-center" mat-cell *matCellDef="let element">

                      <mat-radio-button *ngIf="element.inputType == 'radio'" layout-xs="row" name="table" [id]="'tableradio'+element.rowID"
                        [value]="element.rowID" class="radiobtn" (change)="selectItem(element, $event)" [checked]="element.isChecked">
                      </mat-radio-button>


                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Activity">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>
                      Activity</th>
                    <td class="text-start" mat-cell *matCellDef="let element"><div class="=row">
                      {{element.activity}}
                      <mat-form-field *ngIf="element.subCategories?.length>0 && (studentInfo.campusType == 'H' || studentInfo.campusType == 'M')">
                              <mat-label>Select All Sports/Activities student performs</mat-label>
                              <mat-select [(ngModel)]="element.selectedSubCats" multiple>
                                <mat-option *ngFor="let cat of element.subCategories" [value]="cat.choiceID">{{cat.choiceName}} [{{cat.choiceCode}}]</mat-option>
                            </mat-select>
                      </mat-form-field>
                  </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Demographics">
                    <th class="headerCell" mat-header-cell mat-sort-header *matHeaderCellDef>Demographics</th>
                    <td class="datacell" mat-cell *matCellDef="let element">{{element.demographics}}</td>
                  </ng-container>

                  <ng-container matColumnDef="MarketingPlan">
                    <th class="headerCell" mat-header-cell mat-sort-header *matHeaderCellDef>Marketing Plan</th>
                    <td class="datacell" mat-cell *matCellDef="let element">{{element.marketingPlan}}</td>
                  </ng-container>

                  <ng-container matColumnDef="Rate">
                    <th class="headerCell" mat-header-cell mat-sort-header *matHeaderCellDef>Coverage Rate</th>
                    <td class="datacell" mat-cell *matCellDef="let element">
                      {{element.rate |
                      currency:'USD':'symbol'}}</td>
                  </ng-container>


                  <tr mat-header-row *matHeaderRowDef="displayedColumnsAvailCoverage"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsAvailCoverage;"></tr>
                </table>


                <!--Add On Coverages-->
                <span class="headerLabel" *ngIf="availableAddonCoverages.length>0"> Add-ons </span>

                <table mat-table #table [dataSource]="availableAddonCoverages" matSort [id]="'tableAddon'">
                    <ng-container matColumnDef="RowID">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
                      <td style="width: 45px" mat-cell *matCellDef="let element">
                          <mat-checkbox [name]="element.rowID" [id]="'tablecheck'+element.rowID"
                                [checked]="element.isChecked" (change)="selectItem(element,$event)">
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Activity">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>Activity</th>
                      <td mat-cell *matCellDef="let element">{{element.activity}}</td>
                    </ng-container>

                    <ng-container matColumnDef="Demographics">
                      <th class="headerCell" mat-header-cell mat-sort-header *matHeaderCellDef>Demographics</th>
                      <td class="datacell" mat-cell *matCellDef="let element">{{element.demographics}}</td>
                    </ng-container>

                    <ng-container matColumnDef="MarketingPlan">
                      <th class="headerCell" mat-header-cell mat-sort-header *matHeaderCellDef>Marketing Plan</th>
                      <td class="datacell"  mat-cell *matCellDef="let element">{{element.marketingPlan}}</td>
                    </ng-container>

                    <ng-container matColumnDef="Rate">
                      <th class="headerCell" mat-header-cell mat-sort-header *matHeaderCellDef>Coverage Rate</th>
                      <td class="datacell" mat-cell *matCellDef="let element">{{element.rate |
                            currency:'USD':'symbol'}}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsAvailCoverage" [class.d-none]="availableAddonCoverages.length == 0"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsAvailCoverage;"></tr>
                </table>
              </div>
            </div>

            <div class="row">
              <div class="d-flex justify-content-end align-items-center">
                <div class="d-inline-flex flex-wrap py-1">
                  <div class="amountlabel me-3">Payable Amount</div>
                  <div class="amountlabel me-3">{{payableAmount |currency:'USD':'symbol'}}</div>
                </div>
                <div class="py-1">
                  <button
                    mat-button
                    class="nextbutton"
                    id="nextBtn"
                    (click)="GotoStudentDetailsTab()"
                    [disabled]="!toAllowNext">Next<img src="assets/images/right.svg" alt="Unable to load image">
                  </button>
                </div>
              </div>
            </div>

            <!-- <div class="row" id="paymentRow">
              <div class="col-lg-3 col-md-3 col-sm-6"></div>
              <div class="col-lg-9 col-md-9 col-sm-6 payInfo">
                <div class="paymentText">
                  <div class="amountlabel">Payable Amount</div>
                  <div class="amountlabel">{{payableAmount |currency:'USD':'symbol'}}</div>
                </div>
                <div>
                  <button mat-button class="nextbutton" id="nextBtn" (click)="GotoStudentDetailsTab()"
                    [disabled]="!toAllowNext"> Next<img src="assets/images/right.svg" alt="Unable to load image"></button>
                </div>
              </div>
            </div> -->

          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<ng-container *ngIf="showIdCard">
  <app-id-card></app-id-card>
  </ng-container>
