import { Component, OnInit } from '@angular/core';
import { MyProfile } from 'src/app/models/myprofile.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UserdetailsService } from 'src/app/services/profile/userdetails.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  userDetail: MyProfile = {
    fullname: "",
    emailId: ""
  };
  shortName: string = "";
  isGeneral: boolean = true;
  isStudentDetails: boolean = false;
  isChangePassword: boolean = false;
  constructor(private authService:AuthenticationService, private router: Router,
    private profileService: UserdetailsService,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry){
      this.matIconRegistry.addSvgIcon("General",this.domSanitizer
      .bypassSecurityTrustResourceUrl('assets/images/icons/horizontal.svg'));
      this.matIconRegistry.addSvgIcon("Student",this.domSanitizer
      .bypassSecurityTrustResourceUrl('assets/images/icons/circle.svg'));
      this.matIconRegistry.addSvgIcon("ChangePassword",this.domSanitizer
      .bypassSecurityTrustResourceUrl('assets/images/icons/bank.svg'));
      //clicked icons
      this.matIconRegistry.addSvgIcon("GeneralClicked",this.domSanitizer
      .bypassSecurityTrustResourceUrl('assets/images/icons/horizontalClicked.svg'));
      this.matIconRegistry.addSvgIcon("StudentClicked",this.domSanitizer
      .bypassSecurityTrustResourceUrl('assets/images/icons/circleClicked.svg'));
      this.matIconRegistry.addSvgIcon("ChangePasswordClicked",this.domSanitizer
      .bypassSecurityTrustResourceUrl('assets/images/icons/bankClicked.svg'));
  }
  ngOnInit(): void {
    this.authService.userDetail.subscribe((value)=>{
      this.userDetail= value
      this.userDetail.emailId = value.email
    });
    this.profileService.fullName.subscribe(fullname => {
      if(fullname!=null||fullname!=''){
        this.userDetail.fullname = fullname;
        const names = this.userDetail.fullname.split(' ');
        let shortName = names[0].substring(0,1)+names[1].substring(0,1);
        this.shortName = shortName.toUpperCase();
      }
    })
    this.userDetail.fullname = this.userDetail.firstName?.trim() + " " + this.userDetail.lastName?.trim();
    const names = this.userDetail.fullname.split(' ');

    let shortName = names[0].substring(0,1)+names[1].substring(0,1);
    this.shortName = shortName.toUpperCase();
    
    
    
  }
  toggleGeneral(){
    this.isGeneral = true;
    this.isChangePassword=false;this.isStudentDetails=false;
  }
  toggleStudentDetail(){
    this.isGeneral = false;this.isChangePassword=false;this.isStudentDetails=true;
  }
  toggleChangePassword(){
    this.isGeneral = false;this.isChangePassword=true;this.isStudentDetails=false;
  }
}
