import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UnderWriter } from 'src/app/models/Brochure.model';
import { StudentInfo } from 'src/app/models/coverages/studentInfo.model';
import { Coverage } from 'src/app/models/enrol/coverage.model';
import { Student } from 'src/app/models/student.model';
import { MyCoverageService } from 'src/app/services/coverages/mycoverage.service';
import { EnrolService } from 'src/app/services/enrol/enrol.service';
import { RatesService } from 'src/app/services/rates/rates.service';
import { StudentDetailsService } from 'src/app/services/studentdetails/student-details.service';

@Component({
  selector: 'app-view-student-dialog',
  templateUrl: './view-student-dialog.component.html',
  styleUrls: ['./view-student-dialog.component.scss'],
})
export class ViewStudentDialogComponent implements OnInit {
  student = new Student(
    '',
    '',
    0,
    '',
    '',
    '',
    '',
    '',
    '',
    0,
    '',
    '',
    '',
    '',
    0,
    ""
  );

  coverages: Coverage[] = [];
  underwriters: UnderWriter = {
    insCode: '',
    insCo: '',
    state: '',
    address1: '',
    address2: '',
    city: '',
    zip: '',
    logoLoc: '',
  };

  displayedColumns: string[] = ['Name', 'Demographics', 'MarketingPlan'];
  isDataLoad: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private studentDetailService: StudentDetailsService,
    private ratesService: RatesService,
    private myCoverageService: MyCoverageService,
    private router: Router,
    private enrolService: EnrolService,
    private dialogRef: MatDialogRef<ViewStudentDialogComponent>,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.student = this.data;
    this.student.campusType = this.getCampusTypeFromLvlCde();

    this.getPurchasedCoverages();
  }
  getCampusTypeFromLvlCde() {
    if (this.student.levelCode == 'E') return 'Elementary';
    else if (this.student.levelCode == 'M') {
      return 'Middle School';
    } else if (this.student.levelCode == 'H') {
      return 'High School';
    } else if (this.student.levelCode == 'O') {
      return 'College/Adult';
    } else {
      return '';
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getPurchasedCoverages() {
    this.spinner.show('default');
    this.studentDetailService
      .getCoverages(this.student.studentID.toString())
      .subscribe({
        next: (result: any) => {
          this.coverages = result.body as Coverage[];
          this.isDataLoad = true;
          if (this.coverages.length > 0) {
            this.getUnderWriter(parseInt(this.coverages[0].policyNo));
          }
          this.spinner.hide('default');
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }

  getUnderWriter(policyNo: number) {
    this.ratesService.getBrochure(policyNo).subscribe({
      next: (result: any) => {
        this.underwriters = Object.assign(
          new UnderWriter(),
          result.body.underwriterModel
        );
      },
      error: (err: any) => {
        console.log(err);
      },
    });
  }

  buyNewCoverages(isBuyAgain: boolean) {
    let student = new StudentInfo('', '', '', '', 0, 0, '', '', [], 0,'','','');
    student.firstName = this.student.firstName;
    student.lastName = this.student.lastName;
    student.state = this.student.state;
    student.campusID = this.student.campus;
    student.campusType = this.student.levelCode;
    student.studentID = this.student.studentID;
    student.dob = this.student.dob;
    student.gradeID = Number(this.student.grade);
    student.phone = this.student.phone;
    student.district = this.student.schoolDistrictName;
    student.coverages = this.coverages;
    student.campusName = this.student.campusName;
    student.schoolDistrictName = this.student.schoolDistrictName;
    student.ssn = this.student.ssn;
    this.myCoverageService.setBuyAgainCoverageFlag(isBuyAgain);

    this.myCoverageService.setBuyCoveragesFlag(true);
    this.myCoverageService.setStudToBuyCoverages(student);
    this.router.navigate(['/enroll']);
    this.dialogRef.close();
  }
}
