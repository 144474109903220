import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CancelRequestComponent } from './features/cancelrequest/cancel-request/cancel-request.component';
import { FaqsComponent } from './features/faqs/faqs/faqs.component';
import { ContactUsComponent } from './features/contact-us/contact-us/contact-us.component';
import { ChangePasswordComponent } from './features/my-profile/change-password/change-password/change-password.component';
import { MyProfileComponent } from './features/my-profile/my-profile.component';
import { GeneralComponent } from './features/my-profile/general/general.component';
import { PaymentComponent } from './features/enrol/payment/payment/payment.component';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [

  // { path: '', loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule)},
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'rates', loadChildren: () => import('./features/rates/rates.module').then(m => m.RatesModule)  },
  { path: 'home', loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule)  },
  { path: 'enroll', loadChildren: () => import('./features/enrol/enrol.module').then(m => m.EnrolModule) },
  { path: 'cancel-request', component: CancelRequestComponent },
  { path: 'faqs', component: FaqsComponent },
  {path: 'paymentsuccess', component:PaymentComponent, canActivate: [AuthGuard]},

  { path:'contact-us',component:ContactUsComponent },
  { path:'myprofile',component:MyProfileComponent, canActivate: [AuthGuard]},
  { path:'myprofile/general',component:GeneralComponent, canActivate: [AuthGuard]},
  { path:'myprofile/change-password',component:ChangePasswordComponent},
  { path: 'coverages', loadChildren: () => import('./features/coverages/coverages.module').then(m => m.CoveragesModule) , canActivate:[AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
