import { StudentInfo } from "./studentInfo.model";

export class IDCard {
    student: StudentInfo = new StudentInfo("", "", "", "", 0, 0, "", "", [], 0,'','','');
    underWriter: string = "";
    isDownload: boolean = false;
    constructor(student:StudentInfo, underWriter:string, isDownload:boolean){
        this.student=student;
        this.underWriter = underWriter;
        this.isDownload = isDownload;
    }

}