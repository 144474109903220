import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthorizenetPaymentComponent } from './authorizenet-payment.component';



@NgModule({
  declarations: [
    AuthorizenetPaymentComponent
    
  ],
  imports: [
    CommonModule
  ],
  exports:[AuthorizenetPaymentComponent]
})
export class AuthorizenetPaymentModule { }
