<div class="row">
    <div class="accordion ms-auto " [id]="'accordionExample'+i"
        *ngFor="let student of enrollData.studentDetail;let i = index">
        <div class="accordion-item">
            <h2 class="accordion-header" [id]="'heading'+i">
                <div class="row accheader">
                    <div class="col-lg-10">
                        <div class="row">
                            <div class="col-lg-4" style="display: flex;">
                                <div class="accHeaderLabel">Student First Name</div>
                                <div class="accSubHeader">{{student.firstName}}</div>
                            </div>
                            <div class="col-lg-4" style="display: flex;">
                                <div class="accHeaderLabel">State</div>
                                <div class="accSubHeader">{{student.state}}</div>
                            </div>

                            <div class="col-lg-4" style="display: flex;">
                                <div class="accHeaderLabel">Coverage Year</div>
                                <div class="accSubHeader"> {{student.coverageYear}} - {{year+1}}</div>
                            </div>
                        </div>
                        <div class="row" style="margin-top: 1rem;">
                            <div class="col-lg-10" style="display: flex;">
                                <div class="accHeaderLabel" style="margin-right: 0.3rem;">School</div>
                                <div class="accSubHeader">{{student.school}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2" style="display: flex;justify-content: flex-end;">

                        <div>
                            <button mat-button class="deleteBtn" (click)="deleteStudent(student, i)" [id]="'delete'+i">
                                <img src="assets\images\alt.jpg" alt="">
                            </button>
                        </div>
                        <div>
                            <button class="accordion-button coverageAccordion" type="button" data-bs-toggle="collapse"
                                [id]="'btn'+i" [attr.data-bs-target]="'#collapse'+i" [attr.aria-controls]="'collapse'+i"
                                [ngClass]="{ 'collapsed': i!=0 }">
                            </button>
                        </div>

                    </div>
                </div>
            </h2>

            <!---->
            <div [id]="'collapse'+i" class="accordion-collapse collapse " [ngClass]="{ 'show': i == enrollData.studentDetail.length-1}"
                [attr.data-bs-parent]="'#accordionExample'+i" [attr.aria-labelledby]="'heading'+i">
                <div class="accordion-body" id="accBody">
                    <mat-table #table [dataSource]="student.coverageList" matSort [id]="'table'+i">
                        <ng-container matColumnDef="RowID">
                            <th style="width: 20%;text-align:center;" class="headerCell" mat-header-cell mat-sort-header *matHeaderCellDef>
                            </th>
                            <td style="width: 20%;text-align:center;" mat-cell *matCellDef="let element">
                                <div *ngIf="element.inputType == 'radio'">
                                    <mat-radio-button layout-xs="row" class="small-radio"
                                        [id]="'table'+i+'radio'+element.rowID" [name]="student.groupName"
                                        [checked]="element.isChecked"
                                        (change)="selectItem(element, student, $event)">
                                    </mat-radio-button>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Activity">
                            <th style="width: 45%;" class="headerCell" mat-header-cell mat-sort-header
                                *matHeaderCellDef><span class="headerCellText"> Activity</span></th>
                            <td style="width: 45%;text-align: left;" class="datacell" mat-cell *matCellDef="let element">
                                <div class="=row">
                                {{element.activity}}
                                <mat-form-field *ngIf="element.subCategories?.length>0 && (student.campusTypeId == 'H' || student.campusTypeId == 'M')">
                                        <mat-label>Select All Sports/Activities student performs</mat-label>
                                        <mat-select [(ngModel)]="element.selectedSubCats" multiple>
                                          <mat-option *ngFor="let cat of element.subCategories" [value]="cat.choiceID">{{cat.choiceName}} [{{cat.choiceCode}}]</mat-option>
                                      </mat-select>
                                </mat-form-field>
                            </div>
                            </td>

                        </ng-container>

                        <ng-container matColumnDef="Demographics">
                            <th style="width: 30%;" class="headerCell" mat-header-cell mat-sort-header *matHeaderCellDef><span
                                    class="headerCellText">Demographics</span></th>
                            <td style="width: 30%;text-align: left" class="datacell" mat-cell *matCellDef="let element">{{element.demographics}}</td>
                        </ng-container>

                        <ng-container matColumnDef="MarketingPlan">
                            <th style="width: 25%;" class="headerCell" mat-header-cell mat-sort-header *matHeaderCellDef><span
                                    class="headerCellText">Marketing Plan</span></th>
                            <td style="width: 25%;text-align:left"class="datacell" mat-cell *matCellDef="let element">{{element.marketingPlan}}</td>
                        </ng-container>

                        <ng-container matColumnDef="Rate">
                            <th style="width: 25%;" class="headerCell" mat-header-cell mat-sort-header *matHeaderCellDef><span
                                    class="headerCellText">ANNUAL Rate</span></th>
                            <td style="width: 25%;text-align: left" class="datacell" mat-cell *matCellDef="let element">{{element.rate |
                                currency:'USD':'symbol'}}</td>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </mat-table>

                    <!--Add On Coverages-->
                    <span class="addons" *ngIf="student.addOnCoverageList.length>0"> Add-ons </span>

                    <mat-table #table [dataSource]="student.addOnCoverageList" matSort [id]="'tableAddon'+i">
                        <ng-container matColumnDef="RowID">
                            <td style=" width: 20%;text-align: center;position: relative; left: 0.6rem;" mat-cell *matCellDef="let element">
                                <mat-checkbox [name]="'chk'+ student.groupName" [id]="'table'+i+'check'+element.rowID"
                                    [checked]="element.isChecked" (change)="selectItem(element, student,$event)">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Activity">
                            <td style="width: 45%;"  mat-cell *matCellDef="let element">
                                {{element.activity}}</td>
                        </ng-container>

                        <ng-container matColumnDef="Demographics">
                            <td style="width: 30%;" mat-cell *matCellDef="let element">{{element.demographics}}</td>
                        </ng-container>

                        <ng-container matColumnDef="MarketingPlan">
                            <td style="width: 25%;"  mat-cell *matCellDef="let element">{{element.marketingPlan}}</td>
                        </ng-container>

                        <ng-container matColumnDef="Rate">
                            <td style="width: 25%;" mat-cell *matCellDef="let element">{{element.rate |
                                currency:'USD':'symbol'}}</td>
                        </ng-container>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </mat-table>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="newOrSavedStudent === 'New' && showForm" style="margin-top: 1rem;">
    <div class="row" style="margin-bottom: 0.8rem;">
        <div class="col-lg-4 col-md-4 col-sm-3">
            <div class="coveragedet-label">Student First Name</div>
            <input type="text" id="studFirstName" class="coveragedet-txt" placeholder="Enter Student First Name" autocomplete="nocando"
                [(ngModel)]="studFirstName" aria-placeholder="Enter Student First Name" maxlength="30">
        </div>
        <div class="col-lg-4 col-md-3 col-sm-3">
            <div class="coveragedet-label">State</div>
            <select class="inputBox" id="state" [(ngModel)]="selectedState" (change)="getDistricts()" #stateSelected>
                <option value="" disabled [selected]="selectedState == ''">Select State</option>
                <option *ngFor="let st of states" [value]="st.state">
                    {{st.name}}
                </option>
            </select>
        </div>
        <div class="col-lg-4 col-md-3 col-sm-3">
            <div class="coveragedet-label">School District</div>
            <select class="inputBox" id="distict" aria-placeholder="Enter School District" (change)="getCampus()"
                [(ngModel)]="selectedDistrict" #districtSelect>
                <option value="" disabled selected>Select School District</option>
                <option *ngFor="let dist of districts" [value]="dist.policyNo">
                    {{dist.client}}
                </option>
            </select>
        </div>
        <div class="col-md-2">
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-md-4">
            <div class="coveragedet-label">Campus Name</div>
            <select class="inputBox" id="campus" [(ngModel)]="selectedCampus">
                <option value="" disabled selected>Select Campus</option>
                <option *ngFor="let camp of campus" [value]="camp.campusID">
                    {{camp.campusName}}
                </option>
            </select>
        </div>
        <div class="col-lg-4 col-md-4">
            <div class="coveragedet-label">Campus Type</div>
            <select class="inputBox " id="campusType" [(ngModel)]="selectedCampusType">
                <option value="" disabled selected>Select Campus Type</option>
                <option value="E">Elementary</option>
                <option value="M">Middle School</option>
                <option value="H">High School</option>
                <option value="O">College/Adult</option>
            </select>
        </div>
        <div class="col-lg-3 col-md-4" id="addCovbtn">
            <button value="Add Student" class="coveragedet-button" (click)="getCoverages()"
                [disabled]="getDisableAddCovg()">Add Student</button>
        </div>

    </div>


</div>
<div *ngIf="newOrSavedStudent === 'Saved' && showForm">
    <div class="row">
        <div class="col-lg-12">
            <div class="coveragedet-label">Student Name</div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-10">
            <select class="inputBox" id="savedStudents" [(ngModel)]="selectedStudent">
                <option value="" disabled selected>Select Student</option>
                <option *ngFor="let stud of enrolledStudents" [value]="stud.studentID">
                    {{stud.firstName}} {{stud.lastName}}
                </option>
            </select>
        </div>
        <div class="col-lg-2" id="getCovgBtn">

            <button value="Add Student" class="coveragedet-button"
                (click)="getCoverages('Saved')" [disabled]="getDisableAddCovg()">Add Student</button>
        </div>
    </div>
</div>


<div class="row" id="paymentRow">
    <div class="col-lg-3 col-md-3 col-sm-6">
        <div *ngIf="!showForm && !disableAddCoverage">
            <button mat-button class="prevbutton" id="prevBtn" (click)="showForm = true;"
                > Add Another Student</button>
        </div>
    </div>
    <div class="col-lg-9 col-md-9 col-sm-6 payInfo">
        <div class="paymentText" >
            <div class="amountlabel">Payable Amount</div>
            <div class="amountlabel">{{ enrollData.payableAmount | currency:'USD':'symbol'}}</div>
        </div>
        <div>
            <button mat-button class="nextbutton" id="nextBtn" (click)="GotoStudentDetailsTab()"
                [disabled]="!toAllowNext"> Next<img src="assets/images/right.svg" alt="Unable to load image"></button>
        </div>
    </div>
</div>
