import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MyProfileComponent } from './my-profile.component';
import { GeneralComponent } from './general/general.component';
import { ChangePasswordComponent } from './change-password/change-password/change-password.component';
import { StudentDetailsComponent } from './student-details/student-details/student-details.component';



const routes: Routes = [
  { path: '', 
  component: MyProfileComponent,
  children: [
    {
      path: 'general',
      component: GeneralComponent,
    },
    {
      path: 'student-details',
      component: StudentDetailsComponent
    },
    {
      path: 'change-password',
      component: ChangePasswordComponent,
    }
  ],
  },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MyProfileRoutingModule { }
