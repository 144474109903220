import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Observable } from "rxjs";
import { Login } from "src/app/models/login.model";
import { AuthenticationService } from "src/app/services/authentication.service";
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/models/User.model';



  @Injectable({
    providedIn: 'root',
  })
  export class AuthGuard {
    constructor(public authService: AuthenticationService, public router: Router,
      public jwtHelper: JwtHelperService, public toastr: ToastrService) { }
    canActivate(
      next: ActivatedRouteSnapshot,

    ): Observable<boolean> | Promise<boolean> | UrlTree | boolean {


      const token = sessionStorage.getItem("jwt");
      if (this.authService.isTempPassword) {
        this.router.navigate(['/myprofile/change-password'])
        return false;
      }
      if (this.jwtHelper.isTokenExpired(token)) {
        //TBD: To replace below alert with toast or some message service
        alert("Your session is ended. Please login again.");
        this.authService.isAuthUser.next(false);
        this.authService.loggedInUser.next(new Login("", "", "", "", "", "", "", "", "", ""));
        sessionStorage.removeItem("jwt");
        this.router.navigate(['home']);
      }

      const loggedUser:Login = JSON.parse(sessionStorage.getItem('loggedUser')??"");
      if(loggedUser && loggedUser.email && loggedUser.email != '' && token && !this.jwtHelper.isTokenExpired(token) && !this.authService.isAuthUser.value){
        let user = new User();
          user.firstName = loggedUser.firstName;
          user.lastName = loggedUser.lastName;
          user.email = loggedUser.email;
          user.address = loggedUser.address1;
          user.city = loggedUser.city;
          user.state = loggedUser.stateCode;
          user.zip = Number(loggedUser.zip);
          this.authService.isAuthUser.next(true);
          this.authService.loggedInUser.next(new Login(loggedUser.email, "", loggedUser.firstName, loggedUser.lastName, loggedUser.accountId, loggedUser.address1, loggedUser.address2, loggedUser.stateCode, loggedUser.city, loggedUser.zip));
          this.authService.loggedInUserId.next(loggedUser.accountId);
          this.authService.setUserDetailModel(user);
      }

      //Check if the token is expired or not and if token is expired then redirect to login page and return false
      if (token && !this.jwtHelper.isTokenExpired(token) && this.authService.isAuthUser.value) {
        return true;
      }

      return false;
    }
  }
