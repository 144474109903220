import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[appDOBValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: DOBValidatorDirective, multi: true }]
})
export class DOBValidatorDirective implements Validator {
  constructor() { }

  validate(control: AbstractControl): ValidationErrors | null {
    let maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 2);

    if (control.value == '') {
      return { blankDOB: true }
    }

    if (new Date(control.value) > maxDate) {
      return { invalidDOB: true }
    }

    return null;
  }
}
