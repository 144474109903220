<div class="container studentdetails">
    <div class="row">
        <form name="form" #f="ngForm">
            <div class="row usernameRow">
                <div class="parentHeader">
                    <div class="headerText">Parent/Guardian Details</div>
                </div>
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-4 form-group">
                        <label for="fname" class="parentLabel">First Name</label>
                        <input type="text" class="form-control" id="fname" name="firstname" [pattern]="ufirstnamePattern"
                            placeholder="Enter First Name" [(ngModel)]="enroll.firstname" required   [disabled]="userLoggedIn"
                            #firstname="ngModel" [ngClass]="{ 'is-invalid': f.submitted && firstname.errors }" appEmptyStringValidator
                            maxlength="30" />
                        <div *ngIf="firstname.invalid && (firstname.dirty || firstname.touched || f.submitted)" class="errorMessage">
                            <div *ngIf="firstname.errors?.['emptyString'] ||firstname.errors?.['required']">
                                Missing item - First Name!
                            </div>
                            <div *ngIf="firstname.errors?.['pattern']" style="font-size: 13px;">
                                The First Name can only contain Hyphens(-),apostrophe('),spaces and characters.
                            </div>

                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-4 form-group">
                        <label for="lname" class="parentLabel">Last Name</label>
                        <input type="text" class="form-control" id="lname" name="lastname" [pattern]="unamePattern" [disabled]="userLoggedIn"
                            placeholder="Enter Last Name" [(ngModel)]="enroll.lastname" required  appEmptyStringValidator
                            #lastname="ngModel" [ngClass]="{ 'is-invalid': f.submitted && lastname.errors }"
                            maxlength="30" />
                        <div *ngIf="lastname.invalid && (lastname.dirty || lastname.touched || f.submitted)" class="errorMessage">
                            <div *ngIf="lastname.errors?.['emptyString'] ||lastname.errors?.['required']">
                                Missing item - Last Name!
                            </div>
                            <div *ngIf="lastname.errors?.['pattern']" style="font-size: 13px;">
                                The Last Name can only contain Hyphens(-),apostrophe('),spaces and characters.
                            </div>

                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-4 form-group">
                        <label for="email" class="parentLabel">Email Address</label>
                        <input type="email" class="form-control" id="email" name="email" [(ngModel)]="enroll.email"
                            placeholder="Enter Mail Address" required email #email="ngModel" appEmptyStringValidator [disabled]="userLoggedIn"
                            [ngClass]="{ 'is-invalid': f.submitted && email.errors }"
                            pattern="[a-zA-Z0-9\.\-\_]{1,}@[a-zA-Z\.\-]{2,}[.]{1}[a-zA-Z]{2,}"
                            maxlength="100"
                            (blur)="onBlurCheckEmail()" />
                        <div *ngIf="email.invalid && (email.dirty || email.touched || f.submitted)" class="errorMessage">
                            <div *ngIf="email.errors?.['emptyString']||email.errors?.['required']">Missing item - Email Address!</div>
                            <div *ngIf="email.errors?.['pattern']"> Email address is invalid</div>
                            <div *ngIf="emailhasCom" class="errorMessage">
                                Email must end with ".com"
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row addressRow">
                <div class="parentHeader">
                    <div class="headerText">Address</div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-4 form-group">
                        <label class="parentLabel">Street Address</label>
                        <input type="text" class="form-control" id="streetaddress" name="streetaddress" [(ngModel)]="enroll.streetaddress"
                            placeholder="Enter Street Address" required #streetaddress="ngModel" appEmptyStringValidator
                             />
                        <div *ngIf="streetaddress.invalid && (streetaddress.dirty || streetaddress.touched || f.submitted)"
                            class="errorMessage">
                            <div *ngIf="streetaddress.errors?.['emptyString'] || streetaddress.errors?.['required']">
                                Missing item - Street Address!
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-4 form-group">
                        <label class="parentLabel">City</label>
                        <input type="text" class="form-control" id="city" name="city" [(ngModel)]="enroll.city" appEmptyStringValidator
                            placeholder="Enter City" required #city="ngModel"  />
                        <div *ngIf="city.invalid && (city.touched || city.dirty || f.submitted)" class="errorMessage">
                            <div *ngIf="city.errors?.['emptyString'] || city.errors?.['required']">
                                Missing item - City !
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-4 form-group">
                        <div class="selectDropdown">
                            <label class="parentLabel">State</label>
                            <select class="inputBox" id="parentState" [(ngModel)]="enroll.state" name="state"
                                #state="ngModel" required appEmptyStringValidator>
                                <option value="" disabled selected>Select State</option>
                                <option *ngFor="let st of states" [value]="st.state">
                                    {{st.name}}
                                </option>
                            </select>
                        </div>
                        <div *ngIf="state.invalid && (state.touched || state.dirty || f.submitted)" class="errorMessage">
                            <div *ngIf="state.errors?.['emptyString'] ||state.errors?.['required']">
                                Missing item - State !
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-4 form-group">
                        <label for="zip" class="parentLabel">Zip</label>
                        <input type="text" class="form-control" id="zip" name="zip" [pattern]="zipPattern"
                            placeholder="Enter zip" [(ngModel)]="enroll.zip" required #zip="ngModel" appEmptyStringValidator
                            [ngClass]="{ 'is-invalid': f.submitted && zip.errors }"  />
                        <div *ngIf="zip.invalid && (zip.dirty || zip.touched || f.submitted)" class="errorMessage">
                            <div *ngIf="zip.errors?.['emptyString'] || zip.errors?.['required']">Missing item - Zipcode!</div>

                            <div *ngIf="zip.errors?.['pattern'] && zip.value.length>=1 &&zip.value.length<6">
                                Zip Code must be atleast 5 digit
                            </div>
                            <div *ngIf="zip.errors?.['pattern']  && zip.value.length>=6">
                                Zip Code entered should be in correct format (xxxxx-xxxx)
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="row">
      <div class="student">
          <div class="studentHeader">
              <div class="headerText">Student Details</div>
          </div>
          <div class="row" [formGroup]="form" style="width: 100%;">
              <ng-container formArrayName="students">
              <div class="accordion md-auto" style="padding-bottom: 1rem;" *ngFor="let coverage of enroll.studentDetail;let i = index">
                  <div class="accordion-item">
                      <h2 class="accordion-header" [id]="'heading'+i">
                          <div class="row accheader">
                              <div class="col-lg-11 col-md-11 col-sm-10">
                                  <div class="row" id="studInfo">
                                      <div class="col-lg-4 col-md-4 col-sm-12" id="headerInfo" style="display: flex;">
                                          <p id="headerLabel">Student First Name</p>
                                          <p id="headerValue">{{coverage.firstName}}</p>
                                      </div>
                                      <div class="col-lg-5 col-md-5 col-sm-12" id="headerInfo" style="display: flex;">
                                          <p id="headerLabel">Campus</p>
                                          <p id="headerValue">{{coverage.campusName}}</p>
                                      </div>
                                      <div class="col-lg-3 col-md-3 col-sm-12" id="headerInfo" style="display: flex;">
                                          <p id="headerLabel">State</p>
                                          <p id="headerValue">{{coverage.state}}</p>
                                      </div>

                                  </div>
                                  <div class="row" id="studInfo">
                                      <div class="col-lg-4 col-md-4 col-sm-12" id="headerInfo" style="display: flex;">
                                          <p id="headerLabel">Campus Type</p>
                                          <p id="headerValue" *ngIf="coverage.campusTypeId=='E'">Elementary</p>
                                          <p id="headerValue" *ngIf="coverage.campusTypeId=='M'">Middle School</p>
                                          <p id="headerValue" *ngIf="coverage.campusTypeId=='H'">High School</p>
                                          <p id="headerValue" *ngIf="coverage.campusTypeId=='O'">College/Adult</p>
                                      </div>
                                      <div class="col-lg-5 col-md-5 col-sm-12" id="headerInfo" style="display: flex;">
                                          <p id="headerLabel">School District</p>
                                          <p id="headerValue">{{coverage.school}}</p>
                                      </div>
                                      <div class="col-lg-3 col-md-3 col-sm-12" id="headerInfo">
                                          <!-- <p id="headerLabel">Coverage Expires</p> -->
                                          <p id="headerValue">
                                            <span
                                              class="badge d-flex justify-content-center flex-wrap px-2 pt-2"
                                              [ngClass]="{'1': 'text-bg-success', '2': 'text-bg-warning', '3': 'text-bg-danger' }[getCoverageExpiresColor(coverage.terminationDate)]"
                                              >
                                              <span class="me-2 pb-1">Coverage Expires</span>
                                              <span class="pb-1">{{coverage.terminationDate | date : 'MM/dd/yyyy'}}</span>
                                            </span>
                                          </p>
                                      </div>
                                  </div>
                              </div>

                              <div class="col-lg-1 col-md-1 col-sm-2">
                                  <button class="accordion-button studentAccordion collapsed" type="button" [id]="'collapseBtn'+i"
                                      data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse'+i"
                                      [attr.aria-controls]="'collapse'+i" [ngClass]="{ 'collapsed': i!=0 && studentForm(i).disabled}">
                                  </button>
                              </div>
                          </div>
                      </h2>
                      <div [id]="'collapse'+i" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading'+i" [ngClass]="{ 'show': i==0 || studentForm(i).enabled }">
                          <div class="accordion-body">
                              <form name="studentform" [formGroup]="studentForm(i)">
                                  <div class="row">
                                      <div class="col-lg-3 col-md-3 col-sm-4 form-group">
                                          <label [for]="'studFirstName'+i" class="parentLabel">First Name</label>
                                          <input type="text" class="form-control" [id]="'studFirstName'+i" name="firstName" [pattern]="ufirstnamePattern"
                                          [(ngModel)]="coverage.firstName" formControlName="firstName" placeholder="Enter First Name" appEmptyStringValidator
                                          required [ngClass]="{ 'is-invalid': f.submitted && firstname.errors }" autocomplete="nocando" maxlength="30" />
                                      <div *ngIf="studentForm(i).controls['firstName'].invalid && (studentForm(i).controls['firstName'].dirty||studentForm(i).controls['firstName'].touched || f.submitted)" class="errorMessage">
                                          <div *ngIf="studentForm(i).controls['firstName'].errors?.['emptyString'] || studentForm(i).controls['firstName'].errors?.['required']">
                                              Missing item - First Name!
                                          </div>
                                          <div *ngIf="studentForm(i).controls['firstName'].errors?.['pattern']">
                                              The First name can only contain Hyphens(-),apostrophe('), spaces
                                              and characters.
                                          </div>

                                      </div>
                                      </div>
                                      <div class="col-lg-3 col-md-3 col-sm-4 form-group">
                                          <label [for]="'studLastName'+i" class="parentLabel">Last Name</label>
                                          <input type="text" [id]="'studLastName'+i" class="form-control" name="lastname"
                                          [(ngModel)]="coverage.lastName" required [pattern]="unamePattern"
                                          formControlName="lastName" placeholder="Enter Last Name " appEmptyStringValidator
                                          [ngClass]="{ 'is-invalid': f.submitted && lastname.errors }"  autocomplete="nocando" maxlength="30" />
                                      <div *ngIf="studentForm(i).controls['lastName'].invalid && (studentForm(i).controls['lastName'].dirty || studentForm(i).controls['lastName'].touched || f.submitted )" class="errorMessage">
                                          <div *ngIf="studentForm(i).controls['lastName'].errors?.['emptyString'] ||studentForm(i).controls['lastName'].errors?.['required']">
                                              Missing item - Last Name!
                                          </div>
                                          <div *ngIf="studentForm(i).controls['lastName'].errors?.['pattern']">
                                              The Last name can only contain Hyphens(-),apostrophe('), spaces
                                              and characters.
                                          </div>

                                      </div>
                                      </div>
                                      <div class="col-lg-6 col-md-6 col-sm-4 form-group">
                                          <label [for]="'StudentId'+i" class="parentLabel">Student ID</label>
                                          <input type="text" class="form-control" name="StudentId" [id]="'StudentId'+i"
                                              placeholder="Enter Student ID" [(ngModel)]="coverage.ssn" required appEmptyStringValidator
                                              formControlName="ssn" appStudentidValidator [studentFirstName]="studentForm(i).controls['firstName'].value" [studentLastName]="studentForm(i).controls['lastName'].value"
                                              [ngClass]="{ 'is-invalid': f.submitted && studentForm(i).controls['ssn'].errors }" maxlength="50" />
                                          <div *ngIf="(studentForm(i).controls['ssn'].touched || studentForm(i).controls['ssn'].dirty || f.submitted)  && studentForm(i).controls['ssn'].invalid" class="errorMessage">
                                              <div *ngIf="studentForm(i).controls['ssn'].errors?.['required'] || studentForm(i).controls['ssn'].errors?.['emptyString']">
                                                  Missing item - Student ID!
                                              </div>
                                              <div *ngIf="studentForm(i).controls['ssn'].errors?.['uniqueStudentId'] && (studentForm(i).controls['ssn'].touched || studentForm(i).controls['ssn'].dirty)">
                                                  Student ID entered already exists!
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="row">
                                      <div class="col-lg-3 col-md-3 col-sm-4 form-group">
                                          <label [for]="'dob'+i" class="parentLabel">Date of Birth</label>
                                          <input type="date" class="form-control" [id]="'dob'+i" name="dob" max="{{ maxDate | date:'yyyy-MM-dd' }}"
                                            formControlName="dob" appDOBValidator />
                                          <div
                                            *ngIf="(studentForm(i).controls['dob'].touched || f.submitted || studentForm(i).controls['dob'].invalid) || studentForm(i).controls['dob'].errors?.['invalidDOB'] || studentForm(i).controls['dob'].errors?.['blankDOB']"
                                            class="errorMessage">
                                              <div *ngIf="(studentForm(i).controls['dob'].errors?.['required'] || studentForm(i).controls['dob'].errors?.['blankDOB']) && studentForm(i).controls['dob'].touched">
                                                  Missing item - Date of Birth!
                                              </div>
                                              <div
                                                *ngIf="studentForm(i).controls['dob'].errors?.['invalidDOB'] && studentForm(i).controls['dob'].touched"
                                                class="errorMessage">
                                                  Invalid Date of Birth!
                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-lg-3 col-md-3 col-sm-4 form-group">
                                          <label [for]="'grade'+i" class="parentLabel">Grade</label>
                                          <select name="gra" class="form-select" [id]="'grade'+i" [(ngModel)]="coverage.grade" appEmptyStringValidator
                                              placeholder="Select Grade" formControlName="grade" required>
                                              <option value="" disabled>Select Grade</option>
                                              <option value="0">K</option>
                                              <option value="1">1</option>
                                              <option value="2">2</option>
                                              <option value="3">3</option>
                                              <option value="4">4</option>
                                              <option value="5">5</option>
                                              <option value="6">6</option>
                                              <option value="7">7</option>
                                              <option value="8">8</option>
                                              <option value="9">9</option>
                                              <option value="10">10</option>
                                              <option value="11">11</option>
                                              <option value="12">12</option>
                                              <option value="13">Col</option>
                                              <option value="14">Other</option>

                                          </select>
                                          <div *ngIf="(studentForm(i).controls['grade'].touched || f.submitted || studentForm(i).controls['grade'].dirty) && studentForm(i).controls['grade'].invalid " class="errorMessage">
                                              <div *ngIf="studentForm(i).controls['grade'].errors?.['emptyString']">
                                              </div>
                                                  Missing item - Grade!

                                          </div>
                                      </div>
                                      <div class="col-lg-6 col-md-6 col-sm-4 form-group">
                                          <label [for]="'phone'+i">Phone No.</label>
                                          <input type="number" class="form-control" name="phone" [id]="'phone'+i"
                                              placeholder="Enter Phone No." id="phone"
                                              [pattern]="phonepattern" minlength="10" data-maxlength="10"
                                              oninput="this.value=this.value.slice(0,this.dataset.maxlength);"
                                              [(ngModel)]="coverage.phone" formControlName="phone" />

                                          <div *ngIf="(studentForm(i).controls['phone'].touched || f.submitted  || studentForm(i).controls['phone'].invalid)" class="errorMessage">
                                              <div *ngIf="studentForm(i).controls['phone'].errors?.['required']">
                                                  Missing item - Phone No!
                                              </div>
                                              <div *ngIf="studentForm(i).controls['phone'].errors?.['pattern']">
                                                  Invalid Phone No!
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </form>
                          </div>
                      </div>
                  </div>
              </div>
          </ng-container>
          </div>
      </div>
    </div>
    <button mat-button matStepperPrevious id="prevBtn" class="prevbutton" (click)="goPreviousTab()"><img src="assets/images/right.svg" alt="Unable to load image" class="prev">Previous</button>
    <button mat-button class="nextbutton" id="nextBtn" type="submit" (click)="checkEmail()">Next<img src="assets/images/right.svg" alt="Unable to load image"></button>
</div>
