import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Campus } from 'src/app/models/enrol/campus.model';
import { District } from 'src/app/models/enrol/district.model';
import { State } from 'src/app/models/enrol/state.model';
import { Student } from 'src/app/models/student.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EnrolService } from 'src/app/services/enrol/enrol.service';
import { LocationService } from 'src/app/services/location/location.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { StudentDetailsService } from 'src/app/services/studentdetails/student-details.service';
import { NgForm, NgModel } from '@angular/forms';

@Component({
  selector: 'app-add-student-dialog',
  templateUrl: './add-student-dialog.component.html',
  styleUrls: ['./add-student-dialog.component.scss']
})
export class AddStudentDialogComponent implements OnInit {

  AccountId: any;
  states: State[] = [];
  districts: District[] = [];
  selectedState: string = "";
  selectedDistrict: string = "";
  selectedCampus: string = "";
  selectedCampusType: string = "";
  campus:  Campus[]  =[];
  student = new Student("","",0,"","","","","","",0,"","","","",0,"");
  
 
  ufirstnamePattern = "^([a-zA-Z]{1,}?[a-z0-9 \\'\\-]{0,})*$";
  unamePattern = "^([a-zA-Z]{1,}?[a-z \\'\\-]{0,})*$";
  phonepattern =
  '^(\\+\\d{1,2}\\s?)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$|(?:NaN)';
  maxDate:any;
  isSubmitted: boolean = false;
  constructor(private auth: AuthenticationService,
    private locationService: LocationService,private enrolService: EnrolService,
    private studService:StudentDetailsService,
    private notifyService: NotificationService,
    
    private dialogRef: MatDialogRef<AddStudentDialogComponent>) {

  }

  ngOnInit() {
    this.auth.loggedInUserId.subscribe((res) => this.AccountId = res)
    this.student.accountId = this.AccountId;

    this.maxDate = new Date().toDateString();
    this.getCoverageStates();
  }
  getCoverageStates() {
    this.locationService.getCoverageStates().subscribe({
      next: (result: any) => {

        result.body.forEach((item: { name: string; state: string; }) => {
          let data = {} as State;
          data = {
            name: item.name,
            state: item.state,
            postalAbbreviation: "",
            countryCode: "",
            capitalCity: "",
            standardAbbreviation: ""
          };

          this.states.push(data);
        });
      },
    });
  }


  getDistricts(isChange?: boolean) {
    this.districts = [ ];
    this.campus = [];
    if (isChange) {
      this.student.schoolDistrict ='';
      this.student.campus = 0;
    }

    this.locationService.getDistricts(this.student.statecode).subscribe({
      next: (result: any) => {

        result.body.forEach((item: { policyNo: number; client: string; }) => {
          let data = {} as District;
          data = {
            policyNo: item.policyNo,
            client: item.client
          };

          this.districts.push(data);
          this.student.state = this.states.find((i)=>i.state==this.student.statecode)?.name!
        });
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }
  getCampus() {
    this.campus = [];

    this.enrolService.getCampus(this.student.schoolDistrict).subscribe({
      next: (result: any) => {

        result.body.forEach((item: { campusID: number; campusName: string; }) => {
          let data = {} as Campus;
          data = {
            campusID: item.campusID,
            campusName: item.campusName
          };

          this.campus.push(data);
          
        });
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }
  cancel(){
    this.dialogRef.close("");
  }
  closeDialog(){
    this.dialogRef.close();
  }
  showCancleNotification(){
    this.notifyService.showError("Student details not created ");
  }
  showSaveNotification(){
    this.notifyService.showSuccess("Student created successfully");
  }

 saveStudent(form:NgForm, student:Student){
  if(form.invalid)
  return;
 
  this.isSubmitted =true;
  this.student.levelCode= this.selectedCampusType;
  this.student.phone = this.student.phone?.toString();
  this.student.campusName = this.campus.find((x)=>x.campusID==this.student.campus)?.campusName!
  this.studService.saveStudent(student).subscribe((res:any)=>
  {
    if(res && res.status){
      this.showSaveNotification();
      this.dialogRef.close("");
    }
  
  }
  );

 }
}
