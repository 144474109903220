export class User {
    accountID?: number = 0;
    firstName: string = "";
    lastName: string = "";
    email: string = "";
    password: string = "";

    address: string = "";
    state: string = "";
    city: string = "";
    zip: number = 0;
}
