import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Login } from '../models/login.model';
import { User } from '../models/User.model';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  loginUrl: string = "Authentication/Login";
  registerUrl: string = "User/Register";
  changePasswordUrl: string = "ChangePassword/ChangeNewPassword";
  email: string = "";

  isAuthUser = new BehaviorSubject<boolean>(false);
  loggedInUserId = new BehaviorSubject<string>('');
  private userDetailModel = new BehaviorSubject<any>({});
  userDetail = this.userDetailModel.asObservable();
  loggedInUser = new BehaviorSubject<any>({});
  isTempPassword: boolean = false;
  postPayDetails = new BehaviorSubject<any>({});
  constructor(private httpClient: HttpClient) {

  }

  authenticate(login: Login): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    this.email = login.email;
    return this.httpClient.post(environment.baseUrl + this.loginUrl, login, httpOptions)
  }

  registerUser(login: User): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    return this.httpClient.post(environment.baseUrl + this.registerUrl, login, httpOptions)
  }
  setUserDetailModel(userDetail: User) {
    this.userDetailModel.next(userDetail)
  }
  getEmail() {
    return this.email;
  }
  getIsTempPassword() {
    return this.isTempPassword;
  }
}
