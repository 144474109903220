import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthorizenetService {

  constructor(private http: HttpClient) { }
  CreateAcceptPaymentTransaction(requestData: any): Observable<any[]> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(requestData);
    return this.http.post<any>(environment.baseUrl + "Payment/AcceptPayment" , body, { headers: headers });
}
}
