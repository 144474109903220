import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';


import { AccountAddress, AccountUpdate } from 'src/app/models/accountUpdate.model';
import { State } from 'src/app/models/enrol/state.model';
import { Login } from 'src/app/models/login.model';
import { MyProfile } from 'src/app/models/myprofile.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LocationService } from 'src/app/services/location/location.service';
import { UserdetailsService } from 'src/app/services/profile/userdetails.service';


@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralComponent implements OnInit {
  firstName: string = "";
  lastName: string = "";
  name: string = "";
  
  @Input("userDetail") userDetail:MyProfile = {
    fullname: "",
    emailId: ""
  };
  unamePattern = "^[a-zA-Z ]+(?:['-][a-zA-Z ]+)*$";
  zipPattern = "^[0-9]{5}(?:-[0-9]{4})?$";
  states: State[] = [];
  address = new AccountAddress();
  prevAddress = new AccountAddress();
  addressComplete: string = "";
  addressComplete2: string = "";
  isEdit: boolean = true;
  loggedUser!: Login;
  account: AccountUpdate = {
    prevFirstName: "",
    prevLastName: "",
    firstName: "",
    lastName: "",
    address1: "",
    address2: "",
    stateCode: "",
    city: "",
    zip: "",
  }
  constructor(private userDetailService: UserdetailsService,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private locationService: LocationService,
    private authService:AuthenticationService 
    )
  {
      this.matIconRegistry.addSvgIcon("edit", this.domSanitizer
    .bypassSecurityTrustResourceUrl('assets/images/edit.svg'));
    this.matIconRegistry.addSvgIcon("save", this.domSanitizer
    .bypassSecurityTrustResourceUrl('assets/images/save.svg'));
    this.matIconRegistry.addSvgIcon("reset", this.domSanitizer
    .bypassSecurityTrustResourceUrl('assets/images/reset.svg'));
  }
  ngOnInit(): void {
    this.authService.loggedInUser.subscribe(user => this.loggedUser = {...user});
    this.account.prevFirstName = this.userDetail.firstName!.trim();
    this.account.prevLastName = this.userDetail.lastName!.trim();
    this.account.firstName = this.userDetail.firstName!.trim();
    this.account.lastName = this.userDetail.lastName!.trim();
    this.getAllStates();
    this.getUserAddress();
  }
  getAllStates() {
    this.locationService.getAllStates().subscribe({
      next: (result: any) => {
        result.body.forEach((item: State) => {
          let data = {} as State;
          data = {
            name: item.name,
            state: item.state??item.postalAbbreviation,
            postalAbbreviation: "",
            countryCode: "",
            capitalCity: "",
            standardAbbreviation: ""
          };
          this.states.push(data);
        });
        this.states.sort((a,b)=>{return a.name < b.name? -1: 1});
      },
    });
  }
  getUserAddress(){

    this.userDetailService.getUserAddress(this.userDetail.emailId).subscribe({
      next: (result: any) => {
        this.address = Object.assign(new AccountAddress(), result.body);
        this.prevAddress = Object.assign(new AccountAddress(), result.body);
        if(this.checkIfEmpty(result.body)){
          this.addressComplete="";
          
        }else{
          this.getAddress(this.address);   
          }
       },
       error: (err: any) => {
         console.log(err);
       }
    });
  
  }
  onEdit(){
    this.isEdit = false;
    this.getUserAddress();
    this.account.firstName = this.account.prevFirstName;
    this.account.lastName = this.account.prevLastName;
  }
  save(){
    this.account.address1 = this.address.address1;
    this.account.address2 = this.address.address2;
    this.account.city = this.address.city;
    this.account.stateCode = this.address.stateCode;
    this.account.zip = this.address.zip;
    this.loggedUser.address1 = this.address.address1;
    this.loggedUser.address2 = this.address.address2;
    this.loggedUser.city = this.address.city;
    this.loggedUser.stateCode = this.address.stateCode;
    this.loggedUser.zip = this.address.zip;    
    this.loggedUser.firstName = this.account.firstName;    
    this.loggedUser.lastName = this.account.lastName;    
    this.userDetailService.updateUser(this.account).subscribe({
       error: (err: any) => {
         console.log(err);
       }
    });
    this.authService.loggedInUser.next({...this.loggedUser})
    this.isEdit=true;
    this.account.prevFirstName = this.account.firstName;
    this.account.prevLastName = this.account.lastName;
    this.getAddress(this.address);

    this.userDetailService.setFullName(this.account.firstName?.trim() + " " + this.account.lastName?.trim());
     
  }
  resetChanges(){
    this.account.firstName = this.account.prevFirstName;
    this.account.lastName = this.account.prevLastName;
    this.getUserAddress();
    this.isEdit =true;
  }
  checkIfEmpty(address:any){
    if(address.address1.trim()=='' && address.address2.trim()=='' &&
    address.city.trim()=='' && address.stateCode.trim()==''&& address.zip.trim()=='')
      return true;
    return false;
}
  getAddress(address:AccountAddress){
    if(this.checkIfEmpty(address)){
      this.addressComplete="";
      return;
    }
    if(this.address.address1!="")
      this.addressComplete = address.address1;
    if(this.address.address2!=""){
      this.addressComplete += ', '+address.address2;
    }
    this.addressComplete += address.city!='' ? ', ' + address.city + ',' : '';
    this.addressComplete2 = address.stateCode!='' ?  address.stateCode : '';
    this.addressComplete2 += address.zip!='' ? ' - '+address.zip: '';
  }
}
