import { Component, ViewEncapsulation } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MyCoverageService } from 'src/app/services/coverages/mycoverage.service';
import { StepperService } from 'src/app/services/stepper/stepper.service';

@Component({
  selector: 'app-enrol',
  templateUrl: './enrol.component.html',
  styleUrls: ['./enrol.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EnrolComponent {
  stepCompleted: boolean[] = [false, false, false];

  buyMoreCoverages: boolean = false;

  constructor(private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry, private myCoverageService: MyCoverageService,
    private stepService: StepperService) {
 

    //loading icons for edit steps 
    this.matIconRegistry.addSvgIcon("Step1", this.domSanitizer
      .bypassSecurityTrustResourceUrl('assets/images/lock1.svg'));
    this.matIconRegistry.addSvgIcon("Step2", this.domSanitizer
      .bypassSecurityTrustResourceUrl('assets/images/user.svg'));
    this.matIconRegistry.addSvgIcon("Step3", this.domSanitizer
      .bypassSecurityTrustResourceUrl('assets/images/dollar.svg'));

    //loading icons for done steps
    this.matIconRegistry.addSvgIcon("Step1-disabled", this.domSanitizer
      .bypassSecurityTrustResourceUrl('assets/images/lock-disabled.svg'));
    this.matIconRegistry.addSvgIcon("Step2-disabled", this.domSanitizer
      .bypassSecurityTrustResourceUrl('assets/images/user-disabled.svg'));
    this.matIconRegistry.addSvgIcon("Step3-disabled", this.domSanitizer
      .bypassSecurityTrustResourceUrl('assets/images/dollar-disabled.svg'));
  }

  ngOnInit() {
    this.myCoverageService.buyMoreCoverages.subscribe((value) => {
      if (value)
        this.buyMoreCoverages = true;
      else
        this.buyMoreCoverages = false;
    });
  }


}
