<div class="container general">
    <div class="header">
        <div class="edit">
            <button id="editBtn" (click)="onEdit()"><mat-icon svgIcon="edit"></mat-icon></button>
        </div>
        <div class="row">
            <p class="">Parent/Guardian Details</p>
        </div>
        <hr />
    </div>
    <div class="detail">
        <form #userProfile="ngForm" id="userProfileForm">
            <div class="row pt-2 mb-2">
                <div class="col-6">
                    <div style="display: block;">
                        <p class="label">First Name</p>
                        <p *ngIf="isEdit" class="name">{{account.firstName}}</p>
                        <div *ngIf="!isEdit">
                            <input type="text" id="firstname" name="firstname" placeholder="Enter first name" [pattern]="unamePattern"  
                    [(ngModel)]="account.firstName" #firstname="ngModel" class="inputBox" required />
                    <div *ngIf="firstname.invalid && (firstname.dirty || firstname.touched)" class="alert-danger addressError">
                        <div *ngIf="firstname.errors?.['required']">
                                Missing item - First Name!
                            </div>
                            <div *ngIf="firstname.errors?.['pattern']">
                                The First name can only contain Hyphens(-),apostrophe('), spaces and characters.
                            </div>
                            </div>
                        </div>
                            
                    </div>
                </div>
                <div class="col-6">
                    <div>
                        <p class="label">Last Name</p>
                        <p *ngIf="isEdit" class="name">{{account.lastName}}</p>
                        <div>
                            <div *ngIf="!isEdit">
                                <input type="text" id="lastname" name="lastname" placeholder="Enter Last Name" class="inputBox"
                                [(ngModel)]="account.lastName" #lastname="ngModel" [pattern]="unamePattern" required/>
                                <div *ngIf="lastname.invalid && (lastname.dirty || lastname.touched)" class="alert-danger addressError"
                                >
                                    <div *ngIf="lastname.errors?.['required']">
                                        Missing item - Last Name!</div>     
                                        <div *ngIf="lastname.errors?.['pattern']">
                                            The Last name can only contain Hyphens(-),apostrophe('), spaces and characters.
                                        </div>                                  
                                </div>
                                
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <p class="emaillabel">Email</p>
                <p class="emailAddress">
                    {{userDetail.emailId}}
                </p>
            </div>
            <div class="row">
                <div>
                    <p class="addlabel">Address</p>
                    <div *ngIf="isEdit" class="address">
                        <p>{{addressComplete}}<br />{{addressComplete2}}</p>
                    </div>
                </div>
                
                <div class="row" *ngIf="!isEdit" class="addressEdit" style="display: flex;">
                    <div class="col-6">
                        <div >
                            <label class="addrInputlabel">Street Address1 </label>
                            <input  type="text" id="address1" [(ngModel)]="address.address1" name="address1" required #address1="ngModel"
                                placeholder="Enter Street Address1" class="inputBox addressInputs"/>
                            <div *ngIf="address1.invalid && (address1.dirty || address1.touched)" class="alert-danger addressError"
                               >
                                <div *ngIf="address1.errors?.['required']">
                                    Missing item - Street Address1!
                                </div>
                            </div>
                        </div>
                        <div>
                            <label class="addrInputlabel">Street Address2 </label>
                            <input type="text" id="address2" [(ngModel)]="address.address2" name="address2" #address2="ngModel"
                                placeholder="Enter Street Address2" class="inputBox addressInputs" />
                        </div>
                     
                        <div>
                            <label class="addrInputlabel">City </label>
                        <input type="text" id="city" [(ngModel)]="address.city" name="city" #city="ngModel"
                            placeholder="Enter City" class="inputBox addressInputs" required />
                        <div *ngIf="city.invalid && (city.dirty || city.touched)" class="alert-danger addressError">
                            <div *ngIf="city.errors?.['required']">
                                Missing item - City!
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="col-6" style="margin-left: 2rem;">
                    <div>
                        <label class="addrInputlabel">State</label>
                        <div>
                            <select class=" inputBox addressInputs"  [(ngModel)]="address.stateCode" name="state" required #state="ngModel">
                                <option value="" selected>Enter State</option>
                                <option *ngFor="let st of states" [value]="st.state">
                                    {{st.name}}
                                </option>
                            </select>
                        </div>
                        
                        <div *ngIf="state.invalid && (state.dirty || state.touched)" class="alert-danger addressError">
                            <div *ngIf="state.errors?.['required']">
                                Please select State
                            </div>
                        </div>
                    </div>
                    <div>
                        <label class="addrInputlabel">Zip</label>
                            <input type="text"   name="zip" [pattern]="zipPattern" required [(ngModel)]="address.zip"
                                #zip="ngModel" placeholder="Enter Zip Code" class="inputBox addressInputs" />
                            <div *ngIf="zip.invalid && (zip.dirty || zip.touched)" class="alert-danger addressError">
                                <div *ngIf="zip.errors?.['required']">Missing item - Zipcode!</div>
                                <div *ngIf="zip.errors?.['pattern'] && zip.value.length>=1 &&zip.value.length<6" >
                                    Zip Code must be atleast 5 digit
                                </div>
                                <div *ngIf="zip.errors?.['pattern']  && zip.value.length>=6">
                                    Zip Code entered should be in correct format (xxxxx-xxxx)
                                </div>
                            </div>
                            
                    </div>
                    </div>

                </div>
               
            </div>
            <div *ngIf="!isEdit" class="save">
                <button class="btn btn-primary addrBtn" [disabled]="!userProfile.valid" id="saveBtn" (click)="save()">Save</button>
                <button class="btn btn-primary addrBtn" id="resetBtn" type="reset">Reset</button>
                <button class="btn btn-danger addrBtn" id="cancelBtn" (click)="resetChanges()">Cancel</button>
            </div>
        </form>
    </div>
</div>
