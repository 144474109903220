<form id="paymentForm">
    <button class="btn btn btn-md btn-primary AcceptUI nextbutton" type="button"
    attr.data-billingAddressOptions='{{billingAddressOptions}}'
     attr.data-apiLoginID="{{apiLoginID}}"
     attr.data-clientKey="{{clientKey}}"
    attr.data-acceptUIFormBtnTxt="{{ButtonText}}"
    attr.data-acceptUIFormHeaderTxt="{{acceptUIFormHeaderTxt}}"
     data-responseHandler="authorizeNetHandler">
    {{ButtonText}}
</button>
</form>