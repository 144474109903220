import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[appEmptyStringValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: EmptyStringValidatorDirective, multi: true }]
})
export class EmptyStringValidatorDirective implements Validator {

  constructor() { }

  validate(control: AbstractControl): ValidationErrors | null {
    if(control.value && control.value.toString().trim().length == 0) return {emptyString: true}
    return null;
  }

}
