import { Component} from '@angular/core';
import { AuthGuard } from 'src/app/core/guards/auth.guard';

import { AuthenticationService } from 'src/app/services/authentication.service';
import {MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {OnInit, Inject } from '@angular/core';
import { Password } from 'src/app/models/password.model';
import ChangepasswordService from 'src/app/services/changepassword/changepassword.service';
import { AbstractControl, FormsModule } from '@angular/forms';
import{ ValidationErrors} from '@angular/forms';
import{ FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { AESEncryptDecryptService } from 'src/app/services/encryptDecrypt/aesencrypt-decrypt.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  currentPassword : string='';
  newPassword: string='';
  confirmPassword: string='';
  email:string='';
  password:Password[]=[];
  isMessage: boolean = false;
  message: string = "";
  isTempPassword: boolean =false;
  isFailed: boolean = false;
  isChanged: boolean = false;

  passwordType: string = "";

  model: Password = new Password(this.email, this.currentPassword ,this.newPassword, this.confirmPassword );

  constructor(
    private authService: AuthenticationService,
    private changeService: ChangepasswordService,
    private router: Router,
    private AESEncryptDecryptService: AESEncryptDecryptService
  ) {
    this.email = authService.getEmail();
    this.isTempPassword = authService.isTempPassword;
    this.isFailed=false;
  }

  ngOnInit(): void {
    if (this.isTempPassword)
      this.passwordType = "Temporary";
    else
      this.passwordType = "Current";
  }

  passwordPtn ="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$";

  onSubmit(pass:Password){
    this.changePassword(pass);
  }

  changePassword(pass: Password): void{
    this.model.email = this.email;
    this.currentPassword=pass.currentPassword;
    this.newPassword=pass.newPassword;
    this.confirmPassword=pass.confirmPassword;

    //To-do: Encryption disabled for soft production, to be enabled later
    // this.model.currentPassword = this.AESEncryptDecryptService.encrypt(this.model.currentPassword);
    // this.model.newPassword = this.AESEncryptDecryptService.encrypt(this.model.newPassword);
    // this.model.confirmPassword = this.AESEncryptDecryptService.encrypt(this.model.confirmPassword);
    this.changeService.changePassword(this.model).subscribe({
      next: (result: any) => {
        if(!result.body){
          this.isFailed=true;
          this.isChanged =false;
        }
        else{
          this.isMessage=true;
          this.authService.isTempPassword = false;
          this.isTempPassword = false;

          this.isChanged = true;
          this.router.navigate(['coverages'])
        }

       },
       error: (err: any) => {
         console.log("error happended",err);
       }
    });

  }
}

