<div class="container coverage">
  <div class="row mb-3 mt-3">
    <div class="col-lg-10">
      <label class="headerLabel"> My Coverages </label>
    </div>
      <div class="col-lg-2 float-right"><button class="dropdown" id="addCovegBtn" mat-button [matMenuTriggerFor]="menu" aria-label="icon-button with a menu">
        Add Coverage <img class="imgChev" src="assets/images/chevron.png" alt="Unable to load image"/>
      </button>
    </div>
    </div>

<div class="row">
  <ul class="nav nav-tabs displayTab" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button
        class="nav-link active"
        id="coverage-tab"
        data-bs-toggle="tab"
        data-bs-target="#coverages"
        type="button"
        role="tab"
        aria-selected="true">{{ currentYear }}-{{ (currentYear + 1).toString().slice(-2) }} Coverages</button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="nav-link"
        id="expiredCov-tab"
        data-bs-toggle="tab"
        data-bs-target="#expiredCoverages"
        type="button"
        role="tab"
        aria-selected="false"
        (click)="LoadExpiredCoverage()">{{ currentYear - 1 }}-{{ currentYear.toString().slice(-2) }} Coverages</button>
    </li>
  </ul>
  <div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="coverages" role="tabpanel" aria-labelledby="coverage-tab">
      <div class="container mt-2">
        <div class="row">
          <div class="col-lg-8"></div>
          <div class="col-lg-4 btnDrpdwn">

            <mat-menu #menu="matMenu">
              <button mat-menu-item id="adNewStudentBtn" (click)="addStudent('New Student')">
                <span>New Student</span>
              </button>
              <button mat-menu-item id="addSavedStudentBtn" (click)="addStudent('Saved Student')">
                <span>Saved Student</span>
              </button>
            </mat-menu>

          </div>
        </div>
        <!-- <br> -->
        <div class="row">
          <div class="accordion ms-auto " id="accordionCoverage" *ngIf="noOfCoverageStudents>0">
            <div class="accordion-item" *ngFor="let student of myCoverages.students;let i = index">
              <div class="accordion-header" [id]="'heading'+i">
                <div class="d-flex justify-content-between px-2 py-3">


                  <div class="d-flex flex-wrap align-items-center ms-2">
                    <span class="fw-bold me-3" style="font-size: 14px;">{{student.firstName}}  {{student.lastName}}</span>
                    <span class="policynotext" style="font-size: 13px;">Policy No - {{student.coverages[0]? student.coverages[0].voluntaryPolicyNo : ""}}</span>
                  </div>



                  <div class="d-inline-flex">
                    <!-- <button
                      *ngIf="!student.isCollapse"
                      class="buycovbtn me-1"
                      [id]="'buyMoreCovgBtn'+i"
                      (click)="buyNewCoverages(student)">Buy More Coverage
                    </button> -->

                    <button class="downloadbtn me-1" data-toggle="tooltip" data-placement="left"
                      title="Download ID Card &#013;Click here to download"  [id]="'downloadID'+i"
                      (click)="downloadIdCard(student,underwriters.insCo)"><img
                        src="assets/images/download.svg" alt="Unable to load image"></button>

                    <button class="accordion-button btnCoverage collapsed px-1"  type="button" [id]="'collapseBtn'+i"
                      data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse'+i" [attr.aria-controls]="'collapse'+i"
                      aria-controls="collapseTwo" (click)="collapse(student)" >
                    </button>
                  </div>

                </div>
              </div>
              <div [id]="'collapse'+i" class="accordion-collapse collapse" data-bs-parent="#accordionCoverage"
                 [attr.aria-labelledby]="'heading'+i">
                <div class="accordion-body" id="accBody">
                  <div class="row">
                    <div class="d-flex flex-wrap mb-4">
                      <div style="margin-right: 5rem;">
                        <p class="fw-semibold mb-1 text-nowrap" style="font-size: .95rem;">School District</p>
                        <p class="text-dark" style="font-size: .9rem">{{student.district}}</p>
                      </div>
                      <div style="margin-right: 5rem;">
                        <p class="fw-semibold mb-1 text-nowrap" style="font-size: .95rem;">State</p>
                        <p class="text-dark" style="font-size: .9rem">{{student.state}}</p>
                      </div>
                      <div style="margin-right: 5rem;">
                        <p class="fw-semibold mb-1 text-nowrap" style="font-size: .95rem;">School Year</p>
                        <p class="text-dark" style="font-size: .9rem">{{ currentYear}} - {{currentYear + 1}}</p>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6" id="logoColumn" *ngIf="underwriters.insCo!=''">
                      <img *ngIf="underwriters.logoLoc" class="logoImage"
                        src='{{ "./assets/images/"+underwriters.logoLoc+""}}' alt={{underwriters.logoLoc}}>
                      <div style="display: block;">
                        <p class="gnote">Coverage by</p>
                        <div class="note">
                          {{underwriters.insCo}}
                        </div>
                      </div>

                    </div>
                    <div class="col-lg-6">
                      <div class="note">
                        {{underwriters.address1}}, {{underwriters.address2}} <br>
                        {{underwriters.state}}, {{underwriters.city}}, {{underwriters.zip}}
                      </div>
                    </div>
                  </div>
                  <br>

                  <table mat-table #table [dataSource]="student.coverages" matSort>

                    <ng-container matColumnDef="Activity">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>Activity</th>
                      <td mat-cell *matCellDef="let element" [class.refund-color]="element.demographics.indexOf('*') == 0">{{element.activity}}
                        <div *ngIf="element.subCatNameString && element.subCatNameString !=''"> [{{element.subCatNameString}}]</div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Demographics">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>Demographics</th>
                      <td mat-cell *matCellDef="let element" [class.refund-color]="element.demographics.indexOf('*') == 0">{{element.demographics}}</td>
                    </ng-container>

                    <ng-container matColumnDef="MarketingPlan">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>Marketing Plan</th>
                      <td mat-cell *matCellDef="let element" [class.refund-color]="element.demographics.indexOf('*') == 0">{{element.marketingPlan}}</td>
                    </ng-container>

                    <ng-container matColumnDef="Rate">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>Coverage Rate</th>
                      <td mat-cell *matCellDef="let element" [class.refund-color]="element.demographics.indexOf('*') == 0">
                        {{ ((element.demographics.indexOf('*') == 0 && element.paid > 0) ? (element.paid * -1) : element.paid) | currency:'USD':'symbol'}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="EffectiveDate">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>Coverage<br>Effective Date</th>
                      <td mat-cell *matCellDef="let element" [class.refund-color]="element.demographics.indexOf('*') == 0">{{element.effectiveDate | date:
                        'M/d/yyyy'}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="TerminationDate">
                      <th mat-header-cell mat-sort-header *matHeaderCellDef>Coverage<br>Expiration Date</th>
                      <td mat-cell *matCellDef="let element" [class.refund-color]="element.demographics.indexOf('*') == 0">{{element.terminationDate | date:
                        'M/d/yyyy'}}
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                  </table>
                  <br>
                  <div class="d-flex justify-content-between">
                    <button
                      *ngIf="!student.isCollapse"
                      class="buycovbtn me-1"
                      [id]="'buyMoreCovgBtn'+i"
                      (click)="buyNewCoverages(student)">Buy More Coverage
                    </button>
                    <div class="d-flex justify-content-end align-items-center">
                      <span class="totalText me-3">Total</span>
                      <span class="totalamt">{{student.totalAmount | currency:'USD':'symbol'}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="noOfCoverageStudents==0" class="mt-3 h5 text-center text-secondary">No Coverage Found</div>
        </div>
      </div>
    </div>
    <div class="tab-pane fade" id="expiredCoverages" role="tabpanel" aria-labelledby="expiredCov-tab">
      <div class="container mt-2">
            <div class="row">
              <div class="accordion ms-auto " id="accordionExpiry" *ngIf="noOfExpCoverageStudents>0">
                <div class="accordion-item" *ngFor="let student of myExpiredCoverages.students;let i = index">
                  <div class="accordion-header" [id]="'expiryheading'+i">
                    <div class="d-flex justify-content-between px-2 py-3">

                      <div class="d-flex flex-wrap align-items-center ms-2">
                        <span class="fw-bold me-2" style="font-size: 14px;">{{student.firstName}}  {{student.lastName}}</span>
                        <span class="expiredpolicynotext" style="font-size: 13px;">Policy No - {{ student.coverages[0].voluntaryPolicyNo}}</span>
                      </div>

                      <div class="d-inline-flex">
                        <button class="downloadbtn me-1" data-toggle="tooltip" data-placement="left" [id]="'downloadIdExpired'+i"
                          title="Download ID Card &#013;Click here to download" (click)="downloadIdCard(student,underwriters.insCo)"><img
                            src="assets/images/download.svg" alt="Unable to load image"></button>

                        <button class="accordion-button btnExpiryCoverage collapsed px-1"  type="button" [id]="'collapseExpiredBtn'+i"
                          data-bs-toggle="collapse" [attr.data-bs-target]="'#expcollapse'+i" [attr.aria-controls]="'collapse'+i"
                          aria-controls="collapseOne" (click)=" expiryCollapse(student)">
                        </button>
                      </div>

                    </div>





                  </div>
                  <div [id]="'expcollapse'+i" class="accordion-collapse collapse" data-bs-parent="#accordionExpiry"
                     [attr.aria-labelledby]="'expiryheading'+i">
                    <div class="accordion-body" id="accBody">
                      <div class="row">
                        <div class="d-flex flex-wrap mb-4">
                          <div style="margin-right: 5rem;">
                            <p class="fw-semibold mb-1 text-nowrap" style="font-size: .95rem;">School District</p>
                            <p class="text-dark" style="font-size: .9rem">{{student.district}}</p>
                          </div>
                          <div style="margin-right: 5rem;">
                            <p class="fw-semibold mb-1 text-nowrap" style="font-size: .95rem;">State</p>
                            <p class="text-dark" style="font-size: .9rem">{{student.state}}</p>
                          </div>
                          <div style="margin-right: 5rem;">
                            <p class="fw-semibold mb-1 text-nowrap" style="font-size: .95rem;">School Year</p>
                            <p class="text-dark" style="font-size: .9rem">{{currentYear - 1}} - {{currentYear}}</p>
                          </div>
                        </div>
                      </div>

                      <div class="row ">
                        <div class="col-lg-6" id="logoColumn" *ngIf="underwriters.insCo!=''">
                          <img *ngIf="underwriters.logoLoc" class="logoImage"
                            src='{{ "./assets/images/"+underwriters.logoLoc+""}}' alt={{underwriters.logoLoc}}>
                          <div style="display: block;">
                            <p class="gnote">Coverage by</p>
                            <div class="note">
                              {{underwriters.insCo}}
                            </div>
                          </div>

                        </div>
                        <div class="col-lg-6">
                          <div class="note">
                            {{underwriters.address1}}, {{underwriters.address2}} <br>
                            {{underwriters.state}}, {{underwriters.city}}, {{underwriters.zip}}
                          </div>
                        </div>
                      </div>
                      <br>

                          <table mat-table #table [dataSource]="student.coverages" matSort>
                            <ng-container matColumnDef="Activity">
                              <th  mat-header-cell mat-sort-header *matHeaderCellDef>Activity</th>
                              <td  mat-cell *matCellDef="let element" [class.refund-color]="element.demographics.indexOf('*') == 0">{{element.activity}}
                                <div *ngIf="element.subCatNameString && element.subCatNameString !=''"> [{{element.subCatNameString}}]</div>
                              </td>
                            </ng-container>

                            <ng-container matColumnDef="Demographics">
                              <th  mat-header-cell mat-sort-header *matHeaderCellDef>Demographics</th>
                              <td  mat-cell *matCellDef="let element" [class.refund-color]="element.demographics.indexOf('*') == 0">{{element.demographics}}</td>
                            </ng-container>

                            <ng-container matColumnDef="MarketingPlan">
                              <th  mat-header-cell mat-sort-header *matHeaderCellDef>Marketing Plan</th>
                              <td  mat-cell *matCellDef="let element" [class.refund-color]="element.demographics.indexOf('*') == 0">{{element.marketingPlan}}</td>
                            </ng-container>

                            <ng-container matColumnDef="Rate">
                              <th  mat-header-cell mat-sort-header *matHeaderCellDef>Coverage Rate</th>
                              <td  mat-cell *matCellDef="let element" [class.refund-color]="element.demographics.indexOf('*') == 0">
                                {{ ((element.demographics.indexOf('*') == 0 && element.paid > 0) ? (element.paid * -1) : element.paid) | currency:'USD':'symbol'}}
                              </td>
                            </ng-container>

                            <ng-container matColumnDef="EffectiveDate">
                              <th  mat-header-cell mat-sort-header *matHeaderCellDef>Coverage<br>Effective Date</th>
                              <td  mat-cell *matCellDef="let element" [class.refund-color]="element.demographics.indexOf('*') == 0">{{element.effectiveDate | date:
                                'M/d/yyyy'}}
                              </td>
                            </ng-container>

                            <ng-container matColumnDef="TerminationDate">
                              <th  mat-header-cell mat-sort-header *matHeaderCellDef>Coverage<br>Expiration Date</th>
                              <td  mat-cell *matCellDef="let element" [class.refund-color]="element.demographics.indexOf('*') == 0">{{element.terminationDate | date:
                                'M/d/yyyy'}}
                              </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                          </table>
                      <br>
                        <div class="d-flex justify-content-end">
                          <span class="totalText me-3">Total:</span>
                          <span class="totalamt">{{student.totalAmount | currency:'USD':'symbol'}}</span>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="noOfExpCoverageStudents==0" class="mt-3 h5 text-center text-secondary">No Coverage Found</div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
  <ng-container *ngIf="showIdCard">
  <app-id-card></app-id-card>
  </ng-container>
