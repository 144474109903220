import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ForgotService {

  apiUrl = 'ForgotPassword/';
  
  

  constructor(private http: HttpClient) { }

  getForgot(email :string): Observable<string> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(email.toString());
    return this.http.post<string>(environment.baseUrl + this.apiUrl+'ForgotPassword' ,body, {headers:headers});
  
  }
}
