export const environment = {
  //baseUrl: 'https://www.k12studentinsurance.com/EnrolmentAPI/api/',
  baseUrl: 'https://enrollment.k12studentinsurance.com/EnrolmentAPI/api/',
//  baseUrl: 'https://ctprod01.hsri.com/EnrolmentAPI/api/',
  authorizeNetConfig: {
    authorizeNetAPILoginID: '3K8Kc66Gv64',
    authorizeNetAPIClientKey:
      '97yP8sVScy47q3y9Cxz4L24UZ9Z3t7387mQqwqFATYzkc5H2GXKX6ts7SavPQZJc',
    authorizeNetAcceptUIurl: 'https://js.authorize.net/v3/AcceptUI.js',
    acceptUIFormBtnTxt: 'Pay',
    acceptUIFormHeaderTxt: 'Welcome to your payment center',
    billingAddressOptions: '{"show":true, "required":true}',
  },
  environment:'production',
  SymmetricKey: "AAECAwQFBgcICQoLDA0ODw==",
  IV:"HR$2pIjHR$2pIj12"
};
