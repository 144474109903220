import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { User } from 'src/app/models/User.model';
import { Login } from 'src/app/models/login.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ForgotPasswordComponent } from '../../home/forgot-password/forgot-password.component';
import { EnrolService } from 'src/app/services/enrol/enrol.service';
import { Student } from 'src/app/models/student.model';
import { StudentDetailsService } from 'src/app/services/studentdetails/student-details.service';
import { Enroll } from 'src/app/models/Enroll.model';
import { UserdetailsService } from 'src/app/services/profile/userdetails.service';
import { Router } from '@angular/router';
import { AESEncryptDecryptService } from 'src/app/services/encryptDecrypt/aesencrypt-decrypt.service';

@Component({
  selector: 'app-welcome-back',
  templateUrl: './welcome-back.component.html',
  styleUrls: ['./welcome-back.component.scss']
})
export class WelcomeBackComponent {
  login = new Login("", "", "", "", "", "", "", "", "", "");
  showEmailError: boolean = false;
  errorMessage: string = "";
  isLoggedIn: boolean = false;
  fullname: string = "";
  constructor(
    private dialogRef: MatDialogRef<WelcomeBackComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Enroll,
    private authService: AuthenticationService,
    private enrolService: EnrolService,
    private studentDetailsService: StudentDetailsService,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private matDialog: MatDialog,
    private userDetailsService: UserdetailsService,
    private router:Router,
    private AESEncryptDecryptService: AESEncryptDecryptService
  ) {

    this.matIconRegistry.addSvgIcon("close", this.domSanitizer
      .bypassSecurityTrustResourceUrl('assets/images/close-icon.svg'));
    this.login.email = data.email;
  }
  authenticate(login: Login) {
    if(login.password?.trim().length==0){
      this.showEmailError = true;
          this.errorMessage = "Missing item - Password!";
          return;
    }
    //To-do: Encryption disabled for soft production, to be enabled later
    //login.password = this.AESEncryptDecryptService.encrypt(this.login.password);
    this.authService.authenticate(login).subscribe({
      next: (res: any) => {
        if (res?.body) {
          sessionStorage.setItem("jwt", res.body.jWTToken.token);

          this.showEmailError = false;
          this.errorMessage = "";
          this.login.accountID = res.body.accountID;
          this.login.firstName = res.body.firstName;
          this.login.lastName = res.body.lastName;
          this.login.address1 = res.body.address1;
          this.login.address2 = res.body.address2;
          this.login.city = res.body.city;
          this.login.stateCode = res.body.stateCode;
          this.login.zip = res.body.zip;
          this.login.email = res.body.email;
          this.authService.isAuthUser.next(res.status);
          this.authService.isAuthUser.subscribe((val => {
            this.isLoggedIn = val;
            this.fullname = this.login.firstName.trim() + " " + this.login.lastName.trim();
          }));
          let user = new User();
          user.accountID = res.body.accountID;
          user.firstName = res.body.firstName;
          user.lastName = res.body.lastName;
          user.email = res.body.email;
          user.address = res.body.address1;
          user.city = res.body.city;
          user.state = res.body.stateCode;
          user.zip = res.body.zip;
          this.authService.isAuthUser.next(true);
          this.authService.loggedInUser.next(new Login(res.body.email, "", res.body.firstName, res.body.lastName, res.body.accountID, res.body.address1, res.body.address2, res.body.stateCode, res.body.city, res.body.zip));
          this.authService.loggedInUserId.next(res.body.accountID);
          sessionStorage.setItem('loggedUser',JSON.stringify(new Login(res.body.email, "", res.body.firstName, res.body.lastName, res.body.accountID, res.body.address1, res.body.address2, res.body.stateCode, res.body.city, res.body.zip)))
          this.authService.setUserDetailModel(user);
          if (res.body.istempPassword) {
            this.authService.isTempPassword = true;
            this.router.navigate(['myprofile/change-password']);
          }
          this.dialogRef.close("loggedIn");

        } else {
          this.showEmailError = true;
          this.errorMessage = "Email Address / Password entered is incorrect";
        }
      },
      error: (error: HttpErrorResponse) => {
        console.log(error.message)
      }
    })
  }
  closeDialog() {
    this.dialogRef.close();
  }
  OpenForgotPasswordDialog() {
    this.matDialog.open(ForgotPasswordComponent, { panelClass: 'forgot-password-modal', autoFocus: true, data: this.login.email });
  }
}
