import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { State } from 'src/app/models/enrol/state.model';
import { StudentCoverage } from 'src/app/models/enrol/studentcoverage.model';
import { EnrolService } from 'src/app/services/enrol/enrol.service';
import { UserRegistrationComponent } from '../../user-registration/user-registration.component';
import { MatDialog } from '@angular/material/dialog';
import { Enroll } from 'src/app/models/Enroll.model';
import { MatStepper } from '@angular/material/stepper';
import { LocationService } from 'src/app/services/location/location.service';
import { WelcomeBackComponent } from '../../welcome-back/welcome-back.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Login } from 'src/app/models/login.model';
import { Router } from '@angular/router';
import { StudentDetailsService } from 'src/app/services/studentdetails/student-details.service';
import { Student } from 'src/app/models/student.model';
import { StepperService } from 'src/app/services/stepper/stepper.service';
import { AbstractControl, FormArray, FormBuilder, FormGroup, NgForm, ValidationErrors, Validators } from '@angular/forms';
import { Subscription, catchError, debounceTime, distinctUntilChanged, filter, of, switchMap, tap } from 'rxjs';
import { EmptyStringValidatorDirective } from 'src/app/directive/directives/empty-string-validator.directive';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-add-studentdetails',
  templateUrl: './add-studentdetails.component.html',
  styleUrls: ['./add-studentdetails.component.scss'],
})
export class AddStudentdetailsComponent implements OnInit, OnDestroy {
  ufirstnamePattern = "^([a-zA-Z]{1,}?[a-z0-9 \\'\\-]{0,})*$";
  unamePattern = "^([a-zA-Z]{1,}?[a-z \\'\\-]{0,})*$";
  zipPattern = '^[0-9]{5}(?:-[0-9]{4})?$';
  phonepattern =
    '^(\\+\\d{1,2}\\s?)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$|(?:NaN)';

  public studentcoverage: StudentCoverage[] = [];

  selectedState: string = '';
  states: State[] = [];
  enroll: Enroll = new Enroll('', '', '', '', '', '', '', [], 0);
  maxDate: any;
  parentDetails = new Login('', '', '', '', '', '', '', '', '', '');
  userLoggedIn: boolean = false;
  accountId: string = '';
  email: string = '';
  isDataValid: boolean = true;
  emailhasCom: boolean = false;
  @ViewChild('f', { static: false }) f!: NgForm;
  // @ViewChild('student', { static: false }) student!: NgForm;
  loggedInUserSub!: Subscription;
  // students:FormArray = this.fb.array([]);
  form:FormGroup = this.fb.group({
    students: this.fb.array([])
  }
  )
  subArray: Subscription[] = [];
  // disableStudent: boolean[] = [false];
  constructor(
    private enrollService: EnrolService,
    private dialog: MatDialog,
    private stepper: MatStepper,
    private locationService: LocationService,
    private authService: AuthenticationService,
    private router: Router,
    private studentService: StudentDetailsService,
    private stepService: StepperService,
    private fb:FormBuilder,
    private studentDetailsService: StudentDetailsService
  ) {}

  get studentsForm(){
    return this.form.controls['students'] as FormArray;
  }
  studentForm(index:number):FormGroup{
    return this.studentsForm.controls.at(index) as FormGroup
  }
  ngOnInit(): void {
    this.enroll.studentDetail = [];
    let studentData: StudentCoverage[] = [];
    this.enrollService.studentCoverage.subscribe((value) => {
      this.enroll.studentDetail = value;
      studentData = this.enroll.studentDetail;
      this.form.controls['students'] = this.fb.array([]);
      for (let student of this.enroll.studentDetail) {
          if (student.phone == 0) student.phone = NaN;
          this.addStudentToStudentsForm(student);
      }
      this.disbaleStudentsForm();
    });

    this.enrollService.enrollData.subscribe((value) => {
      const studentDetails: StudentCoverage[] = [];
      this.form.controls['students'] = this.fb.array([]);
      if (value?.studentDetail && value.studentDetail.length > 0) {
        value.studentDetail.forEach((studentCoverage: StudentCoverage) => {
        studentDetails.push({ ...studentCoverage });
        });
      }

      this.enroll = new Enroll(
        value.firstname,
        value.lastname,
        value.email,
        value.streetaddress,
        value.city,
        value.state,
        value.zip,
        studentDetails,
        value.payableAmount
      );
      if (this.enroll.studentDetail == undefined) {
        this.enroll.studentDetail = studentData;
      }
      if (this.enroll.studentDetail != undefined) {
        for (let student of this.enroll.studentDetail) {
          if (student.phone == 0) student.phone = NaN;
          this.addStudentToStudentsForm(student);
        }
        this.disbaleStudentsForm();
      }
    });

    this.loggedInUserSub = this.authService.loggedInUser.subscribe({
      next: (res: any) => {
        if (res?.email && res.accountId != '') {
          this.userLoggedIn = true;
          this.accountId = res.accountId;
          this.enroll.firstname = res.firstName;
          this.enroll.lastname = res.lastName;
          this.enroll.streetaddress = res.address1;
          this.enroll.email = res.email;
          this.enroll.state = res.stateCode;
          this.enroll.city = res.city;
          this.enroll.zip = res.zip;

        } else this.userLoggedIn = false;
      },
      error: (error: any) => {
        console.log(error);
      },
    });

    // this.maxDate = new Date().toISOString().split('T')[0];
    this.maxDate = new Date();
    this.maxDate.setFullYear(this.maxDate.getFullYear() - 2);

    for (let i = 0; i < this.studentsForm.value.length; i++) {
      let currentDOB = new Date(this.studentForm(i).controls['dob'].value);
      if (currentDOB > this.maxDate) {
        this.studentForm(i).controls['dob'].markAsTouched();
        this.studentForm(i).controls['dob'].setValue('');
        this.studentForm(i).controls['dob'].updateValueAndValidity();
        this.studentForm(i).controls['dob'].markAsUntouched();
      }
    }

    this.getAllStates();
  }

  ngOnDestroy(): void {
    this.loggedInUserSub.unsubscribe();
  }

  onChangeDOB(index: number): void {
    let currentDOB = new Date(this.studentForm(index).controls['dob'].value);
    if (currentDOB > this.maxDate) {
      this.studentForm(index).controls['dob'].setValue('');
    }
  }

  getCoverageExpiresColor(dateStr: string): string {
    try {
      let today = new Date();
      let termDate = new Date(dateStr);
      let timeDiff = termDate.getTime() - today.getTime();
      let dayDiff =  Math.round(timeDiff / (1000 * 3600 * 24));

      // month is June
      if (today.getMonth() == 5) {
        return '3';
      }
      // more than 90 days until expiration
      if (dayDiff > 90) {
        return '1';
      }
      // more than 60 days until expiration
      if (dayDiff > 60) {
        return '2';
      }
      // less than 60 days until expiration
      return '3';
    } catch (e) {
      // invalid expiration date
      return '3';
    }
  }

  getAllStates() {
    this.locationService.getAllStates().subscribe({
      next: (result: any) => {
        result.body.forEach((item: State) => {
          let data = {} as State;
          data = {
            name: item.name,
            state: item.state?? item.postalAbbreviation,
            postalAbbreviation: '',
            countryCode: '',
            capitalCity: '',
            standardAbbreviation: '',
          };

          this.states.push(data);
        });
        this.states.sort((a,b)=>{return a.name < b.name? -1: 1});
      },
    });
  }

  openUserRegistration() {
    this.enrollService.setEnrolledStudentData(this.enroll);

    const dialogRef = this.dialog.open(UserRegistrationComponent, {
      panelClass: 'user-registration-modal',
      autoFocus: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'registerUser') {
        this.authService.loggedInUser.subscribe((res) => {
          if (res?.email) {
            this.userLoggedIn = true;
            this.accountId = res.accountId;
            this.GotoPaymentDetailsTab();
           // this.stepper.next();
          } else this.userLoggedIn = false;
        });
      }
    });
  }
  openWelcomeBackPopUp(goToPaymentDetails: boolean = false) {
    const dialogRef = this.dialog.open(WelcomeBackComponent, {
      panelClass: 'welcome-back-modal',
      autoFocus: true,
      data: this.enroll,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'loggedIn' && goToPaymentDetails) {
        this.GotoPaymentDetailsTab();
      //  this.stepper.next();
      }
    });
  }

  checkEmail() {
    this.f.form.markAllAsTouched();
    this.studentsForm.markAllAsTouched();
    this.enroll.studentDetail.forEach((ele, index) => {
      ele.dob = this.studentForm(index).controls['dob'].value;
      if (typeof ele.dob == 'string') {
        ele.dob = new Date(ele.dob);
      }
    });
    if (this.f.invalid || this.studentsForm.invalid) {
      return;
    }
    this.stepService.updateStepStatus(1, true);
    if(this.enroll.email != ''){
    if (!this.userLoggedIn) {
      this.enrollService.checkEmail(this.enroll.email).subscribe({
        next: (result: any) => {
          if (result.body) {
            this.openWelcomeBackPopUp(true);
          } else {
            this.openUserRegistration();
          }
        },
      });
    } else {
      this.GotoPaymentDetailsTab();
     // this.stepper.next();
    }
  }
  }

  onBlurCheckEmail() {
    if (!this.userLoggedIn && this.f.form.controls['email'].valid) {
      console.log("Check for existing email address.");
      this.enrollService.checkEmail(this.enroll.email).subscribe({
        next: (result: any) => {
          if (result.body) {
            this.openWelcomeBackPopUp();
          }
        },
      });
    }
    return false;
  }



  async GotoPaymentDetailsTab() {
    this.saveStudents();
    this.enrollService.setEnrolledStudentData(this.enroll);
  }

  saveStudents() {
    let students: Student[] = []
    if(this.accountId && Number(this.accountId) != 0){
    this.enroll.studentDetail.forEach((std: any) => {

      let student = new Student(std.firstName, std.lastName, std.campusId,
        std.campusName, std.campusTypeId, std.grade, std.stateCode && std.stateCode != '' ? std.stateCode: this.states.filter(s=>s.name.toUpperCase() == std.state.toUpperCase())[0].state, std.state, std.school,
        std.studentId > 0 ? std.studentId : 0, std.dob, std.phone?.toString(), std.IsStudentCovered, std.ssn, Number(this.accountId), std.schoolDistrictName??'');
      students.push(student);
    });
    this.studentService.saveStudents(students).subscribe(
      ()=>{
        this.stepper.next();
      }
    );
  }
  }

  checkForCom() {
    if (this.email && !this.email.endsWith('.com')) {
      this.emailhasCom = true;
    }
    this.emailhasCom = false;
  }
  goPreviousTab(){
    this.enrollService.setEnrolledStudentData(this.enroll);
  }
  validateStudentId(studenIdtControl:AbstractControl, firstName:string, lastName:string): ValidationErrors | null{
    return studenIdtControl.valueChanges.pipe(
      debounceTime(10),
      switchMap(value => {
        return this.studentService.checkIfStudentExists(studenIdtControl.value);
      }),
      tap((res:any) => {
        if (res.body == null) {
          return null;
        }
        else {
          const stud = res.body as Student;
          if (stud.firstName == firstName && stud.lastName == lastName) return null;
          return studenIdtControl.setErrors({ uniqueStudentId: true });
        }
      }
    ),
    catchError(error=> {return of(null)}
    ))
  }
  addStudentToStudentsForm(studentCoverage:StudentCoverage){
    this.studentsForm.push(
      this.fb.group(
        {
          firstName:[studentCoverage.firstName],
          lastName:[studentCoverage.lastName],
          studentId:[studentCoverage.studentId],
          dob:[formatDate(studentCoverage.dob, 'yyyy-MM-dd','en') ],
          grade:[studentCoverage.grade],
          phone:[studentCoverage.phone],
          ssn:[studentCoverage.ssn]
        }
      )
    );

  }

  disbaleStudentsForm(){
    let studentIds: number[] = [];
    this.enroll.studentDetail.forEach((student:StudentCoverage) => {
      if(student.studentId && student.studentId.trim().length != 0) studentIds.push(Number(student.studentId))
    })
  if(studentIds.length>0)
  this.studentDetailsService.checkStudentsCoverageStatus(studentIds).subscribe({
      next: (res) => {
            if(res && res.body && res.body.length>0){
              res.body.forEach((cover:any) =>{
                const index = this.studentsForm.controls.findIndex((x,i)=> x.get('studentId')?.value == cover.studentId)
                if(cover.status.toLowerCase() == 'covered' && index !=-1){
                  this.studentForm(index).disable();
                }
                else{
                  this.studentForm(index).enable();

                }
              })
            }
          }
        }
    )
    // if(this.subArray.length>0){
    //   this.subArray.forEach(sub => sub.unsubscribe());
    // };
    // this.subArray = [];
    // this.studentsForm.controls.forEach((student, index) => {
    //   this.subArray[index] = this.studentForm(index).controls['studentId'].valueChanges.pipe(
    //     debounceTime(10),
    //     filter(x => x && x.trim().length > 0),
    //     distinctUntilChanged(),
    //     switchMap((studentid:number) => this.studentDetailsService.checkStudentsCoverageStatus([studentid]))
    //   ).subscribe(res => {
    //     if(res && res.body && res.body.length>0){
    //       res.body.forEach((cover:any) =>{
    //         if(cover.status.toLowerCase() == 'covered'){
    //           this.studentForm(index).disable();
    //         }
    //         else{
    //           this.studentForm(index).enable();

    //         }
    //       })
    //     }
    //     console.log("covered status", res);
    //   })
    // })
    // // this.studentForm(this.studentsForm.length - 1)
  }
}
