<div class="container" style="margin-top: 1rem">
  <div class="row" style="display: flex">
    <div class="col-12 billingColumn">
      <div class="row">
        <div class="paymentheader">Billing Information</div>
        <div class="billerInfo nameheader">
          <div>{{ enrollData.firstname + " " + enrollData.lastname }}</div>
          <div>{{ enrollData.streetaddress }}, {{ enrollData.city }}</div>
          <div>{{ enrollData.state }},{{ enrollData.zip }}.</div>
        </div>
      </div>
      <div class="row">
        <div class="paymentheader">Selected Plans</div>
        <div class="infocard" *ngFor="let card of enrollData.studentDetail">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-lg-8">
                  <div class="nameheader">
                    {{ card.firstName + " " + card.lastName }}
                  </div>
                  <div class="normal-body-font-style">
                    {{ card.school }}, {{ card.state }}
                  </div>
                </div>
                <div class="col-lg-4 coverageYear">
                  {{ getCoverageYear(card.coverageYear) }}
                </div>
              </div>
            </div>
            <div class="body">
              <ul class="list-group">
                <div *ngFor="let coverage of card.coverageList">
                  <li class="list-group-item" *ngIf="coverage.isChecked">
                    <div class="row">
                      <div class="col-lg-8 normal-body-font-style">
                        {{ coverage.activity }} {{coverage.displaySubCat}}, {{ coverage.demographics }},
                        {{ coverage.marketingPlan }}
                      </div>
                      <div class="col-lg-4 currencyText normal-body-font-style">
                        {{ coverage.rate | currency : "USD" : "symbol" }}
                      </div>
                    </div>
                  </li>
                </div>
                <div *ngFor="let coverage of card.addOnCoverageList">
                  <li class="list-group-item" *ngIf="coverage.isChecked">
                    <div class="row">
                      <div class="col-lg-8 normal-body-font-style">
                        {{ coverage.activity }}, {{ coverage.demographics }},
                        {{ coverage.marketingPlan }}
                      </div>
                      <div class="col-lg-4 currencyText normal-body-font-style">
                        {{ coverage.rate | currency : "USD" : "symbol" }}
                      </div>
                    </div>
                  </li>
                </div>
                <li class="list-group-item">
                  <div class="row">
                    <div class="col-lg-8 totalText">Total</div>
                    <div class="col-lg-4 totalAmountText">
                      {{ card.total | currency : "USD" : "symbol" }}
                    </div>
                  </div>
                </li>
             
              </ul>
            </div>


          </div>
        </div>

        <div class="row">
          <div class="col-lg-8 totalText">Electronic Processing Fee (non-refundable)</div>
          <div class="col-lg-4 totalAmountText">
            {{ enrollData.processingFee | currency : "USD" : "symbol" }}
          </div>
        </div>       
      </div>
      <div class="row pRow">
        <div class="col-sm-4 col-md-4 col-lg-8 payableText">Payable Amount</div>
        <div class="col-sm-4 col-md-4 col-lg-4">
          <span class="payableAmount">{{
            enrollData.payableAmount | currency : "USD" : "symbol"
            }}</span>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <button mat-button matStepperPrevious id="prevBtn" class="prevbutton">
            <img src="assets/images/right.svg" alt="Unable to load image" class="prev" />Previous
          </button>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <app-instant-payment class="nextbutton" [subTotal]="enrollData.payableAmount" [enrollData]="enrollData"
            [cardHolderName]="cardHolderName" (showError)="showError($event)"></app-instant-payment>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="alert alert-danger" role="alert" *ngIf="showPaymentError">
        <p>
          {{ErrorMessage}}
        </p>

      </div>
    </div>
  </div>
</div>