import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Password } from 'src/app/models/password.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export default class ChangepasswordService {
  
  changePasswordUrl: string = "ChangePassword/ChangeNewPassword";

  constructor(private http: HttpClient) { }

  changePassword(model: Password): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    return this.http.post(environment.baseUrl + this.changePasswordUrl, model, { headers: headers });
  }

}
