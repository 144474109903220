import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AESEncryptDecryptService {
  private symmKey: string = environment.SymmetricKey;
  private iv: string = environment.IV;
  constructor() { }
  encrypt(message: string) {
    return CryptoJS.AES.encrypt( CryptoJS.enc.Utf8.parse(message.trim()), CryptoJS.enc.Utf8.parse(this.symmKey), {
      keySize: 128 / 8,
      iv:CryptoJS.enc.Utf8.parse(this.iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
  }).toString();
    // this.decryptedMessage = CryptoJS.AES.decrypt( this.encryptedMessage,  this.password.trim() ).toString(CryptoJS.enc.Utf8);
   }
   decrypt(cipherText:string){
    return CryptoJS.AES.decrypt(cipherText, CryptoJS.enc.Utf8.parse(this.symmKey), {
      keySize: 128 / 8,
      iv:CryptoJS.enc.Utf8.parse(this.iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
  }).toString(CryptoJS.enc.Utf8);
   }
}
