import { Component, OnDestroy, OnInit } from '@angular/core';
import { IDCard } from 'src/app/models/coverages/idcard.model';
import { UnderWriter } from 'src/app/models/Brochure.model';
import { Router } from '@angular/router';
import { Student } from 'src/app/models/student.model';
import html2canvas from 'html2canvas';
import { Subscription } from 'rxjs';
import { PDFDocument } from 'pdf-lib';
import { EnrolService } from 'src/app/services/enrol/enrol.service';
import { RatesService } from 'src/app/services/rates/rates.service';
import { PaymentService } from 'src/app/services/payment.service';
import { FiscalYearService } from 'src/app/services/fiscal-year.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit, OnDestroy {
  studentCoverage: any;
  showIdCard: boolean = false;
  isPaymentSuccessfull: boolean = false;
  IDCardYear: number = 0;

  IDCardStudent = {} as IDCard;
  students: Student[] = [];
  subscription: Subscription;

  underwriters: UnderWriter = {
    insCode: '',
    insCo: '',
    state: '',
    address1: '',
    address2: '',
    city: '',
    zip: '',
    logoLoc: '',
  };
  studentIdCards: IDCard[] = [];
  paymentDetails: any;

  constructor(
    private router: Router,
    private enrollService: EnrolService,
    private ratesService: RatesService,
    private paymentService: PaymentService,
    private fiscalYearService: FiscalYearService
  ) {
    this.subscription = new Subscription();
    this.IDCardYear = this.fiscalYearService.getFiscalYearStart();
  }

  ngOnInit(): void {
    this.paymentService.paymentDetails$.subscribe((paymentDetails: any) => {
      if (
        paymentDetails &&
        paymentDetails.length > 0 &&
        paymentDetails[0].orderDetailID
      ) {
        this.paymentDetails = paymentDetails[0];
      }
    });

    this.subscription = this.enrollService.enrollData.subscribe((data) => {
      if (data.studentDetail && data.studentDetail.length > 0) {
        this.studentCoverage = data.studentDetail;
        let ids: IDCard[] = [];
        this.studentCoverage.forEach((student: any) => {
          ids.push(
            new IDCard(
              {
                ...student,
                coverages: [
                  ...student.coverageList.filter(
                    (x: any) => x.isChecked
                  ),
                  ...student.addOnCoverageList.filter(
                    (x: any) => x.isChecked
                  )
                ],
              },
              '',
              false
            )
          );
        });
        this.studentIdCards = ids;
        this.getUnderWriter(
          Number(this.studentIdCards[0].student.coverages[0].policyNo)
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  returnAccout() {
    this.router.navigate(['coverages']);
  }

  async generateConsolidatedPdf() {
    try {
      const consolidatedPdf = await PDFDocument.create();
      for (let i = 0; i < this.studentIdCards.length; i++) {
        // crate a pdf for each student
        const divRef = document.getElementById('idcard' + i);
        const studentPdf = await this.createStudentPdf(divRef);

        // Add the student's PDF to the consolidated PDF
        const pages = await consolidatedPdf.copyPages(
          studentPdf,
          studentPdf.getPageIndices()
        );
        pages.forEach((page: any) => consolidatedPdf.addPage(page));
      }

      // Download the consolidated Pdf
      const pdfBytes = await consolidatedPdf.save();
      this.downloadPdf(pdfBytes, 'consolidated_id_card.pdf');
    } catch (error) {
      console.error('Error generating consolidated PDF ', error);
    }
  }

  async createStudentPdf(divRef: any) {
    const pdfDoc = await PDFDocument.create();
    const canvas = await html2canvas(divRef, {scale: 2});
    const contentData = canvas.toDataURL('image/jpeg');
    let imgWidth = 700;
    let imgHeight = (canvas.height * imgWidth) / canvas.width;
    const jpgImageBytes = await fetch(contentData).then((res) =>
      res.arrayBuffer()
    );

    const jpgImage = await pdfDoc.embedJpg(jpgImageBytes);

    const page = pdfDoc.addPage();

    page.drawImage(jpgImage, {
      x: -50,
      y: 100,
      width: imgWidth,
      height: imgHeight,
    });

    return pdfDoc;
  }
  downloadPdf(pdfBytes: any, filename: any) {
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });

    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;

    a.download = filename;

    a.click();

    window.URL.revokeObjectURL(url);
  }

  generateAndDownloadConsolidatedPDF() {
    this.generateConsolidatedPdf();
  }

  getUnderWriter(policyNo: number) {
    this.ratesService.getBrochure(policyNo).subscribe({
      next: (result: any) => {
        this.underwriters = Object.assign(
          new UnderWriter(),
          result.body.underwriterModel
        );
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }
}
