import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UnderWriter } from 'src/app/models/Brochure.model';
import { IDCard } from 'src/app/models/coverages/idcard.model';
import { MyCoverages } from 'src/app/models/coverages/mycoverages.model';
import { StudentInfo } from 'src/app/models/coverages/studentInfo.model';
import { Coverage } from 'src/app/models/enrol/coverage.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MyCoverageService } from 'src/app/services/coverages/mycoverage.service';
import { EnrolService } from 'src/app/services/enrol/enrol.service';
import { FiscalYearService } from 'src/app/services/fiscal-year.service';
import { RatesService } from 'src/app/services/rates/rates.service';

@Component({
  selector: 'app-coverages',
  templateUrl: './coverages.component.html',
  styleUrls: ['./coverages.component.scss'],
})
export class CoveragesComponent {
  userId: number = 0;
  myCoverages: MyCoverages = {
    students: [],
    underWriter: new UnderWriter(),
  };
  myExpiredCoverages: MyCoverages = {
    students: [],
    underWriter: new UnderWriter(),
  };
  noOfCoverageStudents: number = Number.MAX_VALUE;
  noOfExpCoverageStudents: number = Number.MAX_VALUE;
  totalAmtOfCoveragePurchased: number = 0;
  currentYear: number = 0;
  loggedInUserId: string = '';
  isCollapse: boolean = true;
  isExpiryCollapse: boolean = true;
  displayedColumns: string[] = [
    'Activity',
    'Demographics',
    'MarketingPlan',
    'Rate',
    'EffectiveDate',
    'TerminationDate',
  ];
  isExpiredCoverageLoaded: boolean = true;
  underwriters: UnderWriter = {
    insCode: '',
    insCo: '',
    state: '',
    address1: '',
    address2: '',
    city: '',
    zip: '',
    logoLoc: '',
  };
  showIdCard: boolean = false;

  constructor(
    private myCoverageService: MyCoverageService,
    private ratesService: RatesService,
    private authService: AuthenticationService,
    private router: Router,
    private enrolService:EnrolService,
    private fiscalYearService : FiscalYearService,
    private spinner: NgxSpinnerService) {
    this.currentYear = this.fiscalYearService.getFiscalYearStart();
  }

  ngOnInit() {

    this.authService.loggedInUserId.subscribe((value) => {
      if (value && value != '') {
        this.loggedInUserId = value;
        this.getMyCoverages();
      }
    });
  }

  getMyCoverages() {
    this.spinner.show('default');
    this.myCoverageService
      .getStudentsCovered(parseInt(this.loggedInUserId))
      .subscribe({
        next: (result: any) => {
          if (result?.status) {
            let studIds = result.body.students.map((s: any) => s.studentID);
            this.myCoverageService.getCoveragesForStudents(studIds).subscribe({
              next: (response: any) => {
                result.body.students.forEach(
                  (element: {
                    firstName: string;
                    lastName: string;
                    state: string;
                    dob: string;
                    gradeID: number;
                    studentID: number;
                    phone: string;
                    district: string;
                    campusID: number;
                    campusType: string;
                    campusName: string;
                    coverages: Coverage[];
                    ssn:string;
                  }) => {

                    let data = {} as StudentInfo;
                    data.coverages = [];
                    data = {
                      firstName: element.firstName,
                      lastName: element.lastName,
                      state: element.state,
                      dob: element.dob,
                      gradeID: element.gradeID,
                      studentID: element.studentID,
                      phone: element.phone,
                      district: response.body.find(
                        (s: any) => s.studentID == element.studentID
                      )?.district,
                        schoolDistrictName:response.body.find(
                          (s: any) => s.studentID == element.studentID
                        )?.district,
                      coverages: response.body.filter(
                        (s: any) => s.studentID == element.studentID
                      )
                        ? response.body.filter(
                            (s: any) => s.studentID == element.studentID
                          )
                        : [],
                      totalAmount: 0,
                      campusID: element.campusID,
                      campusType: element.campusType,
                      campusName:element.campusName,
                      isCollapse: true,
                      isExpiryCollapse: true,
                      ssn: element.ssn
                    };
                    if (
                      data?.coverages &&
                      data.coverages.length > 0 &&
                      data.coverages[0].policyNo
                    ) {
                      this.getUnderWriter(parseInt(data.coverages[0].policyNo));
                    }

                    this.myCoverages.students.push(data);
                  }
                );
                this.noOfCoverageStudents = this.myCoverages.students.length;
                this.getTotAmtOfCoveragePurchased();
                this.spinner.hide('default');
              },
              error: (err: any) => {
                this.spinner.hide('default');
                console.log(err);
              }
            });
          }
        },
        error: (err: any) => {
          this.spinner.hide('default');
          console.log(err);
        }
      });
  }
  collapse(e: any) {
    this.myCoverages.students.forEach((s: any) => {
      if (s.studentID != e.studentID) {
        s.isCollapse = true;
      }
    });
    e.isCollapse = !e.isCollapse;
  }

  expiryCollapse(e: any) {
    this.myExpiredCoverages.students.forEach((s: any) => {
      if (s.studentID != e.studentID) {
        s.isExpiryCollapse = true;
      }
    });
    e.isExpiryCollapse = !e.isExpiryCollapse;
  }
  getTotAmtOfCoveragePurchased() {
    this.myCoverages.students.forEach((item) => {
      item.coverages.forEach((cov) => {
        if (cov.demographics.indexOf('*') == 0)
          item.totalAmount -= cov.paid < 0 ? (cov.paid * -1) : cov.paid;
        else
          item.totalAmount += cov.paid;
      });
      if (item.totalAmount < 0)
        item.totalAmount = 0;
    });
  }
  getTotAmtOfExpiredCoveragePurchased() {
    this.myExpiredCoverages.students.forEach((item) => {
      item.coverages.forEach((cov) => {
        if (cov.demographics.indexOf('*') == 0)
          item.totalAmount -= cov.paid < 0 ? (cov.paid * -1) : cov.paid;
        else
          item.totalAmount += cov.paid;
      });
      if (item.totalAmount < 0)
        item.totalAmount = 0;
    });
  }
  getUnderWriter(policyNo: number) {
    this.ratesService.getBrochure(policyNo).subscribe({
      next: (result: any) => {
        this.underwriters = Object.assign(
          new UnderWriter(),
          result.body.underwriterModel
        );
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  addStudent(event: any) {
    if (event == 'New Student') {
      this.myCoverageService.setBuyCoveragesFlag(false);
      this.myCoverageService.setStudentType('New');
    } else if (event == 'Saved Student') {
      this.myCoverageService.setBuyCoveragesFlag(false);
      this.myCoverageService.setStudentType('Saved');
    }
    this.router.navigate(['/enroll']);
  }

  buyNewCoverages(student: StudentInfo, isBuyAgain?: boolean) {
      this.myCoverageService.setBuyCoveragesFlag(true);
    if (isBuyAgain) {
      const x: StudentInfo = this.myCoverages.students.find(
        (stud) =>
          this.toLowerCaseF(student.firstName).trim() ==
            this.toLowerCaseF(stud.firstName).trim() &&
          this.toLowerCaseF(student.lastName).trim() ==
            this.toLowerCaseF(stud.lastName).trim()
      )!;
      if (x != null) student.studentID = x.studentID;
      this.myCoverageService.setBuyAgainCoverageFlag(true);
    } else {
      this.myCoverageService.setBuyAgainCoverageFlag(false);
    }

    this.myCoverageService.setStudToBuyCoverages(student);
    this.router.navigate(['/enroll']);
  }
  toLowerCaseF(value: string): string {
    return value.toLowerCase();
  }
  downloadIdCard(student: StudentInfo, underwriter: string) {
    this.showIdCard = true;
    let data = new IDCard(student, underwriter, true);
    this.myCoverageService.setUserData(data);
    setTimeout(() => {
      this.showIdCard = false;
    }, 2);
  }
  getMyExpiredCoverages() {
    this.spinner.show('default');
    this.myCoverageService
      .getExpiredCoverages(parseInt(this.loggedInUserId))
      .subscribe({
        next: (result: any) => {
          if (result.body != null) {
            result.body.students.forEach(
              (element: {
                firstName: string;
                lastName: string;
                state: string;
                dob: string;
                gradeID: number;
                studentID: number;
                phone: string;
                district: string;
                campusID: number;
                campusType: string;
                campusName: string;
                coverages: Coverage[];
                ssn:string;
              }) => {
                let data = {} as StudentInfo;
                data.coverages = [];
                data = {
                  firstName: element.firstName,
                  lastName: element.lastName,
                  state: element.state,
                  dob: element.dob,
                  gradeID: element.gradeID,
                  studentID: element.studentID,
                  phone: element.phone,
                  district: element.district,
                  coverages: element.coverages,
                  totalAmount: 0,
                  campusID: element.campusID,
                  campusType: element.campusType,
                  isCollapse: true,
                  isExpiryCollapse: true,
                  campusName:element.campusName,
                  schoolDistrictName: element.district,
                  ssn: element.ssn
                };
                if (
                  element?.coverages &&
                  element.coverages.length > 0 &&
                  element.coverages[0].policyNo
                ) {
                  this.getUnderWriter(parseInt(element.coverages[0].policyNo));
                  this.myExpiredCoverages.students.push(data);
                }
              }
            );
            this.getTotAmtOfExpiredCoveragePurchased();
            this.noOfExpCoverageStudents =
              this.myExpiredCoverages.students.length;
            this.spinner.hide('default');
          }
        },
        error: (err: any) => {
          console.log(err);
          this.spinner.hide('default');
        }
      });
  }
  LoadExpiredCoverage() {
    if (this.isExpiredCoverageLoaded) {
      this.getMyExpiredCoverages();
      this.isExpiredCoverageLoaded = false;
    }
  }
}
