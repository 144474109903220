import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common'

@Injectable({
  providedIn: 'root'
})
export class AnnualMaintenanceService {
  today = new Date();
  dateUTC = new Date(
    this.today.getUTCFullYear(),
    this.today.getUTCMonth(),
    this.today.getUTCDate(),
    this.today.getUTCHours(),
    this.today.getUTCMinutes(),
    this.today.getUTCSeconds(),
    this.today.getUTCMilliseconds()
  );
  redirectURL = 'https://www.hsri.com/k12_annual_maintenance.html';

  maintenanceStartDateUTC = new Date(this.today.getUTCFullYear(), 5, 28, 5, 0, 0, 0); // Midnight June 28 Central Time
  maintenanceStopDateUTC = new Date(this.today.getUTCFullYear(), 6, 1, 5, 0, 0, 0); // Midnight July 1 Central Time

  preMaintenanceMessage: boolean = false;

  constructor(@Inject(DOCUMENT) private document: Document) { }

  checkForMaintenance(): void {
    if(this.today >= new Date('6/1/' + this.today.getFullYear()) && this.dateUTC < this.maintenanceStopDateUTC) {
      console.log("Maintenance service started.");
      this.preMaintenanceMessage = true;
      this.goToMaintenancePage(this.maintenanceStartDateUTC, this.maintenanceStopDateUTC);
      setInterval(() => {this.goToMaintenancePage(this.maintenanceStartDateUTC, this.maintenanceStopDateUTC)}, 60000);
    }
  }

  goToMaintenancePage(startDate: Date, stopDate: Date): void {
    const nowUTC = new Date(
      this.today.getUTCFullYear(),
      this.today.getUTCMonth(),
      this.today.getUTCDate(),
      this.today.getUTCHours(),
      this.today.getUTCMinutes(),
      this.today.getUTCSeconds(),
      this.today.getUTCMilliseconds()
    );
    if(nowUTC >= startDate && nowUTC < stopDate) {
      console.log("Maintenance period detected.");
      this.document.location.href = this.redirectURL;
    }
  }
}
