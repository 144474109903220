export class Brochure{
    brochureCode : string="";
    description : string="";
}
export class UnderWriter{
    insCode : string="";
    insCo : string="";
    address1: string="";
    address2: string="";
    city: string="";
    state: string="";
    zip: string="";
    logoLoc: string="";
}