<div class="annual-maintenance-modal" role="alert" class="alert alert-warning mb-0">
  <h2 mat-dialog-title class="mb-3 fw-bold">NOTICE:</h2>
  <mat-dialog-content class="text-dark">
    <p>This website will be <strong>closed</strong> from <strong>June 28 to June 30</strong> for <strong>{{ nextSchoolYear }}-{{ nextSchoolYear + 1 }} School Year</strong> preparations.</p>
    <p>If you want to purchase insurance coverage for the <strong>{{ nextSchoolYear }}-{{ nextSchoolYear + 1 }} School Year</strong>, please wait until <strong>July 1</strong>.</p>
    <p><strong>Spring & Summer</strong> insurance plans for the current <strong>{{ currentSchoolYear }}-{{ currentSchoolYear + 1 }} School Year</strong> are still available for purchase through <strong>June 27.</strong></p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button class="btn btn-sm btn-warning mx-auto mb-2 fw-bold" mat-dialog-close>Close</button>
  </mat-dialog-actions>
</div>
