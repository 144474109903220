<div class="edit-student-modal" id="editStudentmodal">
  <div>
    <button (click)="closeDialog()" id="closeBtn" class="closeHeader">
      <img src="./assets/images/close cross.svg" alt="Unable to load image" />
    </button>
  </div>
  <div class="row editHeader">
    <div id="editStudent">Edit Student</div>
  </div>
  <div>
    <form class="row g-3" #editStudent="ngForm" id="editStudentForm">
      <div class="form-group col-lg-6 col-md-6 col-sm-4">
        <label for="fname" class="edit-label"
          >First Name<span class="require">*</span></label
        >
        <input
          type="text"
          class="form-control"
          id="fname"
          name="firstname"
          [pattern]="ufirstnamePattern"
          placeholder="Enter First Name"
          [(ngModel)]="student.firstName"
          required
          minlength="2"
          #firstname="ngModel"
          [ngClass]="{
            'is-invalid': editStudent.submitted && firstname.errors
          }"
        />

        <div
          *ngIf="firstname.invalid && (editStudent.submitted || firstname.dirty || firstname.touched)"
          class="alert-danger"
          style="color: red"
        >
          <div *ngIf="firstname.errors?.['required']">
            Missing item - First Name!
          </div>
          <div *ngIf="firstname.errors?.['pattern']">
            The First name can only contain Hyphens(-),apostrophe('), spaces and
            characters.
          </div>
          <div *ngIf="firstname.errors?.['minlength']">
            Name must be at least 2 characters long.
          </div>
        </div>
      </div>
      <div class="form-group col-lg-6 col-md-6 col-sm-4">
        <label for="lname" class="edit-label"
          >Last Name<span class="require">*</span></label
        >
        <input
          type="text"
          class="form-control"
          id="lname"
          name="lastname"
          [pattern]="unamePattern"
          placeholder="Enter Last Name"
          [(ngModel)]="student.lastName"
          required
          minlength="2"
          #lastname="ngModel"
          [ngClass]="{ 'is-invalid': editStudent.submitted && lastname.errors }"
        />
        <div
          *ngIf="lastname.invalid && (lastname.dirty || lastname.touched)"
          class="alert-danger"
          style="color: red"
        >
          <div *ngIf="lastname.errors?.['required']">
            Missing item - Last Name!
          </div>
          <div *ngIf="lastname.errors?.['pattern']">
            The Last name can only contain Hyphens(-),apostrophe('), spaces and
            characters.
          </div>
          <div *ngIf="lastname.errors?.['minlength']">
            Name must be at least 2 characters long.
          </div>
        </div>
      </div>
      <div class="form-group col-lg-6 col-md-6 col-sm-4">
        <label class="edit-label"
          >Date of Birth<span class="require">*</span></label
        >
        <input
          type="text"
          class="form-control"
          placeholder="Select Date of Birth"
          onfocus="(this.type='date')"
          onblur="(this.type='text')"
          id="dob"
          name="dob"
          [max]="maxDate"
          [(ngModel)]="student.dob"
          #dob="ngModel"
          required
        />

        <div
          *ngIf="dob.touched || editStudent.submitted"
          class="alert-danger"
          style="color: red"
        >
          <div *ngIf="dob.errors?.['required']">
            Missing item - Date of Birth!
          </div>
        </div>
      </div>
      <div class="form-group col-lg-6 col-md-6 col-sm-4">
        <label class="edit-label">Grade<span class="require">*</span></label>
        <select
          class="inputBox"
          placeholder="Select Grade"
          name="gra"
          id="gra"
          [(ngModel)]="student.grade"
          #gra="ngModel"
        >
          <option value="" disabled selected>Select Grade</option>
          <option value="0">K</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
          <option value="11">11</option>
          <option value="12">12</option>
          <option value="13">Col</option>
          <option value="14">Other</option>
        </select>

        <div
          *ngIf="gra.touched || editStudent.submitted"
          class="alert-danger"
          style="color: red"
        >
          <div *ngIf="gra.value == ''">Missing item - Grade!</div>
        </div>
      </div>
      <div class="form-group col-lg-6 col-md-6 col-sm-4">
        <label for="studentID" class="edit-label"
          >Student ID<span class="require">*</span></label
        >
        <input
          type="text"
          class="form-control"
          name="studentId"
          id="studentID"
          id="studentId"
          [(ngModel)]="student.ssn"
          #studentID="ngModel"
          placeholder="Enter Student ID"
          appStudentidValidator
          [studentFirstName]="firstname.value"
          [studentLastName]="lastname.value"
          required
        />

        <div
          *ngIf="studentID.touched || editStudent.submitted"
          class="alert-danger"
          style="color: red"
        >
          <div *ngIf="studentID.errors?.['required']">
            Missing item - Student ID!
          </div>
          <div
            *ngIf="studentID.errors?.['uniqueStudentId'] && (studentID.touched || studentID.dirty)"
          >
            Student ID entered already exists!
          </div>
        </div>
      </div>
      <div class="form-group col-lg-6 col-md-6 col-sm-4">
        <label for="mobile" class="edit-label">Phone No.</label>
        <input
          type="number"
          class="form-control"
          name="mobile"
          id="mobile"
          placeholder="Enter Phone No."
          [(ngModel)]="student.phone"
          #phone="ngModel"
          oninput="this.value=this.value.slice(0,this.dataset.maxlength);"
          [pattern]="phonepattern"
          minlength="10"
          data-maxlength="10"
        />
        <div
          *ngIf="(phone.touched || editStudent.submitted || phone.invalid)"
          class="errorMessage">
        
          <div *ngIf="phone.errors?.['required']">Missing item - Phone No!</div>
          <div *ngIf="phone.errors?.['pattern']">Invalid Phone No!</div>
        </div>
      </div>
      <div class="form-groupcol-lg-6 col-md-6 col-sm-4 selectDropdown">
        <label for="inputState" class="edit-label"
          >State<span class="require">*</span></label
        >
        <select
          class="inputBox"
          [(ngModel)]="student.statecode"
          name="state"
          id="state"
          (change)="getDistricts(true)"
          #state="ngModel"
          required
        >
          <option value="" disabled selected>Select State</option>
          <option *ngFor="let st of states" [value]="st.state">
            {{ st.name }}
          </option>
        </select>

        <div
          *ngIf="state.touched || editStudent.submitted"
          class="alert-danger"
          style="color: red"
        >
          <div *ngIf="state.errors?.['required']">Missing item - State!</div>
        </div>
      </div>
      <div class="form-group col-lg-6 col-md-6 col-sm-4 selectDropdown">
        <label for="inputState" class="edit-label"
          >School District<span class="require">*</span></label
        >
        <select
          class="inputBox"
          [(ngModel)]="student.schoolDistrict"
          name="schoolDistrict"
          id="district"
          (change)="getCampus()"
          #schoolDistrict="ngModel"
          required
        >
          <option value="">Select School District</option>

          <option *ngFor="let dist of districts" [value]="dist.policyNo">
            {{ dist.client }}
          </option>
        </select>

        <div
          *ngIf="schoolDistrict.touched || editStudent.submitted"
          class="alert-danger"
          style="color: red"
        >
          <div *ngIf="schoolDistrict.errors?.['required']">
            Missing item - School District !
          </div>
        </div>
      </div>
      <div class="form-group col-lg-6 col-md-6 col-sm-4 selectDropdown">
        <label for="inputState" class="edit-label"
          >Campus<span class="require">*</span></label
        >
        <select
          class="inputBox"
          [(ngModel)]="student.campus"
          name="camdata"
          id="capmus"
          #camdata="ngModel"
          required
        >
          <option value="0" disabled selected>Select Campus</option>
          <option *ngFor="let camp of campus" [value]="camp.campusID">
            {{ camp.campusName }}
          </option>
        </select>
        <div
          *ngIf="camdata.touched || editStudent.submitted"
          class="alert-danger"
          style="color: red"
        >
          <div *ngIf="camdata.value == ''">Missing item - Campus!</div>
        </div>
      </div>

      <div class="form-group col-lg-6 col-md-6 col-sm-4 selectDropdown">
        <label for="inputState" class="edit-label"
          >Campus Type<span class="require">*</span></label
        >
        <select
          class="inputBox"
          [(ngModel)]="student.levelCode"
          name="campustype"
          id="campusType"
          #capustype="ngModel"
          required
        >
          <option value="" disabled selected>Select Campus Type</option>
          <option value="E">Elementary</option>
          <option value="M">Middle School</option>
          <option value="H">High School</option>
          <option value="O">College/Adult</option>
        </select>
        <div
          *ngIf="capustype.touched || editStudent.submitted"
          class="alert-danger"
          style="color: red"
        >
          <div *ngIf="capustype.errors?.['required']">
            Missing item - Campus Type !
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6">
        <button
          type="button"
          (click)="closeDialog()"
          id="cancelBtn"
          class="cancelBtn"
        >
          Cancel
        </button>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6">
        <button
          type="submit"
          (click)="saveStudent(editStudent, student)"
          id="saveBtn"
          class="saveBtn"
        >
          Save
        </button>
      </div>
    </form>
  </div>
</div>
