export class PostPayment {
    email: string | undefined = "";
    subTotal: number = 0;
    feeAmount: number = 0;
    shoppingCarts: ShoppingCart[] = [];
    transactionId: string = "";

    constructor(email: string, subTotal: number, feeAmount: number, shoppingCarts: ShoppingCart[], transactionId: string = "") {
        this.email = email;
        this.subTotal = subTotal;
        this.feeAmount = feeAmount;
        this.transactionId = transactionId;
    }
}

export class ShoppingCart {
    studentID: string = "";
    rowID: number = 0;
    subcat: string | undefined = "";
    ssn: string = '';

    constructor(studentID: string, rowID: number, subcat: string, ssn: string) {
        this.studentID = studentID;
        this.rowID = rowID;
        this.subcat = subcat;
        this.ssn = ssn
    }
}