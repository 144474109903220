import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, of, tap } from 'rxjs';
import { State } from 'src/app/models/enrol/state.model';
import { District } from 'src/app/models/enrol/district.model';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LocationService {

    apiUrl = 'Location';
    constructor(private http: HttpClient) { }
    private coverageStates = new BehaviorSubject<State[]>([]);
    private allStates = new BehaviorSubject<State[]>([]);

    getCoverageStates(): Observable<State[]> {
        if(this.coverageStates.value && this.coverageStates.value.length > 0) return this.coverageStates.asObservable();
        return this.http.post<State[]>(environment.baseUrl + this.apiUrl+ '/CoverageStates/', null).pipe(
            tap(states => {
            this.coverageStates.next(states);
            return of(states);
        }),
        catchError(error => {
            throw error;
        }));
    }

    getAllStates(): Observable<State[]> {
        if(this.allStates.value && this.allStates.value.length > 0) return this.allStates.asObservable();
        return this.http.post<State[]>(environment.baseUrl + this.apiUrl+ '/GetAllStates/', null).pipe(
            tap(states => {
            this.allStates.next(states);
            return of(states);
        }),
        catchError(error => {
            throw error;
        }));
    }

    getDistricts(selectedState: string): Observable<District[]> {
        const headers = { 'content-type': 'application/json' }
        const body = JSON.stringify(selectedState);
        return this.http.post<District[]>(environment.baseUrl +  this.apiUrl+ '/GetDistricts/', body, { headers: headers });
    }

}