import { Component, Inject ,OnInit} from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Campus } from 'src/app/models/enrol/campus.model';
import { District } from 'src/app/models/enrol/district.model';
import { State } from 'src/app/models/enrol/state.model';
import { Student } from 'src/app/models/student.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EnrolService } from 'src/app/services/enrol/enrol.service';
import { LocationService } from 'src/app/services/location/location.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { StudentDetailsService } from 'src/app/services/studentdetails/student-details.service';

@Component({
  selector: 'app-edit-student-dialog',
  templateUrl: './edit-student-dialog.component.html',
  styleUrls: ['./edit-student-dialog.component.scss']
})
export class EditStudentDialogComponent implements OnInit {
  states: State[] = [];
  districts: District[] = [];
  selectedState: string = "";
  selectedDistrict: string = "";
  selectedCampus: string = "";
  selectedCampusType: string = "";
  campus: Campus[] = [];
  fetchedCampusName: string = "";
  schoolName: any;
  schoolDistrictName:any;
  dobDate:string = "";

  ufirstnamePattern = "^([a-zA-Z]{1,}?[a-z0-9 \\'\\-]{0,})*$";
  unamePattern = "^([a-zA-Z]{1,}?[a-z \\'\\-]{0,})*$";
  maxDate:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private auth: AuthenticationService,
  private locationService: LocationService,private enrolService: EnrolService,
  private notifyService: NotificationService,
  private studService:StudentDetailsService,private dialogRef: MatDialogRef<EditStudentDialogComponent>){}

  student = new Student("","",0,"","","","","","",0,"","","","",0,"");
  phonepattern =
    '^(\\+\\d{1,2}\\s?)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$|(?:NaN)';

  
  ngOnInit(){
    this.student=this.data;
    this.getCoverageStates();
    this.maxDate = new Date().toDateString();
   
    this.getDistricts();
    if(this.student?.dob){
    this.student.dob = this.student.dob.split('T')[0];
    }
   
  }
  closeDialog(){
    this.dialogRef.close();
  }
  getCoverageStates() {
    this.locationService.getCoverageStates().subscribe({
      next: (result: any) => {

        result.body.forEach((item: { name: string; state: string; }) => {
          let data = {
            name: item.name,
            state: item.state,
            postalAbbreviation: "",
            countryCode: "",
            capitalCity: "",
            standardAbbreviation: ""
          } as State;
          

          this.states.push(data);
        });
      
      },
    
    });
  }
  getDistricts(isChange?: boolean) {
    this.districts = [];
    if(isChange){
      this.student.schoolDistrict ='';
    this.student.campus = 0;
    }

    this.locationService.getDistricts(this.student.statecode).subscribe({
      next: (result: any) => {

        result.body.forEach((item: { policyNo: number; client: string; }) => {
          let  data = {
            policyNo: item.policyNo,
            client: item.client
          } as District;
          this.districts.push(data);
          this.student.state = this.states.find((i)=>i.state==this.student.statecode)?.name!
          
        });
        this.schoolDistrictName = this.student.campusName;
          this.fetchedCampusName = this.schoolDistrictName;
          this.getCampus();
        



      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }
  getCampus() {
    this.campus = [];

    this.enrolService.getCampus(this.student.schoolDistrict).subscribe({
      next: (result: any) => {
        
        result.body.forEach((item: { campusID: number; campusName: string; }) => {
          let data =  {
            campusID: item.campusID,
            campusName: item.campusName
          } as Campus;

          this.campus.push(data);

        });
  
       
        
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }
  showSaveNotification(){
    this.notifyService.showSuccess("Student details updated");
  }
 saveStudent(form:NgForm,student:Student){
  if(form.invalid)
  return;
  student.phone = student.phone?.toString();
  //this.student.levelCode='H';
  //alert(student);
  this.student.campusName = this.campus.find((x)=>x.campusID==this.student.campus)?.campusName!
  this.studService.saveStudent(student).subscribe();
  this.showSaveNotification();
  
  this.studService.addStudent(student);
  this.dialogRef.close("");
}
}
