<div class="add-student-modal" id="addStudent">
  <div >
    <button (click)="closeDialog()" id="closeBtn" class="closeHeader">
        <img src="./assets/images/close cross.svg">
    </button>
</div>
<div class="row editHeader">
  <div id="addStudent" class="addStudent">Add Student</div>
</div>
  <div class="container">
    <form class="row g-3" name="form" #addStudent="ngForm" id="addStudentForm">
      <div class="form-group col-lg-6 col-md-6 col-sm-4">
        <label for="fname" class="edit-label">First Name<span class="require">*</span></label>
        <input type="text" class="form-control" id="fname" name="firstname" [pattern]="ufirstnamePattern"
          placeholder="Enter First Name" [(ngModel)]="student.firstName" 
           #firstname="ngModel"
          required />

        <div *ngIf="firstname.invalid && (addStudent.submitted ||firstname.dirty || firstname.touched)" class="alert-danger"
          style="color: red;">
          <div *ngIf="firstname.errors?.['required']">
            Missing item - First Name!
          </div>
          <div *ngIf="firstname.errors?.['pattern']">
            The First name can only contain Hyphens(-),apostrophe('), spaces and characters.
          </div>
        </div>
      </div>
      <div class="form-group col-lg-6 col-md-6 col-sm-4">
        <label for="lname" class="edit-label">Last Name<span class="require">*</span></label>
        <input type="text" class="form-control" id="lname" name="lastname" [pattern]="unamePattern"
          placeholder="Enter Last Name" [(ngModel)]="student.lastName" #lastname="ngModel"
           required />
           
        <div *ngIf="(addStudent.submitted || lastname.touched)" class="alert-danger"
          style="color: red;">
          <div *ngIf="lastname.errors?.['required']">
            Missing item - Last Name!
          </div>
          <div *ngIf="lastname.errors?.['pattern']">
            The Last name can only contain Hyphens(-),apostrophe('), spaces and characters.
          </div>
        </div>
      </div>
      <div class="form-group col-lg-6 col-md-6 col-sm-4">
        <label class="edit-label">Date of Birth<span class="require">*</span></label>
        <input type="text" class="form-control" placeholder="Select Date of Birth"  id="dob"
        onfocus="(this.type='date')" onblur="(this.type='text')" name="dob" 
        [max]="maxDate" [(ngModel)]="student.dob"  #dob="ngModel" 
                required/>

        <div *ngIf="(dob.touched || addStudent.submitted) " class="alert-danger"
        style="color: red;">
        <div *ngIf="dob.errors?.['required']">
            Missing item - Date of Birth!
          </div>
          </div> 
         
      </div>
      <div class="form-group col-lg-6 col-md-6 col-sm-4">
        <label class="edit-label">Grade<span class="require">*</span></label>
        <select class="inputBox"    id="gra"
        [(ngModel)]="student.grade" placeholder="Select Grade" name="gra"   #gra="ngModel" >
          <option value="" disabled>Select Grade </option>
          <option value="0">K</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
          <option value="11">11</option>
          <option value="12">12</option>
          <option value="13">Col</option>
          <option value="14">Other</option>

        </select>
        
        <div *ngIf="(gra.touched || addStudent.submitted) " class="alert-danger"
        style="color: red;">
          <div *ngIf=" gra.value==''">
            Missing item - Grade!
          </div>

        </div>
      </div>
      <div class="form-group col-lg-6 col-md-6 col-sm-4">
        <label for="studentID" class="edit-label">Student ID<span class="require">*</span></label>
        <input type="text" class="form-control"  name="studentId" placeholder="Enter Student ID"
         id="studentID" [(ngModel)]="student.ssn" #studentID="ngModel" 
         appStudentidValidator [studentFirstName]="firstname.value" [studentLastName]="lastname.value" [isAddScreen]="true"
         required>

         <div *ngIf="(studentID.touched || addStudent.submitted) " class="alert-danger"
          style="color: red;">
          <div *ngIf="studentID.errors?.['required']">
              Missing item - Student ID!
            </div>
            <div *ngIf="studentID.errors?.['uniqueStudentId'] && (studentID.touched || studentID.dirty)">
              Student ID entered already exists!
          </div>
            </div> 

      </div>
      <div class="form-group col-lg-6 col-md-6 col-sm-4">
        <label for="mobile" class="edit-label">Phone No.</label>
        <input type="number" class="form-control" name="mobile" id="mobile" placeholder="Enter Phone No."
         [(ngModel)]="student.phone" [pattern]="phonepattern" minlength="10" data-maxlength="10"
         oninput="this.value=this.value.slice(0,this.dataset.maxlength);"
         #phone="ngModel">
          <div *ngIf="(phone.touched || addStudent.submitted  || phone.invalid)" class="errorMessage">
            <div *ngIf="phone.errors?.['required']">
                Missing item - Phone No!
            </div>
            <div *ngIf="phone.errors?.['pattern']">
                Invalid Phone No!
            </div>
        </div>
         
      </div>

      <div class="form-group col-lg-6 col-md-6 col-sm-4 selectDropdown">
        <label for="inputState" class="edit-label">State<span class="require">*</span></label>
        <select class="inputBox" [(ngModel)]="student.statecode" name="state" id="state"
         (change)="getDistricts(true)" #state="ngModel" required>

          <option value=""disabled selected>Select State</option>
          <option *ngFor="let st of states" [value]="st.state">
              {{st.name}}
          </option>
      </select>

      <div *ngIf="(state.touched || addStudent.submitted) " class="alert-danger"
        style="color: red;">
        <div *ngIf="state.errors?.['required']">
            Missing item - State!
          </div>
          </div>
      </div>
      <div class="form-group col-lg-6 col-md-6 col-sm-4 selectDropdown">
        <label for="inputState" class="edit-label">School District<span class="require">*</span></label>
        <select class="inputBox" name="schoolDistrict" aria-placeholder="Enter School District" 
        (change)="getCampus()" id="district"
                [(ngModel)]="student.schoolDistrict" #schoolDistrict="ngModel" required>
  
                <option value="">Select School District</option>
                <option *ngFor="let dist of districts" [value]="dist.policyNo">
                    {{dist.client}}
                </option>
            </select>
            <div *ngIf="(schoolDistrict.touched || addStudent.submitted) " class="alert-danger"
                  style="color: red;">
                  <div *ngIf="schoolDistrict.errors?.['required']">
                          Missing item - School District!
                  </div>
                </div>
      </div>
      <div class="form-group col-lg-6 col-md-6 col-sm-4 selectDropdown">
        <label for="inputState" class="edit-label">Campus<span class="require">*</span></label>
        <select class="inputBox" [(ngModel)]="student.campus"  name="camdata" id="campus"  #camdata="ngModel"
        required>
          <option value="0" disabled selected>Select Campus</option>
          <option *ngFor="let camp of campus" [value]="camp.campusID">
              {{camp.campusName}}
          </option>
      </select>
      <div *ngIf="(camdata.touched || addStudent.submitted) " class="alert-danger"
                  style="color: red;">
                  <div *ngIf="camdata.value==''">
                          Missing item - Campus!
                  </div>
                </div>
      </div>
      <div class="form-group col-lg-6 col-md-6 col-sm-4 selectDropdown">
        <label for="inputState" class="edit-label">Campus Type<span class="require">*</span></label>
        <select class="inputBox" [(ngModel)]="selectedCampusType" name="capustype" id="campusType"
        #capustype="ngModel" required>
        
          <option value=""  disabled selected>Select Campus Type</option>
          <option value="E">Elementary</option>
          <option value="M">Middle School</option>
          <option value="H">High School</option>
          <option value="O">College/Adult</option>
      </select>
      <div *ngIf="(capustype.touched || addStudent.submitted) " class="alert-danger"
                  style="color: red;">
                  <div *ngIf="capustype.errors?.['required']">
                          Missing item - Campus Type!
                  </div>
                </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6">
        <button type="button" id="cancelBtn" (click)="closeDialog()" class="cancelBtn">Cancel</button>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6">
        <button type="submit" id="saveBtn" (click)="saveStudent(addStudent ,student)" class="saveBtn">Save</button>
      </div>

    </form>
  </div>
</div>