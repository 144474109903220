export class Student {
    studentID: number = 0;
    firstName: string = "";
    lastName: string = "";
    dob: string = "";
    campus: number = 0;
    campusName: string = "";
    levelCode: string = "";
    grade: string = "";
    phone: string = "";
    statecode: string = "";
    state: string = "";
    schoolDistrict: string = "";
    isStudentCovered: string = "";
    ssn: string = "";
    accountId: number = 0;
    campusType: string = "";
    schoolDistrictName:string = '';

    constructor(firstName: string, lastName: string,
        campus: number, campusName: string, levelCode: string, grade: string, statecode: string, state: string, schoolDistrict: string,
        studentID: number, dob: string, mobile: string, isStudentCovered: string, ssn: string, accountId: number, schoolDistrictName:string) {

        this.firstName = firstName;
        this.lastName = lastName;
        this.campus = campus;
        this.campusName = campusName;
        this.levelCode = levelCode;
        this.grade = grade;
        this.studentID = studentID;
        this.dob = dob;
        this.phone = mobile;
        this.state = state;
        this.statecode = statecode;
        this.schoolDistrict = schoolDistrict;
        this.isStudentCovered = isStudentCovered;
        this.ssn = ssn;
        this.accountId = accountId;
        this.schoolDistrictName = schoolDistrictName
    }
}