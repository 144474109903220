import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AddStudentDialogComponent } from '../../dialogs/add-student-dialog/add-student-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { StudentDetailsService } from 'src/app/services/studentdetails/student-details.service';
import { EditStudentDialogComponent } from '../../dialogs/edit-student-dialog/edit-student-dialog.component';
import { DeleteStudentDialogComponent } from '../../dialogs/delete-student-dialog/delete-student-dialog.component';
import { ViewStudentDialogComponent } from '../../dialogs/view-student-dialog/view-student-dialog.component';
import { Student } from 'src/app/models/student.model';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { NotificationService } from 'src/app/services/notification/notification.service';


type NewType = DomSanitizer;

@Component({
  selector: 'app-student-details',
  templateUrl: './student-details.component.html',
  styleUrls: ['./student-details.component.scss']
})
export class StudentDetailsComponent implements OnInit {
  displayedColumns = ["Student Name", "Status", "Actions"];
  accountId: any;
  students: Student[] = [];
  rowStudent: any;
  studentsname: any[] = [];


  constructor(private dialog: MatDialog,
    private auth: AuthenticationService,
    private studentDetailsService: StudentDetailsService,
    private domSanitizer: DomSanitizer,
    private notifyService: NotificationService,

    private matIconRegistry: MatIconRegistry,
    private cdr: ChangeDetectorRef) {
    this.matIconRegistry.addSvgIcon("trash", this.domSanitizer
      .bypassSecurityTrustResourceUrl('assets/images/trash.svg'));

  }

  ngOnInit(): void {
    this.auth.loggedInUserId.subscribe((res) =>
      this.accountId = res)
    this.getStudents(this.accountId)
  }

  addStudent() {
    this.dialog.open(AddStudentDialogComponent,
      { panelClass: 'add-student-modal', autoFocus: true }).afterClosed().subscribe((res) => {
        this.getStudents(this.accountId);
      });
  }

  editStudent(student: Student) {
    const dialogRef = this.dialog.open(EditStudentDialogComponent, { panelClass: 'edit-student-modal', data: student });
    dialogRef.afterClosed().subscribe((res) => {
      this.getStudents(this.accountId);
    })
  }

  deleteStudent(id: number) {
    const dialogRef = this.dialog.open(DeleteStudentDialogComponent, { panelClass: 'delete-student-modal', autoFocus: true, data: id });
    dialogRef.afterClosed().subscribe(value => {
      this.getStudents(this.accountId)
    })
  }

  viewStudent(student: Student) {
    this.dialog.open(ViewStudentDialogComponent, { panelClass: 'view-student-modal', autoFocus: true, data: student, })
  }

  getStudents(accountId: number) {
    this.studentDetailsService.getStudents(accountId).subscribe((result) => {

      this.students = result.body;
      this.studentDetailsService.students = result.body;

      this.checkStudentCovered();
    });
  }

  checkStudentCovered() {
 
    let studIds = this.students.map(s => s.studentID);
    this.studentDetailsService.checkStudentsCoverageStatus(studIds).subscribe({
      next: (result: any) => {
        if (result?.body) {
          this.students.forEach(s => {
            s.isStudentCovered = result.body.filter((b: any) => b.studentId == s.studentID)[0] ? result.body.filter((b: any) => b.studentId == s.studentID)[0].status: "Not Covered";
          })
        }
      },
      error: (err: any) => {
        console.log(err);
      }
    })

  }

  getStudentbyId(id: string) {
    this.rowStudent = this.students.filter((s) => s.studentID.toString() == id);
  }
}
