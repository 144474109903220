<div class="user-registration-modal " id="userreg">
    <div>
        <button (click)="closeDialog()" class="closeHeader" id="closeBtn">
            <img src="./assets/images/close cross.svg" alt="Unable to load image">
        </button>
    </div>
    <div class="userreg">
        <div class="row" style="row-gap:0.5rem;">
            <div class="header">
                User Registration
            </div>
            <div class="subheader">
                Create password to continue
            </div>
        </div>
        <div class="row" style="margin-top: 1.5rem;">
            <div>
                <form #register="ngForm">
                    <div class="row">
                        <div class="form-group">
                            <label for="email" class="uLabel">Email Address</label>
                            <input type="email" class="form-control" id="useremail" name="useremail" disabled email
                                [(ngModel)]="userData.email" #email="ngModel" />
                        </div>
                    </div>
                    <div class="row passwordRow">
                        <div ngModelGroup="passwords" #passwords="ngModelGroup" appCheckPassword>
                            <div class="form-group">
                                <label for="passwordA">Password</label>
                                <input type="password" class="form-control" #passwordA="ngModel" name="passwordA"
                                    required minlength="8" [pattern]="passwordPattern" id="passwordA"
                                    [(ngModel)]="userData.password"
                                    [ngClass]="{ 'is-invalid': register.submitted && passwordA.errors }">
                                <div *ngIf="passwordA.invalid && (passwordA.dirty || passwordA.touched)"
                                    class="alert-danger" style="color: red;font-size: 0.8rem;">
                                    <div *ngIf="passwordA.errors?.['required']">
                                        Missing item - Password!
                                    </div>
                                    <div *ngIf="passwordA.errors?.['pattern']">
                                        Password must contain 1 Upper Case letters and special characters
                                    </div>
                                    <div *ngIf="passwordA.errors?.['minlength']">
                                        Password must be 8 characters in length.
                                    </div>
                                </div>
                            </div>

                            <div class="form-group" style="margin-top: 0.6rem;">
                                <label for="passwordB">Confirm Password</label>
                                <input type="password" class="form-control" #passwordB="ngModel" name="passwordB"
                                    required id="passwordB" [(ngModel)]="cpassword">

                                <div *ngIf="passwordB.invalid && (passwordB.dirty || passwordB.touched)"
                                    class="alert-danger " style="color: red; font-size: 0.8rem;">
                                    <div *ngIf="passwordB.errors?.['required']">
                                        Missing item - Confirm Password!
                                    </div>

                                </div>
                                <div class="alert-danger" style="color: red; font-size: 0.8rem;"
                                    *ngIf="(passwordB.dirty || passwordB.touched) && passwords.errors?.['passwordCheck']">
                                    The new password that you entered do not match.
                                </div>

                            </div>
                        </div>

                    </div>
                </form>
            </div>
            <div style="margin-top: 2rem;">
                <button class="continueBtn" [disabled]="register.invalid" id="registerBtn"
                    (click)="UserRegister(userData)">Continue</button>
            </div>
        </div>
    </div>
</div>