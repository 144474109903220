import {
  Component,
  ElementRef,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Campus } from 'src/app/models/enrol/campus.model';
import { Coverage } from 'src/app/models/enrol/coverage.model';
import { District } from 'src/app/models/enrol/district.model';
import { State } from 'src/app/models/enrol/state.model';
import { StudentCoverage } from 'src/app/models/enrol/studentcoverage.model';
import { EnrolService } from 'src/app/services/enrol/enrol.service';
import { LocationService } from 'src/app/services/location/location.service';
import { MyCoverageService } from 'src/app/services/coverages/mycoverage.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Student } from 'src/app/models/student.model';
import { Enroll } from 'src/app/models/Enroll.model';
import { StepperService } from 'src/app/services/stepper/stepper.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { MatStepper } from '@angular/material/stepper';
import { FiscalYearService } from 'src/app/services/fiscal-year.service';

@Component({
  selector: 'app-add-coveragedetails',
  templateUrl: './add-coveragedetails.component.html',
  styleUrls: ['./add-coveragedetails.component.scss'],
})
export class AddCoveragedetailsComponent implements OnInit, OnDestroy {
  states: State[] = [];
  districts: District[] = [];
  campus: Campus[] = [];
  studFirstName: string = '';
  selectedState: string = '';
  selectedStateName: string = '';
  selectedDistrict: string = '';
  selectedCampus: string = '';
  selectedCampusType: string = '';
  selectedStudent: string = '';
  currentYear: string = '';
  payableAmount: number = 0;
  newOrSavedStudent: string = '';
  loggedInUserId: string = '';
  showForm: boolean = true;
  enrollData = new Enroll('', '', '', '', '', '', '', [], 0);

  enrolledStudents: Student[] = [];
  student = new Student(
    '',
    '',
    0,
    '',
    '',
    '',
    '',
    '',
    '',
    0,
    '',
    '',
    '',
    '',
    0,
    ''
  );
  toAllowNext: boolean = false;
  disableAddCoverage: boolean = false;
  listCoverages = [] as Coverage[];
  count: number = 0;
  tableid: number = 0;

  displayedColumns: string[] = [
    'RowID',
    'Activity',
    'Demographics',
    'MarketingPlan',
    'Rate',
  ];
  year: number = 0;
  dataSource: any;
  subCats: any[] = [
    { value: 0, label: 'Football' },
    { value: 1, label: 'Test1' },
    { value: 2, label: 'Test2' },
  ];
  constructor(
    private enrolService: EnrolService,
    private locationService: LocationService,
    private myCoverageService: MyCoverageService,
    private authService: AuthenticationService,
    private elRef: ElementRef,
    private cdRef: ChangeDetectorRef,
    private stepService: StepperService,
    private stepper: MatStepper,
    private notify: NotificationService,
    private fiscalYearService: FiscalYearService
  ) {
    this.currentYear = this.fiscalYearService.getFiscalYearStart().toString();
    this.year = this.fiscalYearService.getFiscalYearStart();
    this.disableAddCoverage = false;
  }

  ngOnInit() {
    this.enrolService.setEnrolledStudentData(
      new Enroll('', '', '', '', '', '', '', [], 0)
    );

    this.myCoverageService.studentType.subscribe((value) => {
      if (value != '') {
        this.newOrSavedStudent = value;
      } else this.newOrSavedStudent = 'New';
    });

    this.authService.loggedInUserId.subscribe(
      (value) => (this.loggedInUserId = value)
    );

    this.getCoverageStates();

    if (this.newOrSavedStudent == 'Saved') {
      this.getEnrolledStudentDetails();
    }

    this.enrolService.enrollData.subscribe((value) => {
      const studentDetails: StudentCoverage[] = [];
      if (value?.studentDetail && value.studentDetail.length > 0) {
        value.studentDetail.forEach((studentCoverage: StudentCoverage) => {
          studentDetails.push({ ...studentCoverage });
        });
      }

      this.enrollData = new Enroll(
        value.firstname,
        value.lastname,
        value.email,
        value.streetaddress,
        value.city,
        value.state,
        value.zip,
        studentDetails,
        value.payableAmount
      );
    });
  }
  ngOnDestroy(): void {
    this.enrollData.studentDetail = [];
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  clearControls() {
    this.studFirstName = '';
    this.selectedState = '';
    this.selectedDistrict = '';
    this.selectedCampus = '';
    this.selectedCampusType = '';
    this.districts = [];
    this.campus = [];
    this.selectedCampusType = '';
    this.selectedStudent = '';
  }

  checkIfCoverageIsSelected() {
    let noCovSelected: boolean[] = [];
    if (this.selectedCampus && this.selectedCampusType) {
      this.enrollData.studentDetail.forEach((s) => {
        let cov = s.coverageList.filter((x) => x.isChecked);
        if (cov.length > 0) noCovSelected.push(false);
        else noCovSelected.push(true);
      });
    }
    if (noCovSelected.some((x) => x)) this.disableAddCoverage = true;
    else this.disableAddCoverage = false;
  }

  getCoverageStates() {
    this.locationService.getCoverageStates().subscribe({
      next: (result: any) => {
        result.body.forEach((item: { name: string; state: string }) => {
          let data = {
            name: item.name,
            state: item.state,
            postalAbbreviation: '',
            countryCode: '',
            capitalCity: '',
            standardAbbreviation: '',
          } as State;

          this.states.push(data);
        });
      },
      error: (err: any) => {
        console.log(err);
      },
    });
  }

  getDistricts() {
    this.districts = [];
    this.selectedDistrict = '';
    this.campus = [];
    this.selectedCampus = '';
    this.selectedCampusType = '';
    this.locationService.getDistricts(this.selectedState).subscribe({
      next: (result: any) => {
        result.body.forEach((item: { policyNo: number; client: string }) => {
          let data = {
            policyNo: item.policyNo,
            client: item.client,
          } as District;

          this.districts.push(data);
        });
      },
      error: (err: any) => {
        console.log(err);
      },
    });
  }

  getCampus() {
    this.campus = [];
    this.selectedCampus = '';
    this.selectedCampusType = '';
    this.enrolService.getCampus(this.selectedDistrict).subscribe({
      next: (result: any) => {
        result.body.forEach(
          (item: { campusID: number; campusName: string }) => {
            let data = {
              campusID: item.campusID,
              campusName: item.campusName,
            } as Campus;
            this.campus.push(data);
          }
        );
      },
      error: (err: any) => {
        console.log(err);
      },
    });
  }

  getCoverages(event?: string) {
    this.showForm = false;
    if (this.selectedStudent) {
      this.student = this.enrolledStudents.find(
        (s) => s.studentID.toString() == this.selectedStudent
      )!;
      this.selectedCampus = this.student.campus.toString()!;
      this.selectedCampusType = this.student.levelCode!;
      this.studFirstName = this.student.firstName!;
      this.selectedState = this.student.statecode!;
      this.selectedStateName = this.student.state!;
      this.selectedDistrict = this.student.schoolDistrict!;
    }

    let temp: StudentCoverage[];
    this.listCoverages = [];

    this.enrolService
      .getCoverages(this.selectedCampus, this.selectedCampusType)
      .subscribe({
        next: (result: any) => {
          result.body.forEach((item: Coverage) => {
            let data = {
              rowID: item.rowID,
              activity: item.activity,
              demographics: item.demographics,
              marketingPlan: item.marketingPlan,
              rate: item.rate,
              paymentType: item.paymentType,
              planType: item.planType,
              grouping: item.grouping,
              isChecked: false,
              totalRate: 0,
              paid: 0,
              effectiveDate: item.effectiveDate,
              terminationDate: item.terminationDate,
              policyNo: item.policyNo,
              voluntaryPolicyNo: item.voluntaryPolicyNo,
              inputType: '',
              subCategories: item.subCategories,
            } as Coverage;

            this.listCoverages.push(data);
          });

          this.validateCoverageData();

          let checkboxData = this.listCoverages.filter(
            (c) => c.inputType == 'checkbox'
          );

          if (checkboxData.length > 0) {
            this.count = 1;
          } else this.count = 0;
          if (event == 'Saved') this.addStudent(event);
          else this.addStudent();
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }

  noNameCount: number = 1;

  addStudent(event?: any) {
    this.toAllowNext = false;
    this.disableAddCoverage = true;

    if (this.enrollData.studentDetail.length == 0) this.noNameCount = 1;

    let name = '';
    if (this.noNameCount < 10) name = 'Student 0' + this.noNameCount++;
    else name = 'Student ' + this.noNameCount++;

    let studCoverage = {
      firstName: this.studFirstName != '' ? this.studFirstName : name,
      lastName: '',
      studentId: '',
      dob: new Date(),
      grade: '',
      phone: 0,
      stateCode: this.selectedState,
      state: this.getSelectedState(this.selectedState),
      school: this.getSelectedSchool(this.selectedDistrict),
      campusId: parseInt(this.selectedCampus),
      campusTypeId: this.selectedCampusType,
      coverageYear: this.currentYear,
      coverageList: this.listCoverages.filter((c) => c.inputType == 'radio'),
      campusName: this.getSelectedCampus(this.selectedCampus),
      campusType: this.getSelectedCampusType(this.selectedCampusType),
      coverageRate: 0,
      total: 0,
      groupName: '',
      addOnCoverageList: this.listCoverages.filter(
        (c) => c.inputType == 'checkbox'
      ),
    } as StudentCoverage;

    if (event == 'Saved') {
      studCoverage.lastName = this.student.lastName;
      studCoverage.state = this.student.state;
      studCoverage.school = this.student.schoolDistrictName;
      studCoverage.studentId = this.student.studentID.toString();
      studCoverage.grade = this.student.grade.toString();
      studCoverage.phone = parseInt(this.student.phone);
      studCoverage.dob = new Date(this.student.dob);
      studCoverage.ssn = this.student.ssn;
      studCoverage.campusName = this.student.campusName;
      this.student.schoolDistrict = this.selectedDistrict;
    }

    this.enrollData.studentDetail.push(studCoverage);
    this.tableid = this.enrollData.studentDetail.length - 1;

    for (let i = 0; i < this.enrollData.studentDetail.length; i++) {
      this.enrollData.studentDetail[i].groupName = 'table' + i;
    }

    this.checkIfCoverageIsSelected();
    this.myCoverageService.updateStudentCoverage(this.enrollData.studentDetail);
    this.clearControls();
  }

  validateCoverageData() {
    let nonHealthFound: boolean;
    let inputType = '';
    let inputName = '';
    let dentalAlone = '';

    this.listCoverages.forEach((cov) => {
      if (!cov.paymentType.toUpperCase().startsWith('Q')) {
        if (cov.paymentType.toUpperCase().startsWith('A'))
          cov.paymentType = '*Full annual pmt';
        else cov.paymentType = 'Bi-monthly pmts';

        if (cov.activity.toLowerCase().indexOf('inj') > -1) {
          cov.activity += ' [' + cov.paymentType + ']';
        }

        if (cov.planType.startsWith('1')) {
          //at school, 24 hr, inj & sickness
          inputType = 'radio';
        } else if (cov.planType.startsWith('2')) {
          //football, wrestling, boxing, etc
          inputType = 'radio';
        } else {
          inputType = 'checkbox';
        }
      }

      cov.inputType = inputType;
    });
  }

  getSelectedState(value: string): string {
    if (this.states && value) {
      let status: State | undefined = this.states.find((s) => s.state == value);
      if (status) return status.name;
    }
    return '';
  }

  getSelectedSchool(value: string): string {
    if (this.districts && value) {
      let dist: District | undefined = this.districts.find(
        (s) => s.policyNo.toString() == value
      );

      if (dist) return dist.client;
    }
    return '';
  }

  getSelectedCampus(value: string): string {
    if (this.campus && value) {
      let camp: Campus | undefined = this.campus.find(
        (s) => s.campusID.toString() == value || s.campusName == value
      );
      if (camp) return camp.campusName;
    }
    return '';
  }

  getSelectedCampusType(value: string): string {
    if (value == 'E' || value == 'Elementary') return 'Elementary';
    else if (value == 'M'|| value == 'Middle School') return 'Middle School';
    else if (value == 'H'|| value == 'High School') return 'High School';
    else if (value == 'O'|| value == 'College/Adult') return 'College/Adult';
    return '';
  }

  selectItem(element: Coverage, studCovr: StudentCoverage, event: any) {
    if (element.inputType == 'radio') {
      studCovr.coverageList.forEach((item) => {
        if (item.rowID == element.rowID) {
          item.isChecked = true;
        } else {
          item.isChecked = false;
        }
      });
    } else if (element.inputType == 'checkbox') {
      if (event.checked) {
        element.isChecked = true;
      } else if (!event.checked) {
        element.isChecked = false;
        this.payableAmount -= element.rate;
      }
    }

    this.payableAmount = 0;
    this.enrollData.studentDetail.forEach((student) => {
      student.terminationDate = student.coverageList.filter(
        (c) => c.isChecked
      )[0]?.terminationDate;
    });
    this.getPayableAmount();
  }

  getPayableAmount() {
    this.enrollData.payableAmount = 0;
    this.enrollData.studentDetail.forEach((cov) => {
      let total = 0;
      let selectedCoverages = cov.coverageList.filter((x) => x.isChecked);
      if (selectedCoverages.length > 0) {
        total += selectedCoverages[0].rate;
      }

      let selectedAddOnCoverages = cov.addOnCoverageList.filter(
        (x) => x.isChecked
      );
      if (selectedAddOnCoverages.length > 0) {
        selectedAddOnCoverages.forEach((a) => {
          total += a.rate;
        });
      }
      cov.coverageRate = total;
      cov.total = total;
      this.enrollData.payableAmount += cov.total;
    });
    let coverageChecked = this.enrollData.studentDetail[
      this.enrollData.studentDetail.length - 1
    ].coverageList.filter((x) => x.isChecked);
    let addOncoverageChecked = this.enrollData.studentDetail[
      this.enrollData.studentDetail.length - 1
    ].addOnCoverageList.filter((x) => x.isChecked);
    if (
      (coverageChecked && coverageChecked.length > 0) ||
      (addOncoverageChecked && addOncoverageChecked.length > 0)
    ) {
      this.toAllowNext = true;
      this.disableAddCoverage = false;
    } else {
      this.toAllowNext = false;
      this.disableAddCoverage = true;
    }
  }

  GotoStudentDetailsTab() {
    let radioChecked: boolean[] = [];
    let checkboxChecked: boolean[] = [];
    let subCatNotChecked: boolean[] = []
    this.enrollData.studentDetail.forEach((stud) => {
      radioChecked.push(stud.coverageList.some((x) => x.isChecked));
      checkboxChecked.push(stud.addOnCoverageList.some((x) => x.isChecked));
      subCatNotChecked.push(stud.coverageList.some(x=> x.subCategories?.length>0 && (stud.campusTypeId == 'H' || stud.campusTypeId == 'M') && (x.selectedSubCats == undefined || x.selectedSubCats?.length==0) && x.isChecked))
    });
    if (
      !radioChecked.every((x) => x == true) &&
      checkboxChecked.some((x) => x == true)
    ) {
      this.notify.showWarning('Add ons can not be purchased alone.');
      return;
    }
    else if(subCatNotChecked.some(x => x==true)){
      this.notify.showWarning('Select All Sports/Activities student performs');
      return;
    }
     else if (radioChecked.every((x) => x == true)) {
      this.stepService.updateStepStatus(1, true);
      this.getPayableAmount();
      this.enrolService.setEnrolledStudentData(this.enrollData);
      this.stepper.next();
    }
  }

  getEnrolledStudentDetails() {
    this.enrolService.getEnrolledStudentDetails(this.loggedInUserId).subscribe({
      next: (result: any) => {
        result.body.forEach(
          (item: {
            firstName: string;
            lastName: string;
            campus: number;
            campusName: string;
            levelCode: string;
            grade: string;
            state: string;
            studentID: number;
            dob: string;
            phone: string;
            schoolDistrict: string;
            schoolDistrictName: string;
            stateCode: string;
            ssn: string;
            accountId: number;
          }) => {
            let data = {
              firstName: item.firstName,
              lastName: item.lastName,
              campus: item.campus,
              campusName: item.campusName,
              levelCode: item.levelCode,
              state: item.state,
              statecode: item.stateCode,
              schoolDistrict: item.schoolDistrict,
              schoolDistrictName: item.schoolDistrictName,
              grade: item.grade,
              studentID: item.studentID,
              dob: item.dob,
              phone: item.phone,
              isStudentCovered: '',
              ssn: item.ssn,
              accountId: item.accountId,
              campusType: item.levelCode,
            } as Student;

            this.enrolledStudents.push(data);
          }
        );
      },
      error: (err: any) => {
        console.log(err);
      },
    });
  }

  deleteStudent(student: StudentCoverage, index: number) {
    this.payableAmount -= student.coverageRate;
    this.enrollData.studentDetail.splice(index, 1);
    this.disableAddCoverage = false;
    if(this.enrollData.studentDetail.length==0)  this.showForm=true;
    this.getPayableAmount();
    // this.enrollData.payableAmount = 0;
  }
  getDisableAddCovg() {
    if (
      this.newOrSavedStudent === 'New' &&
      this.selectedState?.trim().length > 0 &&
      this.selectedDistrict?.trim().length > 0 &&
      this.selectedCampus?.trim().length > 0 &&
      this.selectedCampusType?.trim().length > 0 &&
      !this.disableAddCoverage
    )
      return false;
    if (
      this.newOrSavedStudent === 'Saved' &&
      this.selectedStudent?.trim().length > 0 &&
      !this.disableAddCoverage
    )
      return false;
    return true;
  }
}
