<div class="container-fluid">
  <div class="row headerRow" >
    <div class=" col-xl-6 col-lg-6 col-md-8 col-sm-6 col-xs-4 " id="logos" >
      <img src="./assets/images/HSRlogo.png" class="hsrLogo" alt="Unable to load image">
      <img src="./assets/images/K12_header.gif" class="klogo" alt="Unable to load image">
    </div>
    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-6 col-xs-8">
      <div *ngIf="!isLoggedIn" id="formDiv">
        <form #loginForm="ngForm" id="loginForm">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 loginColumn">
              <div class="loginEmail">
                <input class="email-input" type="email" [(ngModel)]="login.email" required email maxlength="99"
                  placeholder="Email Address" name="email" id="email" aria-label="Email Address" aria-required="true" #email="ngModel">
                <svg class="mailBox" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                  fill="none" role="none">
                  <path
                    d="M2.50001 4.16665L8.97677 9.20413C9.57862 9.67224 10.4214 9.67224 11.0232 9.20413L17.5 4.16665M4.33334 16.6666H15.6667C16.6001 16.6666 17.0668 16.6666 17.4233 16.485C17.7369 16.3252 17.9919 16.0702 18.1517 15.7566C18.3333 15.4001 18.3333 14.9334 18.3333 14V5.99998C18.3333 5.06656 18.3333 4.59985 18.1517 4.24333C17.9919 3.92973 17.7369 3.67476 17.4233 3.51497C17.0668 3.33331 16.6001 3.33331 15.6667 3.33331H4.33334C3.39992 3.33331 2.93321 3.33331 2.57669 3.51497C2.26308 3.67476 2.00812 3.92973 1.84833 4.24333C1.66667 4.59985 1.66667 5.06656 1.66667 5.99998V14C1.66667 14.9334 1.66667 15.4001 1.84833 15.7566C2.00812 16.0702 2.26308 16.3252 2.57669 16.485C2.93321 16.6666 3.39992 16.6666 4.33334 16.6666Z"
                    stroke="#5F6D7E" stroke-width="1.5" />
                </svg>
              </div>

              <div class="loginPwd">
                <input class="password-input" type="password" [(ngModel)]="login.password" required minlength="8"
                  placeholder="Password" id="password" aria-label="Password" aria-required="true" (change)="resetError()" name="password" #password="ngModel">
                <svg class="lock-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                  fill="none" role="none">
                  <path
                    d="M6.66666 7.5V5.83333C6.66666 3.99238 8.15904 2.5 9.99999 2.5C11.8409 2.5 13.3333 3.99238 13.3333 5.83333V7.5M11.6667 12.5H8.33332M9.99999 14.1667V10.8333M6.83332 17.5H13.1667C14.1001 17.5 14.5668 17.5 14.9233 17.3183C15.2369 17.1586 15.4919 16.9036 15.6517 16.59C15.8333 16.2335 15.8333 15.7668 15.8333 14.8333V10.1667C15.8333 9.23325 15.8333 8.76654 15.6517 8.41002C15.4919 8.09641 15.2369 7.84144 14.9233 7.68166C14.5668 7.5 14.1001 7.5 13.1667 7.5H6.83332C5.8999 7.5 5.43319 7.5 5.07667 7.68166C4.76307 7.84144 4.5081 8.09641 4.34831 8.41002C4.16666 8.76654 4.16666 9.23325 4.16666 10.1667V14.8333C4.16666 15.7668 4.16666 16.2335 4.34831 16.59C4.5081 16.9036 4.76307 17.1586 5.07667 17.3183C5.43319 17.5 5.8999 17.5 6.83332 17.5Z"
                    stroke="#919BA7" stroke-width="1.5" stroke-linecap="round" />
                </svg>
              </div>


              <div class="appButtons">
                <div><button type="button" type="submit" class="login" id="loginBtn" (click)="authenticate(login)">Login</button>
                </div>
                <div><a class="fpassword" (click)="OpenDialog()" id="fpasswordLink" role="button">Forgot password?</a></div>
              </div>
            </div>
          </div>
          <div class="row">
            <div *ngIf="showEmailError" class="emailErrormsg">{{errorMessage}}</div>
            <div *ngIf="showEmailError" class="pwdError">{{pwderrorMessage}}</div>
          </div>
        </form>
      </div>
      <div class="row" *ngIf="isLoggedIn">
        <div class="col-lg-6 col-ms-4 col-sm-4">
        </div>
        <div class="col-lg-6 col-ms-4 col-sm-8">
          <div class="container dropdown" [matMenuTriggerFor]="menu" id="menuTrigger">
            <div><button class="fullnameLogo" type="button">
                {{fullname|shortName:2}}
              </button></div>
            <div class="fullname">{{fullname}}</div>
            <div style="margin-top: 0.9rem;"><mat-icon svgIcon="down"></mat-icon></div>
          </div>
          <mat-menu #menu="matMenu" xPosition="before">
            <button mat-menu-item routerLink="/myprofile" id="profileBtn">
              <span>My Profile</span>
            </button>
            <button mat-menu-item routerLink="/myprofile/change-password" id="changePassBtn">
              <span>Change Password</span>
            </button>
            <button mat-menu-item (click)="logout(login)" id="logoutBtn">
              <span>Logout</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <nav class="navbar navbar-expand-lg" id="appNav">
      <div class="container-fluid">
        <button class="navbar-toggler" id="collapsibleNavbarToggle" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="collapsibleNavbar">
          <ul class="navbar-nav">
            <li class="nav-item" [routerLinkActive]="['active']" id="homeLink" routerLink="/home" *ngIf="!isLoggedIn">
              <a class="nav-link" role="button">Home</a>
            </li>
            <li class="nav-item" [routerLinkActive]="['active']" id="ratesLink" routerLink="/rates">
              <a class="nav-link" role="button">Check Rates</a>
            </li>
            <li class="nav-item" [routerLinkActive]="['active']" id="enrollLink" routerLink="/enroll" *ngIf="!isLoggedIn">
              <a class="nav-link" role="button">Enroll Now</a>
            </li>
            <li class="nav-item" [routerLinkActive]="['active']" id="coverageLink" routerLink="/coverages" *ngIf="isLoggedIn">
              <a class="nav-link" role="button">My Coverages</a>
            </li>
            <li class="nav-item" [routerLinkActive]="['active']" id="faqLink" routerLink="/faqs">
              <a class="nav-link" role="button">FAQs</a>
            </li>
            <li class="nav-item" [routerLinkActive]="['active']" id="contactLink" routerLink="/contact-us">
              <a class="nav-link" role="button">Contact Us</a>
            </li>
          </ul>
        </div>
      </div>

    </nav>
  </div>


</div>
<div class="row">
  <router-outlet></router-outlet>
</div>

<ngx-spinner name="default" type="ball-scale-multiple"></ngx-spinner>
