import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StepperService {
  private stepStatus = new BehaviorSubject<boolean[]>([false, false, false]);
  currentStepStatus = this.stepStatus.asObservable();

  updateStepStatus(index: number, status: boolean): void{
    const currentStatus =this.stepStatus.getValue();
    currentStatus[index]=status;
    this.stepStatus.next(currentStatus);
  }
}
