import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {OnInit, Component, Inject } from '@angular/core';
import { Forgot } from 'src/app/models/forgot.model';
import { ForgotService } from 'src/app/services/forgot/forgot.service';
import { NotificationService } from 'src/app/services/notification/notification.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent  implements OnInit{


  message: string = "";
  isMessage: boolean = false;
  emailAddress: string = "";
  isEmailEmpty : boolean = false;
  userEmail: string = '';
  forgot = new Forgot("");


 
  constructor(private dialogRef: MatDialogRef<ForgotPasswordComponent>,
    private forgotService: ForgotService, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notificationService: NotificationService) {
    this.emailAddress = data;
  }

  ngOnInit() {
    this.isEmailEmpty = this.isEmailAddressNullOrEmpty();
  }
  closeDialog(){
    this.dialogRef.close();
  }
  onSubmit(){
    this.getForgot();
  }
  getForgot() {
    
    this.forgotService.getForgot(this.emailAddress).subscribe({
      
      next: (result: any) => {
        if(result.body == "Success"){
          this.isMessage=true;

          this.message = "A temporary password has been sent to your Email Address.";
          this.notificationService.showSuccess(this.message);
          this.dialogRef.close();
        }
      
      },
      error: (err: any) => {
        console.log(err);
      }
      
    });
  }
  isEmailAddressNullOrEmpty() : boolean {
    return !this.emailAddress || this.emailAddress.trim() === '';
  }

}

