import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { Enroll } from 'src/app/models/Enroll.model';
import { Coverage } from 'src/app/models/enrol/coverage.model';
import { StudentCoverage } from 'src/app/models/enrol/studentcoverage.model';
import { Login } from 'src/app/models/login.model';
import { PostPayment, ShoppingCart } from 'src/app/models/payment/post-payment.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EnrolService } from 'src/app/services/enrol/enrol.service';
import { PaymentService } from 'src/app/services/payment.service';
import { StepperService } from 'src/app/services/stepper/stepper.service';

@Component({
  selector: 'app-review-payment',
  templateUrl: './review-payment.component.html',
  styleUrls: ['./review-payment.component.scss']
})
export class ReviewPaymentComponent implements OnInit,OnDestroy {

  enrollData = new Enroll("", "", "", "", "", "", "", [], 0, 0);
  coverage = new Coverage(0,0, "", "", "", 0, "", "", "", false, 0, 0, "", "", "", "", "");
  loginData = new Login("", "", "", "", "", "", "", "", "", "");
  cardHolderName = "";
  initialPayableAmount: number = 0;
  showPaymentError: boolean = false;
  ErrorMessage: string = '';
  enrolDataSubscription !: Subscription ;

  constructor(private enrollService: EnrolService,
              private authService: AuthenticationService,
              private paymentService : PaymentService,
    private changeDetector: ChangeDetectorRef,
    private spinner:NgxSpinnerService,
              private stepService: StepperService) {

  }
  ngOnInit() {


    this.authService.loggedInUser.subscribe((res) => {
      if (res?.email) {
        this.loginData = res;
      }
    });
    this.enrolDataSubscription   =
    this.enrollService.enrollData.subscribe((value) => {
      const studentDetails: StudentCoverage[] = [];
      if(value?.studentDetail && value.studentDetail.length > 0){
        value.studentDetail.forEach((studentCoverage: StudentCoverage)=>{
          studentCoverage.coverageList.forEach(coverage => {
            coverage.displaySubCat = null;
            if(coverage.isChecked && coverage.selectedSubCats?.length > 0){
              coverage.displaySubCat = coverage.selectedSubCats?.length > 1 ? '(Multiple)': '('+coverage.subCategories.filter(c => c.choiceID == coverage.selectedSubCats[0])[0].choiceName+')'
            }
          })
          studentDetails.push({...studentCoverage});
        })
      }
      this.initialPayableAmount = value.payableAmount;
      this.enrollData =  new Enroll(value.firstname,value.lastname,value.email,value.streetaddress,value.city,value.state,value.zip,studentDetails,value.payableAmount,0);


      this.enrollData.firstname = this.enrollData.firstname == '' ? this.loginData.firstName : this.enrollData.firstname;
      this.enrollData.lastname = this.enrollData.lastname == '' ? this.loginData.lastName : this.enrollData.lastname;
      this.enrollData.streetaddress = this.enrollData.streetaddress == '' ? this.loginData.address1 : this.enrollData.streetaddress;
      this.enrollData.email = this.enrollData.email == '' ? this.loginData.email : this.enrollData.email;
      this.enrollData.state = this.enrollData.state == '' ? this.loginData.stateCode : this.enrollData.state;
      this.enrollData.city = this.enrollData.city == '' ? this.loginData.city : this.enrollData.city;
      this.enrollData.zip = this.enrollData.zip == '' ? this.loginData.zip : this.enrollData.zip;

      if (this.enrollData?.studentDetail) {
        this.getPayableAmount();
        this.cardHolderName = this.enrollData.firstname + " " + this.enrollData.lastname;
      }

    });


  }

  ngOnDestroy(){
    this.enrolDataSubscription.unsubscribe();
  }

  getPayableAmount() {
    if (this.enrollData?.studentDetail){
      let policyNo = "";
      let  postPayment = new PostPayment("",0,0,[]);
      postPayment.email = this.enrollData.email;
      postPayment.shoppingCarts = [];
      this.enrollData.studentDetail.forEach(stud => {
        let total = 0;

        let checkedRadio = stud.coverageList.filter(x => x.isChecked);
        if (checkedRadio && checkedRadio.length > 0) {
          checkedRadio.forEach(a => {
            let shoppingCart = new ShoppingCart(stud.studentId,a.rowID,a.selectedSubCats?.length > 0 ? a.selectedSubCats.join(','):'', stud.ssn);
            total += a.rate;
            postPayment.shoppingCarts.push(shoppingCart);
            policyNo = a.policyNo;
          });
        }
        let additionalCoverages = stud.addOnCoverageList?.filter(x => x.isChecked);
        if (additionalCoverages && additionalCoverages.length > 0) {
          additionalCoverages.forEach(a => {
            let shoppingCart = new ShoppingCart(stud.studentId,a.rowID,a.selectedSubCats?.length > 0 ? a.selectedSubCats.join(','):'', stud.ssn);
            total += a.rate;
            postPayment.shoppingCarts.push(shoppingCart);
          });
        }
        stud.total = total;
        postPayment.subTotal = this.enrollData.payableAmount;
        //this.authService.postPayDetails.next(payment);

      });

      this.getProcessingFee(policyNo, postPayment.subTotal, postPayment.shoppingCarts.length, postPayment);

    }

  }

  getProcessingFee(policyNo: string, subTotal: number, cartLength: number, payment: PostPayment){

    let studCount = this.enrollData.studentDetail.length;
    this.enrollService.getProcessingFee(parseInt(policyNo), subTotal, cartLength).subscribe({
      next: (result: any) => {
        this.enrollData.processingFee = result;
        this.enrollData.payableAmount = this.initialPayableAmount + this.enrollData.processingFee;
        payment.feeAmount = this.enrollData.processingFee;
        this.authService.postPayDetails.next(payment);
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  getCoverageYear(year:string){
      return year + '-' + (parseInt(year)+1).toString();
  }
  showError(message:string){
    this.showPaymentError = true;
    this.ErrorMessage = message;
    this.spinner.hide('payment');
      this.changeDetector.detectChanges();
  }
}
