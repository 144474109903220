import { Component, OnDestroy, OnInit} from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { IDCard } from 'src/app/models/coverages/idcard.model';
import { StudentInfo } from 'src/app/models/coverages/studentInfo.model';
import { MyCoverageService } from 'src/app/services/coverages/mycoverage.service';

@Component({
  selector: 'app-id-card',
  templateUrl: './id-card.component.html',
  styleUrls: ['./id-card.component.scss']
})
export class IdCardComponent implements  OnInit, OnDestroy {
  IDCardYear:number = (new Date).getFullYear();
  IDCardStudent = {} as IDCard;
  constructor(private coverageService: MyCoverageService){}

  ngOnInit(){
    this.IDCardStudent = new IDCard(new StudentInfo("", "", "", "", 0, 0, "", "", [], 0,'','',''), "", false);

      this.coverageService.getUserData()
      .subscribe({
       next:(data:IDCard)=>{

          if(data.isDownload){
            this.IDCardStudent = data;
            let year = this.IDCardStudent.student.coverages[0].policyNo.toString().substring(0,4);
            this.IDCardYear = Number(year);
          }
       }
      });
  }

ngOnDestroy(): void {
  if(this.IDCardStudent.isDownload==true){
    this.downloadPDF(document.getElementById('idcard'));
  }
}

  downloadPDF(divRef:any){
    html2canvas(divRef, {scale: 2})
      .then((canvas) => {
        const contentData = canvas.toDataURL("image/png");
        var doc = new jsPDF('p', 'mm', [200,250]);
        var position = 0;
        var imgWidth = 208;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        doc.addImage(contentData, "PNG", 0, position, imgWidth, imgHeight, '', 'FAST');
        return doc;
      })
      .then((doc:any) => {
        let filename = this.IDCardStudent.student.firstName+'_'+this.IDCardStudent.student.lastName+'_'+this.IDCardStudent.student.studentID+'.pdf';
        doc.save(filename)}
        );
  }
}
