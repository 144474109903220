<div class="container-fluid" style="padding: 0;">
    <div class="row">
            <div class="children_running">
              <a href="javascript:void(null)" (click)="scrollAuto('main')" class="skip bg-danger text-light">Skip to main content</a>
                <!-- <img src="assets\images\children_running.jpg" alt="homepage" class="img-fluid imgContainer"> -->
                <div class="card">
                    <div class="card-body">
                        <div >
                            <p class="card-title" role="heading">Are You Ready To Secure
                                Your Child’s Future?</p>
                        </div>
                        <div >
                            <p class="card-content">Welcome to the ultimate K12 Student Insurance portal,
                                where we prioritize your child’s safety and well-being from
                                Kindergarten to 12th grade.</p>
                        </div>
                        <div class="buttons">
                            <button type="button" class="rateBtn" (click)="checkRate()">Check Rates</button>
                            <button type="button" class="enrolBtn" (click)="enrollnow()">Enroll Now</button>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</div>
<div class="container" style="margin-top: 2rem;">
  <main [attr.id]="'main'" role="main"></main>
  <div class="d-flex flex-wrap justify-content-center px-2">
    <div class="mx-3 mb-3" style="width: 500px;">
      <p class="visitorheader" role="heading">New Visitors: </p>
      <div class="visitorsList">
          <ol type="1">
              <li>Check Rates to determine if your school is covered</li>
              <li>Enroll Now to add students and purchase coverage</li>
          </ol>
      </div>
    </div>
    <div class="mx-3 mb-3" style="width: 500px;">
      <p class="visitorheader" role="heading">Returning Account Holders:</p>
      <div class="holdersList">
          <ol type="1">
              <li>Login to your account</li>
              <li>View existing coverage</li>
              <li>Print Student ID cards</li>
              <li>Purchase additional coverage</li>
          </ol>
      </div>
    </div>
  </div>
    <!-- <div class="row" style="margin-bottom: 3rem;">
        <div class="col-lg-12 col-md-6 col-sm-6">
            <p class="homeText">User IDs and Passwords must be renewed every school year. If you registered online for
                last school year ({{previousYear}} - {{currentYear}}),
                you need to re-register for this school year ({{currentYear}} - {{nextYear}}).</p>
        </div>
    </div>
    <div class="row g-3">
        <div class="col-lg-6 col-md-4 col-sm-3" style="margin-bottom: 3.5rem;">
            <div>
                <p class="visitorheader">New Visitors: </p>
                <div class="visitorsList">
                    <ol type="1">
                        <li>Browse Rates</li>
                        <li >Open New Account - Once you have determined that your school is
                            covered,
                            you'll need to open a new account to add students & purchase coverage [New Coverage, or
                            New Enrollment].
                        </li>
                    </ol>
                </div>
            </div>

        </div>
        <div class="col-lg-6 col-md-4 col-sm-3">
            <p class="visitorheader">Returning Account Holders:</p>
            <div class="holdersList">
                <ol type="1">
                    <li>MyAccount Logon</li>
                    <li>Maintain Student Data</li>
                    <li>Maintain Insurance Coverage</li>
                </ol>
            </div>
        </div>
    </div> -->
</div>
