import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EnrolComponent } from './enrol.component';
import { AddStudentdetailsComponent } from './add-studentdetails/add-studentdetails/add-studentdetails.component';
import { ReviewPaymentComponent } from './review-payment/review-payment.component';
import { PaymentComponent } from './payment/payment/payment.component';

const routes: Routes = [{ path: '', component: EnrolComponent },
{ path: 'student', component: AddStudentdetailsComponent },
{ path: 'payment', component: ReviewPaymentComponent },
{path: 'paymentsuccess', component:PaymentComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EnrolRoutingModule { }
