import { StudentCoverage } from "./enrol/studentcoverage.model";

export class Enroll {
    firstname: string = '';
    lastname: string = '';
    email: string = '';
    streetaddress: string = '';
    city: string = '';
    state: string = '';
    zip: string = '';
    studentDetail: StudentCoverage[] = [];
    payableAmount: number = 0;
    processingFee: number = 0;

    constructor(firstname: string, lastname: string, email: string, streetaddress: string, city: string, state: string, zip: string, studentDetail: StudentCoverage[], payableAmount: number, processingFee: number = 0) {
        this.firstname = firstname;
        this.lastname = lastname;
        this.email = email;
        this.streetaddress = streetaddress;
        this.city = city;
        this.state = state;
        this.zip = zip;
        this.studentDetail = studentDetail;
        this.payableAmount = payableAmount;
        this.processingFee = processingFee;
    }
}
