export class Login {
    accountID: number = 0;
    email: string = "";
    password: string = "";
    firstName: string = "";
    lastName: string = "";
    accountId: string = "";
    token: string = "";
    address1: string = "";
    address2: string = "";
    stateCode: string = "";
    city: string = "";
    zip: string = "";

    constructor(email: string, password: string, firstName: string, lastName: string, accountId: string, address1: string, address2: string, stateCode: string, city: string, zip: string) {
        this.email = email;
        this.password = password;
        this.firstName = firstName;
        this.lastName = lastName;
        this.accountId = accountId;
        this.address1 = address1;
        this.address2 = address2;
        this.stateCode = stateCode;
        this.city = city;
        this.zip = zip;
    }
}
