<div class="welcome">
    <div>
        <button (click)="closeDialog()" class="closeHeader" id="closeBtn">
            <img src="./assets/images/close cross.svg" alt="Unable to load image">
        </button>
    </div>
    <div class="content row">
        <div class="header">
            Welcome back !
        </div>
        <div class="Form">
            <div class="loginForm mt-2 mb-2">
                <label>Email Address</label>
                <input type="email" id="email" placeholder="Email Address" [(ngModel)]="login.email" disabled/>
            </div>
            <div class="loginForm mb-2">
                <label>Password</label>
                <input type="password" id="password" placeholder="Password" [(ngModel)]="login.password"/>
            </div>
            <div class="row error" *ngIf="showEmailError">
                {{errorMessage}}
            </div>
            <div class="mb-2 mt-2">
                <a class="fpassword" id="fpassword"  (click)="OpenForgotPasswordDialog()">Forgot Password?</a>
            </div>
            <div>
                <button class="logIn" id="loginBtn" type="submit" (click)="authenticate(login)">Log In</button>
            </div>
        </div>
        
    </div>
</div>