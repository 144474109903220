import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnrolRoutingModule } from './enrol-routing.module';
import { EnrolComponent } from './enrol.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { HttpClientModule } from '@angular/common/http';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { AddCoveragedetailsComponent } from './add-coveragedetails/add-coveragedetails.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table'
import {MatRadioModule} from '@angular/material/radio';
import { AddStudentdetailsComponent } from './add-studentdetails/add-studentdetails/add-studentdetails.component';
import { MatDialogModule } from '@angular/material/dialog';
import { UserRegistrationComponent } from './user-registration/user-registration.component';
import { ReviewPaymentComponent } from './review-payment/review-payment.component';
import { CheckPasswordDirective } from 'src/app/directive/directives/check-password.directive';
import { BuyMorecoveragesComponent } from './buy-morecoverages/buy-morecoverages.component';
import {MatCheckboxModule} from '@angular/material/checkbox'
import { WelcomeBackComponent } from './welcome-back/welcome-back.component';
import { InstantPaymentComponent } from 'src/app/core/components/instant-payment/instant-payment.component';
import { PaymentComponent } from './payment/payment/payment.component';
import { StudentidValidatorDirective } from 'src/app/directive/directives/studentid-validator.directive';
import { AuthorizenetPaymentModule } from 'src/app/core/components/authorizenet/authrizenet-payment/authorizenet-payment.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { EmptyStringValidatorDirective } from 'src/app/directive/directives/empty-string-validator.directive';
import { CoveragesModule } from '../coverages/coverages.module';
import { MatSelectModule } from '@angular/material/select';
import { DOBValidatorDirective } from 'src/app/directive/directives/dob-validator.directive';

@NgModule({
  declarations: [
    EnrolComponent,
    AddCoveragedetailsComponent,
    AddStudentdetailsComponent,
    UserRegistrationComponent,
    ReviewPaymentComponent,
    CheckPasswordDirective,
    BuyMorecoveragesComponent,
    WelcomeBackComponent,
    InstantPaymentComponent,
    PaymentComponent,
    StudentidValidatorDirective,
    EmptyStringValidatorDirective,
    DOBValidatorDirective
  ],
  imports: [
    CommonModule,
    EnrolRoutingModule,
    MatStepperModule,
    MatIconModule,
    MatFormFieldModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatTableModule,
    MatRadioModule,
    MatDialogModule,
    MatCheckboxModule,
    MatStepperModule,
    AuthorizenetPaymentModule,
    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
    CoveragesModule,
    MatSelectModule,
  ],

  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false },
    }
  ],
  exports:[StudentidValidatorDirective]

})
export class EnrolModule { }
