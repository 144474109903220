import { Component , OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
constructor(private router: Router, private viewportScroller: ViewportScroller){
  if(this.currentMonthDate >= this.JulyOne){
    this.currentYear++;
  }
  this.previousYear = this.currentYear-1;
  this.nextYear = this.currentYear+1;
}
today = new Date();
currentYear:number =  this.today.getFullYear()-1;
JulyOne: string = '0701';
currentMonthDate :string = this.today.getMonth().toString()+this.today.getDate().toString();
previousYear:number = this.currentYear-1;
nextYear:number = this.currentYear+1;

  checkRate(){
    this.router.navigate(['/rates']);
  }

  enrollnow(){
    this.router.navigate(['/enroll']);
  }

  scrollAuto(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }
}




