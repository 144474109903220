export class Coverage {
    studentID?: number = 0;
    rowID: number = 0;
    activity: string = "";
    demographics: string = "";
    marketingPlan: string = "";
    rate: number = 0;
    paymentType: string = "";
    planType: string = "";
    grouping: string = "";
    isChecked: boolean = false;
    totalRate: number = 0;
    paid: number = 0.0;
    effectiveDate: string = "";
    terminationDate: string = "";
    policyNo: string = "";
    voluntaryPolicyNo: string = "";

    inputType: string = "";
    subCategories: any[] = [];
    selectedSubCats:any[] = [];
    displaySubCat: string | null = '';
    subCatNameString: string = '';
    constructor(studentID: number,rowID: number, activity: string, demographics: string, marketingPlan: string, rate: number, paymentType: string, planType: string,
        grouping: string, isChecked: boolean, totalRate: number, paid: number, effectiveDate: string, terminationDate: string, policyNo: string,
        voluntaryPolicyNo: string, inputType: string, subcategories:any[] = []) {
        this.studentID = studentID;
        this.rowID = rowID;
        this.activity = activity;
        this.demographics = demographics;
        this.marketingPlan = marketingPlan;
        this.rate = rate;
        this.paymentType = paymentType;
        this.planType = planType;
        this.grouping = grouping;
        this.isChecked = isChecked;
        this.totalRate = totalRate;
        this.paid = paid;
        this.effectiveDate = effectiveDate;
        this.terminationDate = terminationDate;
        this.policyNo = policyNo;
        this.voluntaryPolicyNo = voluntaryPolicyNo;
        this.inputType = inputType;
        this.subCategories = subcategories;
    }
}