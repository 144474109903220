export class AccountUpdate{
    prevFirstName: string = "";
    prevLastName: string = "";
    firstName: string = "";
    lastName: string = "";
    address1: string="";
    address2: string="";
    stateCode: string="";
    city: string="";
    zip: string="";
}
export class AccountAddress{
    address1: string;
    address2: string;
    stateCode: string;
    city: string;
    zip: string;
    constructor(){
        this.address1="";
        this.address2="";
        this.stateCode="";
        this.city="";
        this.zip="";
    }
}