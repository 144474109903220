import { Directive, Input } from '@angular/core';
import { AbstractControl, AsyncValidator,  NG_ASYNC_VALIDATORS,  } from '@angular/forms';
import { Observable,  debounceTime,of } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { StudentDetailsService } from 'src/app/services/studentdetails/student-details.service';

@Directive({
  selector: '[appStudentidValidator]',
  providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: StudentidValidatorDirective, multi: true }]
})
export class StudentidValidatorDirective implements AsyncValidator {
  @Input('studentFirstName') firstName:string = ''
  @Input('studentLastName') lastName:string = ''
  @Input('isAddScreen') isAddScreen: boolean = false;

  enteredStudentAccountID: number = 0; // logged in user accountID
  existingStudentAccountID: number = 0; // existing student's accountID

  constructor(
    private studentService: StudentDetailsService,
    private authService: AuthenticationService) {}

  validate(control: AbstractControl): Promise<{ [key: string]: any } | null> | Observable<{ [key: string]: any } | null> {

    if(control?.value && control.value != '')

    return new Promise((resolve,reject)=>{
      setTimeout(() =>{
      this.studentService.checkIfStudentExists(control.value).pipe(debounceTime(10)).subscribe(
        (res:any) => {
          if (res.body == null) {
            resolve(null);
          } else {
            const firstname = res.body.firstname;
            const lastname = res.body.lastname;

            this.existingStudentAccountID = res.body.accountID;
            this.authService.userDetail.forEach((e: any) => {
              this.enteredStudentAccountID = e.accountID;
            });

            if(
              !this.isAddScreen
              && this.enteredStudentAccountID === this.existingStudentAccountID
              && firstname.toLowerCase() == this.firstName.toLowerCase()
              && lastname.toLowerCase() == this.lastName.toLowerCase()
            ) {
              resolve(null);
            }
            resolve({ uniqueStudentId: true });
          }
        }
      )},100);
    })
    return of(null);
  }

  CheckStudentID(studentId: string) {
    this.studentService.checkIfStudentExists(studentId).subscribe({
      next: (result: any) => {
        let res = result.body;
        return res;
      }
    });
  }
}
