<div class="container-fluid" style="margin-top: 2.5rem;">
    <div class="row" style="display: flex;flex-wrap: wrap;">
        <div class="col-lg-3 col-md-3 col-sm-6 left-pane">
            <div class="text">
                <span class="fullnameLogo">{{shortName}}</span>
                <p class="fullname">{{userDetail.fullname}}</p>
                <p class="emailAddress">{{userDetail.emailId}}</p>
            </div>
            <br>
            <div class="tabs" tabindex="0">
                <div class="row" id="toggleGeneral" (click)="toggleGeneral()" tabindex="1" [ngClass]="{'clicked': isGeneral}">
                    <button class="text-start">
                        <mat-icon class="generalIcon" svgIcon="General" *ngIf="!isGeneral"></mat-icon>
                        <mat-icon class="generalIcon" svgIcon="GeneralClicked" *ngIf="isGeneral"></mat-icon>
                        <span [ngClass]="{'spanClicked': isGeneral}">General</span></button>
                </div>
                <div class="row" id="toggleStudentDetail" (click)="toggleStudentDetail()" tabindex="2" [ngClass]="{'clicked': isStudentDetails}">
                    <button [ngClass]="{'iconClicked': isStudentDetails}" class="text-start">
                        <mat-icon class="generalIcon" svgIcon="Student" *ngIf="!isStudentDetails"></mat-icon>
                        <mat-icon class="generalIcon"  svgIcon="StudentClicked" *ngIf="isStudentDetails"></mat-icon>
                        <span [ngClass]="{'spanClicked': isStudentDetails}">Student Details</span></button>
                </div>
                <div class="row" id="toggleChangePassword" (click)="toggleChangePassword()" tabindex="3"
                    [ngClass]="{'clicked': isChangePassword}">
                    <button [ngClass]="{'iconClicked': isChangePassword}" class="text-start">
                        <mat-icon class="generalIcon" svgIcon="ChangePassword" *ngIf="!isChangePassword"></mat-icon>
                        <mat-icon  class="generalIcon" svgIcon="ChangePasswordClicked" *ngIf="isChangePassword"></mat-icon>
                        <span [ngClass]="{'spanClicked': isChangePassword}">Change Password</span></button>
                </div>
            </div>
        </div>
        <div class="col-lg-8 col-md-8 col-sm-6">
            <!-- <router-outlet></router-outlet> -->
            <ng-container *ngIf="isGeneral">
                <app-general [userDetail]="userDetail"></app-general>
            </ng-container>
            <ng-container *ngIf="isStudentDetails">
                <app-student-details></app-student-details>
            </ng-container>
            <ng-container *ngIf="isChangePassword">
                <!-- changepassword -->
                <app-change-password></app-change-password>
            </ng-container>
        </div>
    </div>
</div>