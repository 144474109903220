import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { FiscalYearService } from 'src/app/services/fiscal-year.service';

@Component({
  selector: 'app-annual-maintenance',
  templateUrl: './annual-maintenance.component.html',
  styleUrls: ['./annual-maintenance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnualMaintenanceComponent {
  today = new Date();
  currentSchoolYear = this.fiscalYearService.getFiscalYearStart();
  nextSchoolYear = this.currentSchoolYear + 1;

  constructor(private fiscalYearService: FiscalYearService) {}


}
