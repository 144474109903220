<div id="enrolDiv "class="container ">
  <div class="row outer">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <mat-horizontal-stepper labelPosition="bottom" [linear]="false">
        <ng-template matStepperIcon="Coverage">
            <mat-icon svgIcon="Step1-disabled"></mat-icon>
        </ng-template>

        <ng-template matStepperIcon="StudDetails">
          <mat-icon svgIcon="Step2-disabled"></mat-icon>
        </ng-template>

        <ng-template matStepperIcon="Payment">
          <mat-icon svgIcon="Step3-disabled"></mat-icon>
        </ng-template>

        <ng-template matStepperIcon="edit" let-index="index">
          <ng-container [ngSwitch]="index">
            <mat-icon *ngSwitchCase="0" svgIcon="Step1-disabled"></mat-icon>
            <mat-icon *ngSwitchCase="1" svgIcon="Step2-disabled"></mat-icon>
            <mat-icon *ngSwitchCase="2" svgIcon="Step3-disabled"></mat-icon>
          </ng-container>
        </ng-template>
        <ng-template matStepperIcon="done" let-index="index">
          <ng-container [ngSwitch]="index">
            <mat-icon *ngSwitchCase="0" svgIcon="Step1-disabled"></mat-icon>
            <mat-icon *ngSwitchCase="1" svgIcon="Step2-disabled"></mat-icon>
            <mat-icon *ngSwitchCase="2" svgIcon="Step3-disabled"></mat-icon>
          </ng-container>
        </ng-template>

        <mat-step state="Coverage">
          <ng-template matStepLabel>
            <div class="stepper-label text-wrap">Coverage Details</div>
          </ng-template>
          <ng-template matStepContent>
            <app-add-coveragedetails *ngIf="!buyMoreCoverages"></app-add-coveragedetails>
            <app-buy-morecoverages *ngIf="buyMoreCoverages"></app-buy-morecoverages>
          </ng-template>
        </mat-step>

        <mat-step state="StudDetails" >
          <ng-template matStepLabel>
            <div class="stepper-label text-wrap">Student Details </div>
          </ng-template>
          <ng-template matStepContent>
            <app-add-studentdetails></app-add-studentdetails>

          </ng-template>
        </mat-step>

        <mat-step state="Payment" >
          <ng-template matStepLabel>
            <div class="stepper-label text-wrap">Review & Payment </div>
          </ng-template>
          <ng-template matStepContent>
              <app-review-payment></app-review-payment>
          </ng-template>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </div>
</div>
