import { Coverage } from "../enrol/coverage.model";

export class StudentInfo {
    firstName: string = "";
    lastName: string = "";
    state: string = "";
    dob: string = "";
    gradeID: number = 0;
    studentID: number = 0;
    phone: string = "";
    district: string = "";
    campusID: number = 0;
    campusType: string = "";
    coverages: Coverage[] = [];
    totalAmount: number = 0;
    isCollapse?:boolean = true;
    isExpiryCollapse?:boolean = true;
    schoolDistrictName: string = '';
    campusName: string = "";
    ssn: string = "";

    constructor(firstName: string, lastName: string, state: string, dob: string, gradeID: number, studentID: number, phone: string, district: string, coverages: Coverage[], totalAmount: number, campusName:string, schoolDistrictName:string, SSN: string) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.state = state;
        this.dob = dob;
        this.gradeID = gradeID;
        this.studentID = studentID;
        this.phone = phone;
        this.district = district;
        this.coverages = coverages;
        this.totalAmount = totalAmount;
        this.campusName = campusName;
        this.schoolDistrictName = schoolDistrictName;
        this.ssn = SSN
    }
}