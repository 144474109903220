import { OnInit, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
import { EnrolService } from 'src/app/services/enrol/enrol.service';
import { User } from 'src/app/models/User.model';
import { Login } from 'src/app/models/login.model';
import { StudentDetailsService } from 'src/app/services/studentdetails/student-details.service';
import { Student } from 'src/app/models/student.model';
import { AESEncryptDecryptService } from 'src/app/services/encryptDecrypt/aesencrypt-decrypt.service';
import { NgxSpinnerService  } from "ngx-spinner";

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.scss']
})

export class UserRegistrationComponent implements OnInit {

  passwordPattern = "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$";
  userData = new User;
  cpassword: string = ""
  constructor(private dialogRef: MatDialogRef<UserRegistrationComponent>,
    private authService: AuthenticationService,
    private enrolService: EnrolService,
    private studentDetailsService: StudentDetailsService,
    private route: Router,
    private AESEncryptDecryptService: AESEncryptDecryptService,
    private spinner: NgxSpinnerService) {

  }
  ngOnInit() {
    this.enrolService.enrollData.subscribe((value) => {

      this.userData.email = value.email;
      this.userData.firstName = value.firstname;
      this.userData.lastName = value.lastname;
      this.userData.address = value.streetaddress;
      this.userData.state = value.state;
      this.userData.city = value.city;
      this.userData.zip = value.zip;
    }

    )
  }
  closeDialog() {
    this.dialogRef.close();
  }
  UserRegister(userData: User) {
    this.spinner.show('default');
    //To-do: Encryption disabled for soft production, to be enabled later
    //userData.password = this.AESEncryptDecryptService.encrypt(userData.password);
    this.authService.registerUser(userData).subscribe((res: any) => {
      if (res?.status && res.body) {
        sessionStorage.setItem("jwt", res.body.jWTToken.token);
        this.authService.isAuthUser.next(true);
        this.authService.loggedInUser.next(new Login(res.body.email, "", res.body.firstName, res.body.lastName, res.body.accountID, res.body.address1, res.body.address2, res.body.stateCode, res.body.city, res.body.zip));
        this.authService.loggedInUserId.next(res.body.accountID);
        let user = new User();
        user.accountID = res.body.accountID;
        user.firstName = res.body.firstName;
        user.lastName = res.body.lastName;
        user.email = res.body.email;
        this.authService.setUserDetailModel(user);
        sessionStorage.setItem('loggedUser',JSON.stringify(new Login(res.body.email, "", res.body.firstName, res.body.lastName, res.body.accountID, res.body.address1, res.body.address2, res.body.stateCode, res.body.city, res.body.zip)))

        this.spinner.hide('default');
        this.dialogRef.close("registerUser");
      } else {
        console.log('Failed to register account!');
        this.spinner.hide('default');
        this.dialogRef.close("registerUser");
      }
    });
  }
}
