import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyProfileRoutingModule } from './my-profile-routing.module';
import { MyProfileComponent } from './my-profile.component';
import { MatTabsModule} from '@angular/material/tabs';
import {  MatIconModule} from '@angular/material/icon';
import { GeneralComponent } from './general/general.component';
import { StudentDetailsComponent } from './student-details/student-details/student-details.component';
import { MatTableModule } from '@angular/material/table';
import { AddStudentDialogComponent } from './dialogs/add-student-dialog/add-student-dialog.component';
import { EditStudentDialogComponent } from './dialogs/edit-student-dialog/edit-student-dialog.component';
import { FormsModule } from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import { ChangePasswordComponent } from './change-password/change-password/change-password.component';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { DeleteStudentDialogComponent } from './dialogs/delete-student-dialog/delete-student-dialog.component';
import { ViewStudentDialogComponent } from './dialogs/view-student-dialog/view-student-dialog.component';
import { EnrolModule } from '../enrol/enrol.module';


@NgModule({
  declarations: [
    MyProfileComponent,
    GeneralComponent,
    StudentDetailsComponent,
    AddStudentDialogComponent,
    EditStudentDialogComponent,
    ChangePasswordComponent,
    DeleteStudentDialogComponent,
    ViewStudentDialogComponent,
 
  ],
  imports: [
    CommonModule,
    MyProfileRoutingModule,
    MatTabsModule,
    BrowserAnimationsModule,
    
    MatIconModule,
    MatTableModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true
    }),
    EnrolModule
  
    
  ],

  
  
})
export class MyProfileModule { }
