import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { MyCoverages } from 'src/app/models/coverages/mycoverages.model';
import { environment } from 'src/environments/environment';
import { Brochure } from 'src/app/models/Brochure.model';
import { StudentInfo } from 'src/app/models/coverages/studentInfo.model';
import { IDCard } from 'src/app/models/coverages/idcard.model';
import { Coverage } from 'src/app/models/enrol/coverage.model';

@Injectable({
    providedIn: 'root'
})
export class MyCoverageService {
    private studentCoverageSubject = new BehaviorSubject<any>(null);
    studentCoverrage$ = this.studentCoverageSubject.asObservable();


    apiUrl = 'Coverages';

    private newOrSavedStudent = new BehaviorSubject<any>('');
    studentType = this.newOrSavedStudent.asObservable();

    private student = new BehaviorSubject<StudentInfo>({ firstName: '', lastName: '', dob: "", gradeID: 0, studentID: 0, phone: "", state: '', district: '', campusID: 0, campusType: '', coverages: [], totalAmount: 0, campusName:'', schoolDistrictName:'', ssn:'' });
    studentToBuyCoverages = this.student.asObservable();

    private buyCoverage = new BehaviorSubject<boolean>(false);
    buyMoreCoverages = this.buyCoverage.asObservable();

    private buyAgainCoverage = new BehaviorSubject<boolean>(false);
    buyAgainCoverages = this.buyAgainCoverage.asObservable();

    initialDetails: IDCard = {} as IDCard;
    private idcardDataSource : BehaviorSubject<IDCard> = new BehaviorSubject<IDCard>(this.initialDetails);
    public idcardDataObservable = this.idcardDataSource.asObservable();

    constructor(private http: HttpClient) { }

    updateStudentCoverage(newData: any){
        this.studentCoverageSubject.next(newData);
    }

    getStudentsCovered(userId: number): Observable<MyCoverages> {
        const headers = { 'content-type': 'application/json' }
        const body = JSON.stringify(userId.toString());
        return this.http.post<MyCoverages>(environment.baseUrl + this.apiUrl + '/CoveragesForUser/ ', body, { headers: headers });
    }

    getCoveragesForStudents(studentIds: number[]): Observable<Coverage[]> {
        const headers = { 'content-type': 'application/json' }
        const body = JSON.stringify(studentIds);
        return this.http.post<Coverage[]>(environment.baseUrl + this.apiUrl + '/CoveragesForUserStudents/ ', body, { headers: headers });
    }

    getUnderwriters(policyNo: number): Observable<Brochure[]> {
        return this.http.post<Brochure[]>(environment.baseUrl + 'CheckRates/GetBrochures', policyNo);
    }

    setStudentType(value: string) {
        this.newOrSavedStudent.next(value);
    }

    setStudToBuyCoverages(student: StudentInfo) {
        this.student.next(student);
    }

    setBuyCoveragesFlag(value: boolean) {
        this.buyCoverage.next(value);
    }
    setBuyAgainCoverageFlag(value:boolean){
        this.buyAgainCoverage.next(value);
    }
    getExpiredCoverages(userId: number): Observable<MyCoverages> {
        const headers = { 'content-type': 'application/json' }
        const body = JSON.stringify(userId.toString());
        return this.http.post<MyCoverages>(environment.baseUrl + this.apiUrl + '/ExpiredCoveragesForUser/ ', body, { headers: headers });
    }
    setUserData(data:IDCard){
        this.idcardDataSource.next(data);
    }
    getUserData():Observable<IDCard>{
        return this.idcardDataObservable;
    }
}