import { Component,  OnDestroy } from '@angular/core';
import { UnderWriter } from 'src/app/models/Brochure.model';
import { Coverage } from 'src/app/models/enrol/coverage.model';
import { StudentInfo } from 'src/app/models/coverages/studentInfo.model';
import { MyCoverageService } from 'src/app/services/coverages/mycoverage.service';
import { EnrolService } from 'src/app/services/enrol/enrol.service';
import { RatesService } from 'src/app/services/rates/rates.service';
import { StudentCoverage } from 'src/app/models/enrol/studentcoverage.model';
import { StudentDetailsService } from 'src/app/services/studentdetails/student-details.service';
import { Enroll } from 'src/app/models/Enroll.model';
import { StepperService } from 'src/app/services/stepper/stepper.service';
import { IDCard } from 'src/app/models/coverages/idcard.model';
import { MatStepper } from '@angular/material/stepper';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { FiscalYearService } from 'src/app/services/fiscal-year.service';

@Component({
  selector: 'app-buy-morecoverages',
  templateUrl: './buy-morecoverages.component.html',
  styleUrls: ['./buy-morecoverages.component.scss'],
})
export class BuyMorecoveragesComponent implements OnDestroy {
  radioAmount: number = 0;
  checkBoxAmount: number = 0;
  isChecked: boolean = false;
  isExpiredDate: any;

  currentYear: number = 0;
  studentInfo = new StudentInfo('', '', '', '', 0, 0, '', '', [], 0,'','','');
  availableCoverages: Coverage[] = [];
  currentDate: any;
  payableAmount: number = 0;
  toAllowNext: boolean = false;
  studentCoverage: StudentCoverage[] = [];
  isCollapse: boolean = true;
  underwriters: UnderWriter = {
    insCode: '',
    insCo: '',
    state: '',
    address1: '',
    address2: '',
    city: '',
    zip: '',
    logoLoc: '',
  };

  displayedColumns: string[] = [
    'Activity',
    'Demographics',
    'MarketingPlan',
    'Rate',
    'EffectiveDate',
    'TerminationDate',
  ];

  displayedColumnsAvailCoverage: string[] = [
    'RowID',
    'Activity',
    'Demographics',
    'MarketingPlan',
    'Rate',
  ];
  isBuyAgain: boolean = false;
  coverages: Coverage[] = [];
  filteredCoverage: any[] = [];
  enrollData = new Enroll('', '', '', '', '', '', '', [], 0);
  availableAddonCoverages: Coverage[] = [];
  showIdCard: boolean = false;
  constructor(
    private myCoverageService: MyCoverageService,
    private enrolService: EnrolService,
    private ratesService: RatesService,
    private enrollService: EnrolService,
    private studentDetailService: StudentDetailsService,
    private stepService: StepperService,
    private stepper: MatStepper,
    private notify: NotificationService,
    private fiscalYearService:FiscalYearService
  ) {
    this.currentYear = this.fiscalYearService.getFiscalYearStart();
  }

  ngOnInit() {
    this.enrollService.setEnrolledStudentData(new Enroll("", "", "", "", "", "", "", [], 0))
    this.getStudentFromMyCoverage();
    this.enrollService.enrollData.subscribe((value) => {
      const studentDetails: StudentCoverage[] = [];
      if (value?.studentDetail && value.studentDetail.length > 0) {
        value.studentDetail.forEach((studentCoverage: StudentCoverage) => {
          studentDetails.push({ ...studentCoverage });
        });
      }
    
      this.enrollData = new Enroll(
        value.firstname,
        value.lastname,
        value.email,
        value.streetaddress,
        value.city,
        value.state,
        value.zip,
        studentDetails,
        value.payableAmount
      );
    });

  }
  ngOnDestroy(): void {
    this.studentCoverage = [];
  }


  getStudentFromMyCoverage() {
    this.studentInfo = new StudentInfo('', '', '', '', 0, 0, '', '', [], 0,'','','');
    this.myCoverageService.studentToBuyCoverages.subscribe((stud) => {
      if (stud && stud.studentID != 0) {
    
        this.studentInfo = {...stud };
      
        this.getAvailableCoverages();
        this.myCoverageService.buyAgainCoverages.subscribe((value) => {
          this.isBuyAgain = value;
        });

        if (this.studentInfo.coverages.length > 0) {
          this.getUnderWriter(parseInt(this.studentInfo.coverages[0].policyNo));
        }
      }
    });
  }

  getUnderWriter(policyNo: number) {
    this.ratesService.getBrochure(policyNo).subscribe({
      next: (result: any) => {
        this.underwriters = Object.assign(
          new UnderWriter(),
          result.body.underwriterModel
        );
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }
  collapse(e: any) {
    if (
      e.target['className'] == 'accordion-button btnCoverage' ||
      e.target['className'] == ''
    ) {
      this.isCollapse = false;
    } else {
      this.isCollapse = true;
    }
  }
  getAvailableCoverages() {
    let listCoverages = [] as Coverage[];
    this.enrollService
      .getCoverages(
        this.studentInfo.campusID.toString(),
        this.studentInfo.campusType
      )
      .subscribe({
        next: (result: any) => {
          result.body.forEach(
            (item: Coverage) => {
              
             let data = {
                rowID: item.rowID,
                activity: item.activity,
                demographics: item.demographics,
                marketingPlan: item.marketingPlan,
                rate: item.rate,
                paymentType: item.paymentType,
                planType: item.planType,
                grouping: item.grouping,
                isChecked: false,
                totalRate: 0,
                paid: 0,
                effectiveDate: item.effectiveDate,
                terminationDate: item.terminationDate,
                policyNo: item.policyNo,
                voluntaryPolicyNo: item.voluntaryPolicyNo,
                inputType: '',
                subCategories: item.subCategories
              } as Coverage;

              listCoverages.push(data);
            }
          );

          listCoverages.forEach((cov) => {
            cov.isChecked = this.studentInfo.coverages.some(
              (c) =>
                c.activity == cov.activity &&
                c.demographics == cov.demographics &&
                c.marketingPlan == cov.marketingPlan 
             
            );
          });
          this.availableCoverages = this.isBuyAgain ? listCoverages.map(c => {return {...c,isChecked: false}}): listCoverages.filter(c => !c.isChecked);
          this.availableAddonCoverages = this.isBuyAgain ? listCoverages.map(c => {return {...c,isChecked: false}}): listCoverages.filter(c => !c.isChecked);
          this.validateCoverageData();
        },
        error: (err: any) => {
          console.log(err);
        }
      });
  }

  validateCoverageData() {
    let nonHealthFound: boolean;
    let inputType = '';
    let inputName = '';
    let dentalAlone = '';

    this.availableCoverages.forEach((cov) => {
      if (!cov.paymentType.toUpperCase().startsWith('Q')) {
        if (cov.paymentType.toUpperCase().startsWith('A')) {
          cov.paymentType = '*Full annual pmt';
        }
        else 
        {
          cov.paymentType = 'Bi-monthly pmts';
        }

        if (cov.activity.toLowerCase().indexOf('inj') > -1) {
          cov.activity += ' [' + cov.paymentType + ']';
        }

        if (cov.planType.startsWith('1')) {
          //at school, 24 hr, inj & sickness
          inputType = 'radio';
         
        } else if (cov.planType.startsWith('2')) {
          //football, wrestling, boxing, etc
          inputType = 'radio';
        } else {
          inputType = 'checkbox';
        }
      }

      cov.inputType = inputType;
    });
    const listCoverages = [...this.availableCoverages];
    this.availableCoverages =listCoverages.filter(c => c.inputType == 'radio');
    this.availableAddonCoverages = listCoverages.filter(c => c.inputType == 'checkbox');
    let coverageChecked = this.availableCoverages.filter((x) => x.isChecked);
    let addOncoverageChecked = this.availableAddonCoverages.filter((x) => x.isChecked);
    if ((coverageChecked && coverageChecked.length > 0) || (addOncoverageChecked && addOncoverageChecked.length>0)) {
      this.toAllowNext = true;
    }
    else{
      this.toAllowNext = false;
    }
          
  }

  selectItem(element: Coverage, event: any) {
    if (element.inputType == "radio") {
      this.availableCoverages.forEach(item => {
        if (item.rowID == element.rowID) {
          item.isChecked = true;
        } else {
          item.isChecked = false;
        }
      });
    }
    else if (element.inputType == "checkbox") {
      if (event.checked)
        element.isChecked = true;
      else if (!event.checked) {
        element.isChecked = false;
        this.payableAmount -= element.rate;
      }
    }
    this.payableAmount = 0;
    let coverageChecked = this.availableCoverages.filter(x => x.isChecked);
    let addonCoverageChecked = this.availableAddonCoverages.filter((x) => x.isChecked);
    let termDate = '';
    if(!this.isBuyAgain && addonCoverageChecked && addonCoverageChecked.length>0){
      termDate = this.availableAddonCoverages.filter(x => x.isChecked)[0]?.terminationDate
    }
    else{
      termDate = this.availableCoverages.filter(x => x.isChecked)[0]?.terminationDate
    }
    let stud: StudentCoverage = {
      firstName: this.studentInfo.firstName,
      state: this.studentInfo.state,
      coverageList: this.availableCoverages,
      lastName: this.studentInfo.lastName,
      studentId: this.studentInfo.studentID.toString(),
      dob: new Date(this.studentInfo.dob),
      grade: this.studentInfo.gradeID.toString(),
      phone: parseInt(this.studentInfo.phone),
      school: this.studentInfo.schoolDistrictName,
      coverageYear: this.currentYear.toString(),
      campusName: this.studentInfo.campusName,
      campusType: this.studentInfo.campusType,
      coverageRate: element.rate,
      stateCode: '',
      campusId: this.studentInfo.campusID,
      campusTypeId: this.studentInfo.campusType,
      total: 0,
      groupName: '',
      addOnCoverageList: this.availableAddonCoverages,
      terminationDate: termDate,
      ssn: this.studentInfo.ssn
    };
    this.studentCoverage.pop();
    this.studentCoverage.push(stud);
    this.getPayableAmount();
    if (coverageChecked && coverageChecked.length > 0) {
      this.toAllowNext = true;
    }
    else if(addonCoverageChecked && addonCoverageChecked.length > 0){
      this.toAllowNext = true;
    }
    else{
      this.toAllowNext = false;
    }
  
  }
  getPayableAmount() {

    this.payableAmount = 0;
    this.studentCoverage.forEach(cov => {
      let total = 0;
      let selectedCoverages = cov.coverageList.filter(x => x.isChecked);
      if (selectedCoverages.length > 0) {
        total += selectedCoverages[0].rate;
      }

      let selectedAddOnCoverages = cov.addOnCoverageList.filter(x => x.isChecked);
      if (selectedAddOnCoverages.length > 0) {
        selectedAddOnCoverages.forEach(a => {
          total += a.rate;
        });
      }
      cov.coverageRate = total;
      cov.total = total;
      this.payableAmount += cov.total;
    });
  }
  GotoStudentDetailsTab() {
    const coverageChecked = this.availableCoverages.some(x => x.isChecked);
    const addonCoverageChecked = this.availableAddonCoverages.some((x) => x.isChecked);
    const subCatNotChecked = this.availableCoverages.some(x=> x.subCategories?.length>0 && (this.studentInfo.campusType == 'H' || this.studentInfo.campusType == 'M') && (x.selectedSubCats == undefined || x.selectedSubCats?.length==0) && x.isChecked==true)
    if(this.isBuyAgain && !coverageChecked && addonCoverageChecked){
      this.notify.showWarning('Add ons can not be purchased alone.')
      return;
    }
    else if(subCatNotChecked){
      this.notify.showWarning('Select All Sports/Activities student performs');
      return;
    }
    else{
    this.enrollData.studentDetail = this.studentCoverage;
    this.enrollData.payableAmount = this.payableAmount;

    this.stepService.updateStepStatus(0, this.toAllowNext);
    this.enrolService.setEnrolledStudentData(this.enrollData);
    this.stepService.updateStepStatus(1, true);
    this.stepper.next();
  }
  }
  downloadIdCard(student: StudentInfo, underwriter: string) {
    this.showIdCard = true;
    let data = new IDCard(student, underwriter, true);
    this.myCoverageService.setUserData(data);
    setTimeout(() => {
      this.showIdCard = false;
    }, 2);
  }
}
