import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { StudentDetailsService } from 'src/app/services/studentdetails/student-details.service';

@Component({
  selector: 'app-delete-student-dialog',
  templateUrl: './delete-student-dialog.component.html',
  styleUrls: ['./delete-student-dialog.component.scss']
})
export class DeleteStudentDialogComponent {
  studentID: number = 0;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: number,
    private studentService: StudentDetailsService,
    private dialogRef: MatDialogRef<DeleteStudentDialogComponent>
  ){
    this.studentID = data;
  }
  closeDialog(){
    this.dialogRef.close("not deleted");
  }
  deleteStudent(){
    this.studentService.deleteStudent(this.studentID).subscribe({
      next: (res:any)=> {
        if(res && res.body){
        this.dialogRef.close("deleted successfully");
        }
      },
      error: (error: HttpErrorResponse) => {
        console.log(error.message)
      }
    })
  }
}
