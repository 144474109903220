import { Component, Input, OnInit, Output, Renderer2, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { AuthorizenetI } from './authorizenet.config';
declare const window: any
@Component({
  selector: 'app-authorizenet-payment',
  templateUrl: './authorizenet-payment.component.html',
  styleUrls: ['./authorizenet-payment.component.scss']
})

export class AuthorizenetPaymentComponent implements OnInit, OnChanges {
  @Input() ButtonText: string = "Pay Now";
  @Input() config: AuthorizenetI = {};
  @Output() authorizeNetResponseHandler = new EventEmitter<{}>();

  billingAddressOptions: any;
  urlAction: any;
  apiLoginID: any;
  clientKey: any;
  acceptUIFormBtnTxt: any;
  acceptUIFormHeaderTxt: any;
  amountTxt:any;


  constructor(private renderer: Renderer2) { }
  public async loadExternalScript(url: string): Promise<HTMLScriptElement> {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.id = 'authoriszenetScript'
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
    this.renderer.appendChild(document.body, script);
    return script;
  }
  ngOnInit() {
    this.initiConfig();

  }
  removeExternalScript(){
    const script = document.getElementById('authoriszenetScript');
    const acceptUIBackground = document.getElementById('AcceptUIBackground');
    const acceptUIContainer = document.getElementById('AcceptUIContainer');
    script?.setAttribute('src','');
    script?.setAttribute('id','');
    if(script) this.renderer. removeChild(document.body, script);
    if(acceptUIBackground) this.renderer.removeChild(document.body, acceptUIBackground);
    if(acceptUIContainer) this.renderer.removeChild(document.body, acceptUIContainer);
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.initiConfig();
  }
  async initiConfig(){
    try {
      this.billingAddressOptions = this.getUndefined("billingAddressOptions", this.config.billingAddressOptions);
      this.apiLoginID = this.getUndefined("apiLoginID", this.config.apiLoginID);
      this.clientKey = this.getUndefined("clientKey", this.config.clientKey);
      this.acceptUIFormBtnTxt = this.getUndefined("acceptUIFormBtnTxt", this.config.acceptUIFormBtnTxt);
      this.acceptUIFormHeaderTxt = this.getUndefined("acceptUIFormHeaderTxt", this.config.acceptUIFormHeaderTxt);
       this.amountTxt = this.getUndefined('amount', this.config.amount);
       this.removeExternalScript();
      await this.loadExternalScript(this.getUndefined("acceptUIurl", this.config.acceptUIurl));

      window.authorizeNetHandler = this.authorizeNetHandler;
    } catch (error) {
      console.error(`Authorizenet Config Error: "${error}"`)
    }
  }
  public authorizeNetHandler = (response: object) => {
  
    this.authorizeNetResponseHandler.emit(response)
  }

  private getUndefined = (reference: string, data: any) => {
    if (data && data !== null) {
      return data;
    }
    throw new Error(`Data ${reference} is Undefined or Null`);
  };
}


