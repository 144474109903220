import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Login } from './models/login.model';
import { Router } from '@angular/router';
import { ForgotPasswordComponent } from './features/home/forgot-password/forgot-password.component';
import { AnnualMaintenanceComponent } from './features/annual-maintenance/annual-maintenance.component';
import { MatDialog } from '@angular/material/dialog';
import { MyProfile } from './models/myprofile.model';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { User } from './models/User.model';
import { EnrolService } from './services/enrol/enrol.service';
import { MyCoverageService } from './services/coverages/mycoverage.service';
import { StudentInfo } from './models/coverages/studentInfo.model';
import { UserdetailsService } from './services/profile/userdetails.service';
import { Enroll } from './models/Enroll.model';
import { environment } from 'src/environments/environment';
import { AESEncryptDecryptService } from './services/encryptDecrypt/aesencrypt-decrypt.service';
import { AnnualMaintenanceService } from './services/annual-maintenance.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent implements OnInit {
  title = 'EnrolmentUI';
  login = new Login("", "", "", "", "", "", "", "", "", "");
  isLoggedIn: boolean = false;
  showEmailError: boolean = false;
  errorMessage: string = "";
  pwderrorMessage: string = "";
  passwordPattern: string = "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{8,}$";
  public fullname: string = '';
  userDetail: MyProfile = {
    fullname: "",
    emailId: ""
  };
  model: any;
  isTempPassword: boolean = false;
  message: string = "";
  preMaintenanceMessage: boolean = false;

  constructor(private router: Router, private authService: AuthenticationService,
    private route: Router, private dialog: MatDialog, private enrolService: EnrolService,
    private myCoverageService: MyCoverageService,
    private domSanitizer: DomSanitizer,
    private userDetailService: UserdetailsService,
    private AESEncryptDecryptService: AESEncryptDecryptService,
    private matIconRegistry: MatIconRegistry,
    private cdr: ChangeDetectorRef,
    private annualMaintenanceService: AnnualMaintenanceService) {
    this.matIconRegistry.addSvgIcon("down", this.domSanitizer
      .bypassSecurityTrustResourceUrl('assets/images/down.svg'));

    this.userDetailService.fullName.subscribe(newFullName => {
      this.fullname = newFullName;
      this.cdr.detectChanges();
    });

  }

  ngOnInit(): void {
    this.cdr.detectChanges();
    this.authService.loggedInUser.subscribe((res) => {
      if (res?.email) {
        this.isLoggedIn = true;
        this.login = new Login(res.email, "", res.firstName, res.lastName, res.accountId, res.address1, res.address1, res.stateCode, res.city, res.zip)
        this.fullname = this.login.firstName.trim() + " " + this.login.lastName.trim();
      }
    });
    this.annualMaintenanceService.checkForMaintenance();
    this.preMaintenanceMessage = this.annualMaintenanceService.preMaintenanceMessage;
    if (this.preMaintenanceMessage) {
      this.dialog.open(AnnualMaintenanceComponent, {panelClass: 'annual-maintenance-modal'});
    }
  }

  OpenDialog() {
   this.dialog.open(ForgotPasswordComponent, { panelClass: 'forgot-password-modal', autoFocus: true, data: this.login.email });
  }

  authenticate(login: Login) {

    if (login.password != login.password.trim()) {
      this.showEmailError = true;
      this.errorMessage = "Email Address / Password entered is incorrect";
      this.pwderrorMessage = "";
      return;
    }
    if (login.email == "" && login.password == "") {
      this.showEmailError = true;
      this.errorMessage = "Missing item - Email Address!";
      this.pwderrorMessage = "Missing item - Password!"
      return;
    }

    else if (login.email == "") {
      this.showEmailError = true;
      this.errorMessage = "Missing item - Email Address!";
      this.pwderrorMessage = "";
      return;
    } else if (login.password == "") {
      this.showEmailError = true;
      this.errorMessage = "";
      this.pwderrorMessage = "Missing item - Password!";
      return;
    }
    //To-do: Encryption disabled for soft production, to be enabled later
    //this.login.password = this.AESEncryptDecryptService.encrypt(this.login.password);
    this.authService.authenticate(login).subscribe({
      next: (res: any) => {
        if (res?.body) {
          sessionStorage.setItem("jwt", res.body.jWTToken.token);
          this.showEmailError = false;
          this.errorMessage = "";
          this.login.accountID = res.body.accountID;
          this.login.firstName = res.body.firstName;
          this.login.lastName = res.body.lastName;
          this.login.accountId = res.body.accountID;
          this.login.password = "";

          this.authService.isAuthUser.next(res.status);
          this.authService.isAuthUser.subscribe(((val:any) => {
            this.isLoggedIn = val;
            this.fullname = this.login.firstName.trim() + " " + this.login.lastName.trim();

            this.SetUserDetails();
          }));

          this.authService.loggedInUserId.next(this.login.accountId);
          this.authService.loggedInUser.next(new Login(res.body.email, "", res.body.firstName, res.body.lastName, res.body.accountID, res.body.address1, res.body.address2, res.body.stateCode, res.body.city, res.body.zip));
          sessionStorage.setItem('loggedUser',JSON.stringify(new Login(res.body.email, "", res.body.firstName, res.body.lastName, res.body.accountID, res.body.address1, res.body.address2, res.body.stateCode, res.body.city, res.body.zip)))
          if (res.body.istempPassword) {
            this.authService.isTempPassword = true;
            this.message = "update password";
            this.router.navigate(['myprofile/change-password']);
          }
          else {
            this.router.navigate(['/coverages'])
          }

        } else {
          this.showEmailError = true;
          this.errorMessage = "Email Address / Password entered is incorrect";
          this.pwderrorMessage = "";
        }

      },
      error: (error: HttpErrorResponse) => {
        console.log(error.message)
      }
    })
  }

  logout(login: Login) {
    this.authService.isAuthUser.next(false);
    this.login = new Login("", "", "", "", "", "", "", "", "", "");
    sessionStorage.clear();
    this.isLoggedIn = false;
    this.route.navigate(['home']);

    this.authService.loggedInUser.next(new Login("", "", "", "", "", "", "", "", "", ""));
    this.enrolService.setCoverageModel([]);
    this.myCoverageService.setStudentType('New');
    this.myCoverageService.setBuyCoveragesFlag(false);
    this.myCoverageService.setStudToBuyCoverages(new StudentInfo("", "", "", "", 0, 0, "", "", [], 0,'','',''));
    this.enrolService.setEnrolledStudentData(new Enroll("", "", "", "", "", "", "", [], 0));
  }

  SetUserDetails() {
    let user = new User();
    user.accountID = this.login.accountID;
    user.firstName = this.login.firstName;
    user.lastName = this.login.lastName;
    user.email = this.login.email;
    this.authService.setUserDetailModel(user);
  }
  resetError() {
    this.errorMessage = ""
    this.showEmailError = false;
  }
}
