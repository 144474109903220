import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FiscalYearService {

  getFiscalYearStart(){
    let today = new Date();
    if (today.getMonth()  < 6) {
      return today.getFullYear() - 1;
    }
    return today.getFullYear();
  }

}
