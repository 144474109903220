<div class="view-student-modal" id="viewStudent">
    <div>
        <button (click)="closeDialog()" id="closeBtn" class="closeHeader">
            <img src="./assets/images/close cross.svg" alt="Unable to load image">
        </button>
    </div>
    <div class="row studentDetail">
        <div id="detailText">Student Details</div>
    </div>

    <div class="row detialsRow">
        <div class="row informationRow">
            <div class="col-sm-4 col-md-4 col-lg-4">
                <div class="inputLabel">First Name</div>
                <div class="inputValue"> {{student.firstName}} </div>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4">
                <div class="inputLabel">Last Name</div>
                <div class="inputValue"> {{student.lastName}} </div>
            </div>

        </div>
        <div class="row informationRow">
            <div class="col-sm-4 col-md-4 col-lg-4">
                <div class="inputLabel">DOB</div>
                <div class="inputValue"> {{student.dob | date: 'MM/dd/yyyy' }} </div>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4">
                <div class="inputLabel">Grade</div>
                <div class="inputValue"> {{student.grade}} </div>
            </div>

            <div class="col-sm-4 col-md-4 col-lg-4">
                <div class="inputLabel">Student ID</div>
                <div class="inputValue"> {{student.ssn}} </div>
            </div>
        </div>

        <div class="row informationRow">
            <div class="col-sm-4 col-md-4 col-lg-4">
                <div class="inputLabel">Mobile No</div>
                <div class="inputValue"> {{student.phone == 'NaN' ? '' : student.phone}} </div>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4">
                <div class="inputLabel">State</div>
                <div class="inputValue"> {{student.state}} </div>
            </div>

            <div class="col-sm-4 col-md-4 col-lg-4">
                <div class="inputLabel">School District</div>
                <div class="inputValue"> {{student.schoolDistrictName}} </div>
            </div>
        </div>
        <div class="row informationRow">
            <div class="col-sm-4 col-md-4 col-lg-4">
                <div class="inputLabel">Campus Type</div>
                <div class="inputValue"> {{student.campusType}}</div>
            </div>
            <div class="col-sm-8 col-md-8 col-lg-8">
                <div class="inputLabel">Campus</div>
                <div class="inputValue"> {{student.campusName}} </div>
            </div>

        </div>
    </div>
    <div class="row covDetail">
        <div id="detailText">Coverage Details</div>
    </div>
    <div class="row" style="display: grid; justify-content: center;" *ngIf="coverages.length==0 && isDataLoad" >
        <div class="col-sm-12 col-md-12 col-lg-12" >
            <span class="noCoverage"> No coverage found</span>
        </div>
        <div class="mt-2">
            <button class="buycovbtn" *ngIf="coverages.length==0" id="buyNowBtn" (click)="buyNewCoverages(true)">Buy Now</button>
        </div>

    </div>
    <div class="row ">
        <div class="col-lg-6 logoCol">
            <div>
                <img *ngIf="underwriters.logoLoc" class="logoImage" src='{{ "./assets/images/"+underwriters.logoLoc+""}}'
                alt={{underwriters.logoLoc}}>
            </div>
            <div>
                <div class="gnote" *ngIf="underwriters.insCo">Coverage by</div>
                <div class="note">
                    {{underwriters.insCo}}
                </div>
            </div>
        </div>
        <div class="col-lg-6 noteCol">
            <div class="note">
                {{underwriters.address1}}, {{underwriters.address2}} <br>
                {{underwriters.state}}, {{underwriters.city}}, {{underwriters.zip}}
            </div>
        </div>
    </div>
    <div class="row tableRow" *ngIf="coverages.length>0">
        <mat-table #table [dataSource]="coverages" matSort>

            <ng-container matColumnDef="Name">
                <th style="width: 33%;" class="headerCell" mat-header-cell mat-sort-header *matHeaderCellDef>
                    <span class="headerCellText">Name</span>
                </th>
                <td style="width: 33%;" class="datacell" mat-cell *matCellDef="let element">{{element.activity}}
                    <div *ngIf="element.subCatNameString && element.subCatNameString !=''"> [{{element.subCatNameString}}]</div>
                </td>
            </ng-container>

            <ng-container matColumnDef="Demographics">
                <th style="width: 33%;" class="headerCell" mat-header-cell mat-sort-header *matHeaderCellDef>
                    <span class="headerCellText">Demographics</span>
                </th>
                <td style="width: 33%;" class="datacell" mat-cell *matCellDef="let element">
                    {{element.demographics}}</td>
            </ng-container>

            <ng-container matColumnDef="MarketingPlan">
                <th style="width: 33%;" class="headerCell" mat-header-cell mat-sort-header *matHeaderCellDef>
                    <span class="headerCellText">Marketing Plan</span>
                </th>
                <td style="width: 33%;" class="datacell" mat-cell *matCellDef="let element">
                    {{element.marketingPlan}}</td>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
    </div>
    <div class="row" style="justify-content: center; display: grid;">
        <button class="buycovbtn" *ngIf="coverages.length>0" id="buyMoreCovgBtn" (click)="buyNewCoverages(false)">Buy more coverages</button>
    </div>
</div>
