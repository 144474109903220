import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PaymentService {
 
  constructor(private http: HttpClient) { }
  private paymentDetailsSub = new BehaviorSubject<any>(null);

  public paymentDetails$ = this.paymentDetailsSub.asObservable();

  public setPaymentDetails(paymentDetails:any){
    this.paymentDetailsSub.next(paymentDetails);
  }

  PostPaymentProcess(postPaymentProcessData: any): Observable<any[]> {
    const headers = { 
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
    return this.http.post<any>(environment.baseUrl + "Payment/PostPayProcess" ,  postPaymentProcessData, { headers: headers });
}
}
