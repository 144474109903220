import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, shareReplay } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { Student } from 'src/app/models/student.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StudentDetailsService {

  getStudentUrl: string = "Students/LoadStudents";
  saveStudentUrl: string = "Students/SaveStudent";
  saveStudentsUrl: string = "Students/SaveStudents";
  deleteStudentUrl: string = "Students/DeleteStudent";
  // coveragesForStudentUrl: string = "Coverages/PurchasedCoveragesForStudent";
  coveragesForStudentUrl: string = "Coverages/CoveragesForUserStudents";
  studentCoveredUrl: string = "Students/IsStudentCovered";
  studentsCoverageStatusUrl: string = "Students/CoverageStatus";
  studentExistsUrl: string = "Students/StudentExists";

  students: Student[]=[];
  private studentSubject = new BehaviorSubject<Student[]>(this.students);


  getSutendnts(): Observable<Student[]> {
    return this.studentSubject.asObservable();
  }



  addStudent(student: Student): void {
    this.students.push(student);
    this.studentSubject.next([...this.students]);
  }

  private familyInfo = new BehaviorSubject<any>({});
  famInfo = this.familyInfo.asObservable();

  private allStudents: Observable<Student[]> | undefined;

  constructor(private httpClient: HttpClient) {

  }

  get AllStudents() {
   
    shareReplay(1);
    return this.allStudents;
  }

  setFamilyinfo(famInfo: any) {
    this.familyInfo.next(famInfo);
  }

  saveStudent(student: Student) {
    const headers = { 'content-type': 'application/json'}  
    return this.httpClient.post(environment.baseUrl + this.saveStudentUrl, student, {headers: headers});
  }

  saveStudents(students: Student[]) {
    const headers = { 'content-type': 'application/json'}  
    
    return this.httpClient.post(environment.baseUrl + this.saveStudentsUrl, students, {headers: headers});
  }


  getStudents(acId: number): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    return this.httpClient.post(environment.baseUrl + this.getStudentUrl, acId, httpOptions).pipe(map(data => {
      return data;
    }));

    
  }

  deleteStudent(studentId: number): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    return this.httpClient.post(environment.baseUrl + this.deleteStudentUrl, studentId, httpOptions)
  }

  checkStudentCovered(studentId: string): Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body= JSON.stringify(studentId);
    return this.httpClient.post(environment.baseUrl + this.studentCoveredUrl , body, {headers: headers}).pipe(map(data => {
      return data;
    }));
  }

  checkStudentsCoverageStatus(studentIds: number[]): Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body= JSON.stringify(studentIds);
    return this.httpClient.post(environment.baseUrl + this.studentsCoverageStatusUrl , body, {headers: headers}).pipe(map(data => {
      return data;
    }));
  }

  getCoverages(studentId: string): Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    const body= JSON.stringify([studentId]);
    return this.httpClient.post(environment.baseUrl + this.coveragesForStudentUrl, body, {headers: headers}).pipe(map(data => {
      return data;
    }));
  }

  checkIfStudentExists(studentId: string): Observable<any> {
    const headers = { 'content-type': 'application/json'}  
    const body= JSON.stringify(studentId);
    return this.httpClient.post(environment.baseUrl + this.studentExistsUrl, body, {headers: headers})    
  }
}
