import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, map } from 'rxjs';
import { Campus } from 'src/app/models/enrol/campus.model';
import { environment } from 'src/environments/environment';
import { Enroll } from 'src/app/models/Enroll.model';
import { Student } from 'src/app/models/student.model';
import { StudentCoverage } from 'src/app/models/enrol/studentcoverage.model';

@Injectable({
  providedIn: 'root'
})
export class EnrolService {

  private studentCoverageModel = new Subject<any>();
  studentCoverage = this.studentCoverageModel.asObservable();

  private enrolledStudentModel = new BehaviorSubject<any>({});
  enrollData = this.enrolledStudentModel.asObservable();

  constructor(private http: HttpClient) { }

  getCampus(selectedDistrict: string): Observable<Campus[]> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(selectedDistrict);
    return this.http.post<Campus[]>(environment.baseUrl + 'Location/GetCampus/', body, { headers: headers });
  }

  getCoverages(selectedCampus: string, selectedCampusType: string): Observable<Campus[]> {
    let args = {
      campusID: selectedCampus,
      campusType: selectedCampusType
    };

    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(args);
    return this.http.post<Campus[]>(environment.baseUrl + 'Coverages/GetCoverages/', body, { headers: headers });
  }

  setCoverageModel(coverage: any) {

    this.studentCoverageModel.next(coverage);
  }


  setEnrolledStudentData(enrollData: Enroll) {
    const studentDetails: StudentCoverage[] = [];
    if (enrollData?.studentDetail && enrollData.studentDetail.length > 0) {
      enrollData.studentDetail.forEach(studentCoverage => {
        studentDetails.push({ ...studentCoverage });
      })
    }
    const enroll = new Enroll(enrollData.firstname, enrollData.lastname, enrollData.email, enrollData.streetaddress, enrollData.city, enrollData.state, enrollData.zip, studentDetails, enrollData.payableAmount, 0)
    this.enrolledStudentModel.next(enroll);
  }

  checkEmail(email: string) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(environment.baseUrl + 'Email/CheckEmail', JSON.stringify(email), { headers: headers });
  }


  getEnrolledStudentDetails(accountId: string) {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(accountId);
    return this.http.post<Student[]>(environment.baseUrl + 'Students/LoadStudents/', body, { headers: headers });
  }

  getProcessingFee(policyNo: number, subTotal: number, studentCount: number): Observable<any> {
    let args = {
      policyNo: policyNo,
      totalRate: subTotal,
      studentCount: studentCount
    };

    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(args);
    return this.http.post<any>(environment.baseUrl + 'Payment/ProcessingFee/', body, { headers: headers }).pipe(map(result => {
      return result.body;
    }));
  }


}
