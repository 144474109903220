import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatTableModule} from '@angular/material/table';

import { RatesRoutingModule } from './rates-routing.module';
import { RatesComponent } from './rates.component';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    RatesComponent
  ],
  imports: [
    CommonModule,
    RatesRoutingModule,
    MatTableModule,
    FormsModule
  ]
})
export class RatesModule { }
