import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { HomeModule } from './features/home/home.module';
import { AppComponent } from './app.component';
import { RatesModule } from './features/rates/rates.module';
import { EnrolModule } from './features/enrol/enrol.module';
import { CancelRequestComponent } from './features/cancelrequest/cancel-request/cancel-request.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FaqsComponent } from './features/faqs/faqs/faqs.component';
import { ContactUsComponent } from './features/contact-us/contact-us/contact-us.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MatMenuModule} from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { ShortNamePipe } from './pipe/short-name.pipe';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import { NgxSpinnerModule } from "ngx-spinner";


import {MatListModule} from '@angular/material/list'
import { MyProfileModule } from './features/my-profile/my-profile.module';
import { JwtModule } from "@auth0/angular-jwt";
import { ToastrModule } from 'ngx-toastr';
import { AnnualMaintenanceComponent } from './features/annual-maintenance/annual-maintenance.component';

export function tokenGetter() {
  return sessionStorage.getItem("jwt");
}


@NgModule({
  declarations: [
    AppComponent,
    CancelRequestComponent,
    FaqsComponent,
    ContactUsComponent,
    ShortNamePipe,
    AnnualMaintenanceComponent
  ],
  imports: [
    BrowserModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    AppRoutingModule,
    HomeModule,
    RatesModule,
    MyProfileModule,
    EnrolModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    MatExpansionModule,
    MatMenuModule,
    MatIconModule,
    MatListModule,

    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
    ToastrModule.forRoot(
      {
        positionClass: 'toast-top-center'
      }
    ),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ["localhost:7151","http://ctqa02"],
        disallowedRoutes: []
      }
  }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
