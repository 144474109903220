<div class="contact">
  <div class="d-flex justify-content-center">
    <div class="logo">
      <img src="./assets/images/HSRlogo.svg" class="img-fluid" alt="Unable to load image">
    </div>
  </div>

  <div class="container d-flex justify-content-center">
    <div class="card border-0">
      <div class="card-body">
        <h5 class="card-title mb-2">Hours</h5>
        <p class="card-text mb-3">Monday - Friday 8:00 AM - 5:00 PM CT</p>

        <h5 class="card-title mb-2">Phone</h5>
        <p class="card-text mb-3">
          <a href="tel:18664095733">866.409.5733</a>
        </p>

        <h5 class="card-title mb-2">Email</h5>
        <p class="card-text mb-3">
          <a href="mailto:customerservice@hsri.com">customerservice&#64;hsri.com</a>
        </p>
      </div>
    </div>
  </div>
</div>
