import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  newmessage: string | undefined = "";
  messagenew: string | undefined = "";
  constructor(private toastr: ToastrService) { }

  showSuccess(message: string | undefined) {
    this.toastr.success(message);
    this.newmessage = message;
  }
  showError(messagesuc: string | undefined) {
    this.toastr.error(messagesuc);
    this.messagenew = messagesuc;
  }
  showWarning(messagesuc: string | undefined) {
    this.toastr.warning(messagesuc);
    this.messagenew = messagesuc;
  }
}
