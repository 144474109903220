import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CoveragesRoutingModule } from './coverages-routing.module';
import { CoveragesComponent } from './coverages.component';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import {  MatOptionModule } from '@angular/material/core';
import {  MatSelectModule } from '@angular/material/select';
import { IdCardComponent } from './id-card/id-card.component'

@NgModule({
  declarations: [
    CoveragesComponent,
    IdCardComponent,
  ],
  imports: [
    CommonModule,
    CoveragesRoutingModule,
    MatTableModule,
    MatOptionModule,
    MatSelectModule,
    MatMenuModule,
    FormsModule
  ],
  exports:[IdCardComponent]
})
export class CoveragesModule { }
