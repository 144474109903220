<div class="container" style="margin-bottom: 2%;">
  <!-- <div class="row">
    <div class="col passwordTitle ">
      <div *ngIf="!isTempPassword">
        <p class="title text-center">Change Current Password</p>
      </div>
      <div *ngIf="isTempPassword" class="err-new">
        <p class="title text-center">Change Temporary Password</p>
      </div>
    </div>
  </div> -->
  <div class="row mb-3 ">
    <div class="col passwordTitle ">
      <div *ngIf="!isTempPassword">
        <p class="title text-center">Change Current Password</p>
      </div>
      <div *ngIf="isTempPassword" class="err-new">
        <p class="title text-center">Change Temporary Password</p>
      </div>
    </div>
    <form (ngSubmit)="changePassword(model)" #changePasswordFrom="ngForm" id="changePasswordFrom" class="d-flex justify-content-center">
      <div ngModelGroup="passwords" #passwords="ngModelGroup" appCheckPassword>
        <div style="display: block; position: relative;">
          <label for="password" class="col-form-label pwdLabel">
            <span>{{ passwordType }} Password</span>
          </label>
          <div class="col">
            <input type="password" class="form-control" placeholder="{{ passwordType }} Password" id="currentPassword"
              name="password" [(ngModel)]="model.currentPassword" #currentPassword="ngModel" required />
            <div *ngIf="(currentPassword.touched || changePasswordFrom.submitted) " class="alert-danger"
              style="color: red;">
              <div *ngIf="currentPassword.errors?.['required']">
                Missing item - {{ passwordType }} Password!
              </div>
              <div *ngIf="isFailed&&!isChanged">
                Password entered is incorrect
              </div>
            </div>
          </div>
        </div>
        <div style="display: block; position: relative;">
          <label for="password" class="col-form-label pwdLabel">New Password</label>
          <div class="col">
            <input type="password" class="form-control" placeholder="New Password" minlength="8" id="newPassword"
              name="newPassword" [(ngModel)]="model.newPassword" #newPassword="ngModel" [pattern]="passwordPtn"
              [ngClass]="{'is-invalid': changePasswordFrom.submitted && newPassword.errors}" required />
            <div *ngIf="(newPassword.touched || changePasswordFrom.submitted)" class="alert-danger" style="color: red; max-width: 280px">
              <div *ngIf="newPassword.errors?.['required']" class="mb-2">
                Missing item - New Password!
              </div>
              <div *ngIf="newPassword.errors?.['pattern']" class="mb-2">
                Password must contain 1 Upper Case letter and 1 Special Character.
              </div>
              <div *ngIf="newPassword.errors?.['minlength']" class="mb-2">
                Password must be at least 8 characters long.
              </div>
              <div
                *ngIf="(model.currentPassword == model.newPassword)&&(model.currentPassword !='' || model.newPassword != '')"
                class="mb-2">
                Current Password and New Password should not be the same.
              </div>
            </div>
          </div>
        </div>
        <div style="display: block; position: relative;">
          <label for="password" class="col-form-label pwdLabel">Confirm New Password</label>
          <div class="col">
            <input type="password" class="form-control" placeholder="Confirm New Password" minlength="8" maxlength="12"
              id="confirmPassword" name="confirmPassword" [(ngModel)]="model.confirmPassword" #confirmPassword="ngModel"
              required />
            <div *ngIf="(confirmPassword.touched || changePasswordFrom.submitted) " class="alert-danger"
              style="color: red; max-width: 280px;">
              <div *ngIf="confirmPassword.errors?.['required']" class="mb-2">
                Missing item - Confirm New Password!
              </div>
              <div *ngIf=" model.newPassword !== model.confirmPassword" class="mb-2">
                The passwords do not match.
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="row">
    <div class="col d-flex justify-content-center">
      <button
        class="changeBtn"
        id="changeBtn"
        type="submit"
        [disabled]="!changePasswordFrom.valid"
        (click)="onSubmit(model)">Change Password</button>
      <div *ngIf="isChanged" style="color: green">
        <div>Password Changed Successfully</div>
      </div>
    </div>
  </div>
</div>
