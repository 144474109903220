<div class="forgot-password-modal" id="passwordDialog">
    <div >
        <button (click)="closeDialog()" id="closeBtn" class="closeHeader">
            <img src="./assets/images/close cross.svg" alt="Unable to load image">
        </button>
    </div>

    <div class="forgotPassword">
        <div class="header">
            <p class="title">Forgot Password</p>
        </div>
        <div>
            <form>
                <label for="email" class="emailLabel">Email Address</label>
                <div class="form-group femail">
                    <input type="email" class="form-control forgotEmail" id="email" name="email"
                        [(ngModel)]="emailAddress" [disabled]= !isEmailEmpty />
                    <svg class="mailicon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                        fill="none">
                        <path
                            d="M2.50001 4.16665L8.97677 9.20413C9.57862 9.67224 10.4214 9.67224 11.0232 9.20413L17.5 4.16665M4.33334 16.6666H15.6667C16.6001 16.6666 17.0668 16.6666 17.4233 16.485C17.7369 16.3252 17.9919 16.0702 18.1517 15.7566C18.3333 15.4001 18.3333 14.9334 18.3333 14V5.99998C18.3333 5.06656 18.3333 4.59985 18.1517 4.24333C17.9919 3.92973 17.7369 3.67476 17.4233 3.51497C17.0668 3.33331 16.6001 3.33331 15.6667 3.33331H4.33334C3.39992 3.33331 2.93321 3.33331 2.57669 3.51497C2.26308 3.67476 2.00812 3.92973 1.84833 4.24333C1.66667 4.59985 1.66667 5.06656 1.66667 5.99998V14C1.66667 14.9334 1.66667 15.4001 1.84833 15.7566C2.00812 16.0702 2.26308 16.3252 2.57669 16.485C2.93321 16.6666 3.39992 16.6666 4.33334 16.6666Z"
                            stroke="#5F6D7E" stroke-width="1.5" />
                    </svg>
                </div>
                <button class="continueBtn" id="continueBtn" (click)="onSubmit()">Continue</button>
            </form>
            <div *ngIf="isMessage&&emailAddress!=''">
               <p style="margin-top: 0.8rem;"> {{message}} </p> 
            </div>
        </div>
    </div>
</div>