
<div class="container faqs">
    <div class="row">
        <div class="col-lg-12">
            <div class="header"><h3>FAQs</h3></div>
            <ol type="1">
                <li class="question">Q: Can I modify my student’s information?
                    <ol type="A">
                        <li class="answer">
                        You can only modify your student’s information prior to purchasing coverage.  After you have purchased coverage for your student, you must contact <a href="mailto:customerservice@hsri.com">Customer Service</a> to update their information.
                        </li>
                    </ol>
                </li>
                <li class="question">Q: What does At School Coverage cover?
                    <ol type="A">
                        <li class="answer">Insurance coverage is provided during the hours and days when school is in session, while attending or participating in school sponsored and supervised activities on or off school premises (i.e. day field trips) and while participating in interscholastic athletics (except injuries incurred while participating in High School Football events/activities). Coverage is provided while traveling to, during or after such activities as a member of a group in transportation furnished or arranged by the Policyholder and traveling directly to or from the Insured's home premises and school premises when school is in session. If the Policyholder provides mandatory coverage for students under an At School, Interscholastic Athletic/Activity or Football program, benefits will be payable under those programs before being considered under an At School Voluntary program.</li>
                        </ol>
                </li>
                <li class="question">Q: What does 24-Hour Coverage cover?
                    <ol type="A">
                        <li class="answer">Provides coverage for injuries incurred 24-Hours a day, 365 days a year, at home, at school and while participating in interscholastic athletics (except injuries incurred while participating in High School Football events/activities). If the Policyholder provides mandatory coverage for students under an Interscholastic Athletic/Activity, Football or At School program, benefits will be payable under those programs before being considered under a 24-Hour Voluntary program.</li>
                    </ol>
                </li>
                <li class="question">Q: How is the Coverage Period defined?
                    <ol type="A">
                        <li class="answer">Coverage under the At School, 24-Hour and Football programs begins on the date of premium receipt but not before the start of the school year activities. At School Coverage ends at the close of the regular nine-month school term. 24-Hour Coverage ends when school reopens for the following fall term. Coverage is available under both plans throughout the school year at the premiums quoted (no pro rata premiums available).</li>
                    </ol>
                </li>
            </ol>
        </div>
    </div>
  
</div>